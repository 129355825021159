import axios from "axios";
import { auth } from "../../utils/firebaseConfig";
import { iChallenge } from "../../domain/community/challenge";

const newChallenge = async (challenge: iChallenge) => {
    try {
        const token = await auth.currentUser?.getIdToken();
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}community/challenges`,
            challenge,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token
                }
            }
        );

        if (response.status >= 400) {
            throw new Error('Error creating challenge');
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

const getCommunityChallenges = async () => {
    try {
        const token = await auth.currentUser?.getIdToken();
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `community/challenges/all`, (
            {
                headers: {
                    'Accept': 'application/json',
                    Authorization: token
                }
            }));
         const result = response.data.map((val: any) => {
                return { id: val.id, challenge: val.challenge as iChallenge };
            });
            return result;
    } catch (error) {
        
    }
}

const getChallengeById = async (id: string) => {
    try {
      const token = await auth.currentUser?.getIdToken();
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `community/challenges/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching challenge:', error);
      return null;
    }
  };

export { newChallenge, getCommunityChallenges, getChallengeById };

import { Box, FormControl, FormErrorMessage, Heading, InputGroup, Textarea } from '@chakra-ui/react';
import React from 'react'
import { FieldErrors, FieldValues } from 'react-hook-form'
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface EditLogLineProps {
    errors: FieldErrors<FieldValues>,
    register: any,
}

const EditLogline: React.FC<EditLogLineProps> = ({ errors, register }) => {
    const currProjectData = useSelector((state: RootState) => state.currProject.value);
    return (
        <Box>
            <Heading sx={{ marginBottom: ['20px'] }} fontSize="lg" fontWeight="bold">
                Logline
            </Heading>
            <FormControl isInvalid={Boolean(errors.logline)} >
                <InputGroup >
                    <Textarea
                        defaultValue={currProjectData.logline}
                        {...register('logline', {
                            required: 'Logline is required'
                        })}
                        placeholder='Type in the logline for your project here' />
                </InputGroup>
                <FormErrorMessage>
                    {errors.logline && errors.logline.message?.toString()}
                </FormErrorMessage>
            </FormControl>
        </Box>
    )
}

export default EditLogline
import React from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'

function HeroSection() {
    return (
        <Flex sx={{ flexDir: ['column', 'column', 'row'] }}>
            <Flex sx={{ flexDir: 'column', my: 'auto', gap: ['20px','30px','40px','70px'] }}>
                <Box sx={{fontSize: ['20px', '30px'], fontWeight: [600]}}>
                    Welcome to the revolution of independent cinema!
                </Box>
                <Box sx={{fontSize: ['15px', '30px'], fontWeight: [600]}}>
                    As emerging filmmakers, our desire for creation is limited to our network.
                    So we created a platform that brings together all artists,
                    turning competition to collaboration.
                </Box>
            </Flex>
            <Box sx={{ maxW: ['80vw'] }}>
                <Box >
                    <Image sx={{ maxW: ['80vw', '80vw', '480px'], objectFit: 'cover' }} src='/camera-man-frame.png' alt='blob' />
                </Box>
            </Box>
        </Flex>
    )
}

export default HeroSection
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { defaultImgProfile } from "../../utils/consts";
import { useParams } from "react-router-dom";
import EditModalProfileBasicInfo from "./EditModalProfileBasicInfo";
import AvatarEditor from "react-avatar-editor";
import axios from "axios";
import { setCurrProfileAction } from "../../store/slices/currProfile";
import { auth } from "../../utils/firebaseConfig";
import { uploadFileToStorage } from "../../utils/helpers";
import { useChat } from "../../hooks/messages/useChat";
import { useNavigate } from "react-router-dom";
interface DetailedProfileSectionProps {
  currProfileData?: any;
}

const DetailedProfileSection: React.FC<DetailedProfileSectionProps> = ({}) => {
  const currProfileData = useSelector(
    (state: RootState) => state.currProfile.value
  );
  const loggedInProfile: any = useSelector(
    (state: RootState) => state.profile.value
  );
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const params = useParams();
  const [img, setImg] = useState<File | null>(null);
  const dispatch = useDispatch();
  const toast = useToast();
  const editorRef = useRef<AvatarEditor | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { createMessageRoom } = useChat();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setImg(file);
    onOpen();
  };

  const onConnect = async () => {
    const userData = [loggedInProfile.id, currProfileData.id];
    createMessageRoom(userData);
    navigate(`/messages`);
  };

  const handleUpload = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob(async (blob: any) => {
        if (blob) {
          setIsUploading(true);

          try {
            blob.name = img?.name;
            const url = await uploadFileToStorage(blob, "images");
            const updatedProdileData = { ...currProfileData, headShotUrl: url };
            const token = await auth.currentUser?.getIdToken();
            await axios.post(
              process.env.REACT_APP_BACKEND_URL + "profile/edit-basic-info",
              JSON.stringify(updatedProdileData),
              {
                headers: {
                  Authorization: token,
                  "Content-Type": "application/json",
                },
              }
            );
            dispatch(
              setCurrProfileAction({ ...currProfileData, headShotUrl: url })
            );
            toast({
              title: "Success",
              description: "Your headshot has been updated successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          } catch (error) {
            toast({
              title: "Error",
              description: "Failed to upload to headshot",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          }
          onClose();
          setIsUploading(false);
        }
      });
    }
  };
  useEffect(() => {
    const uid = auth.currentUser?.uid;
    setCanEdit(params.id === uid);
  }, [params.id, loggedInProfile.id]);

  return (
    <Flex
      sx={{
        flexDir: ["column", "column", "column", "row"],
        alignItems: "center",
        gap: ["40px", "40px", "80px"],
      }}
    >
      {canEdit ? (
        <Image
          sx={{
            w: ["250px"],
            h: ["250px"],
            objectFit: "cover",
            borderRadius: ["450px"],
          }}
          src={
            currProfileData.headShotUrl
              ? currProfileData.headShotUrl
              : defaultImgProfile
          }
          cursor="pointer"
          onClick={onOpen}
        />
      ) : (
        <Image
          sx={{
            w: ["250px"],
            h: ["250px"],
            objectFit: "cover",
            borderRadius: ["450px"],
          }}
          src={
            currProfileData.headShotUrl
              ? currProfileData.headShotUrl
              : defaultImgProfile
          }
        />
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crop and Upload Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            sx={{ display: "flex", flexDir: "column", alignItems: "center" }}
          >
            <input type="file" onChange={handleFileChange} />
            {img && (
              <AvatarEditor
                ref={editorRef}
                image={img}
                style={{ width: "250px", height: "250px" }}
                width={1000}
                height={1000}
                borderRadius={550}
                border={50}
              />
            )}
          </ModalBody>
          {img && (
            <Center sx={{ my: ["10px"] }}>
              <Button
                isLoading={isUploading}
                sx={{
                  bgColor: "#FFAF7A",
                  w: ["100px"],
                  borderRadius: ["50px"],
                }}
                onClick={handleUpload}
              >
                Upload
              </Button>
            </Center>
          )}
        </ModalContent>
      </Modal>

      <Flex
        sx={{ flexDir: ["column"], justifyContent: "center", gap: ["15px"] }}
      >
        <Box>
          <Flex
            sx={{
              alignItems: "center",
              fontWeight: ["800"],
              fontSize: ["lg", "xl", "4xl"],
            }}
          >
            {currProfileData.firstName + " " + currProfileData.lastName}{" "}
            {canEdit ? <EditModalProfileBasicInfo /> : <></>}
          </Flex>
          <Text sx={{ fontWeight: ["600"], fontSize: ["25px"] }}>
            {currProfileData.mainRole ? currProfileData.mainRole : ""}
          </Text>
        </Box>
        {!canEdit && (
          <Button
            sx={{
              py: ["30px"],
              borderRadius: ["50px"],
              bgColor: "#92C8C8",
              maxW: ["200px"],
              fontWeight: ["600"],
              fontSize: ["25px"],
              color: "white",
            }}
            onClick={onConnect}
          >
            Connect
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default DetailedProfileSection;

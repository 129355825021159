// export const BACKEND_URL = 'https://backendapp-qm2t46wnya-uc.a.run.app/';
import React from "react";
import { GiDirectorChair } from "react-icons/gi";
import { PiFilmSlateThin } from "react-icons/pi";
import { FaFileInvoiceDollar, FaFilm } from "react-icons/fa";
import { BsCameraReels } from "react-icons/bs";
import { LuPaintbrush } from "react-icons/lu";
import { GiDualityMask } from 'react-icons/gi';
import { FaComputer, FaMusic, FaPenClip, FaRegLightbulb } from "react-icons/fa6";

export const BACKEND_URL = 'http://127.0.0.1:5001/indie-stry/us-central1/backendApp/';


export const Roles = ['Director',
    'Actor',
    'AD',
    'VFX',
    'Writer',
    'Composer',
    'Producer',
    'DP',
    'Camera Operator',
    'Gaffer',
    'Grip and Electric',
    'Editor',
    'Colorist',
    'DIT',
    'Production Designer',
    'Wardrobe Designer',
    'Hair and Makeup',
    'Boom Operator',
    'Sound Designer',
    'Sound Mixer',
    'Audio Engineer',
    'Casting Director',
    'Stunt Coordinator',
    'Other',
];

export const Interests = ['Adventure',
    'Drama',
    'Horror',
    'Non-Fiction',
    'Documentary',
    'Romance',
    'Action',
    'Television',
    'Comedy',
    'Musical',
    'New Media',
    'Narrative'
];

export const genres = ['Adventure', 'Drama', 'Horror', 'Non-Fiction', 'Documentary', 'Romance', 'Action', 'Television', 'Comedy', 'Musical', 'New Media', 'Narrative'];

export const states = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
    'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
    'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
    'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

export const stateAbbreviations: { [key: string]: string } = {
    "Alabama": "AL",
    "Alaska": "AK",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "Florida": "FL",
    "Georgia": "GA",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Pennsylvania": "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY"
};

export const iconMap = (role: string) => {
    const iconSize = '26px';
    switch (role) {
        case 'Director':
            return <GiDirectorChair size={iconSize}/>;
        case 'Actor':
            return <GiDualityMask size={iconSize} />;
        case 'AD':
            return <PiFilmSlateThin size={iconSize}/>;
        case 'VFX':
            return <FaComputer size={iconSize}/>;
        case 'Writer':
            return <FaPenClip size={iconSize}/>;
        case 'Composer':
            return <FaMusic size={iconSize}/>
        case 'Producer':
            return <FaFileInvoiceDollar size={iconSize}/>;
        case 'DP':
            return <BsCameraReels size={iconSize}/>;
        case 'Gaffer':
            return <FaRegLightbulb size={iconSize}/>;
        case 'Editor':
            return <FaRegLightbulb size={iconSize}/>;
        case 'Production Designer':
            return <LuPaintbrush size={iconSize}/>;
        default:
            return <FaFilm />;
    }
}

export const defaultImgProfile = '/defaultProfileImg.png';
export const challengeBadge = '/icon2.svg';
export const defaultProjectCoverPage = "https://firebasestorage.googleapis.com/v0/b/indie-stry.appspot.com/o/projects%2Fproject-placeholder-image.jpeg?alt=media&token=eb886c14-1219-4785-a7ed-b5a86cc8eb11&_gl=1*1432694*_ga*MTk1NTEzNDk2NC4xNjkxNTQxNjA4*_ga_CW55HF8NVT*MTY5OTI5ODUwMC4yMDIuMS4xNjk5Mjk4NTYyLjU4LjAuMA..";
export const bgImage = "https://firebasestorage.googleapis.com/v0/b/indie-stry.appspot.com/o/images%2Fhome-page.png?alt=media&token=a1fe4683-73c8-4ce8-83c8-df19ceb604da&_gl=1*91civs*_ga*MTk1NTEzNDk2NC4xNjkxNTQxNjA4*_ga_CW55HF8NVT*MTY5OTMwNjYyOS4yMDQuMS4xNjk5MzA3MTYzLjE5LjAuMA..";

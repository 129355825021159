import React, { useState, useEffect } from 'react';
import { Box, Center, Text, Badge } from '@chakra-ui/react';


interface ChallengeTimerProps {
    endDate: string;
}
const calculateTimeRemaining = (endDate: string) => {
    const currentDate = new Date();
    const endDateTime = new Date(endDate);
    const timeDiff = endDateTime.getTime() - currentDate.getTime();

    const days = Math.floor(timeDiff / (1000 * 3600 * 24));
    const hours = Math.floor((timeDiff % (1000 * 3600 * 24)) / (1000 * 3600));
    const minutes = Math.floor((timeDiff % (1000 * 3600)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
};
const ChallengeTimer: React.FC<ChallengeTimerProps> = ({ endDate }) => {
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(endDate));
    const [challengeActive, setChallengeActive] = useState(true);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining(endDate));
            if (timeRemaining.days <= 0 && timeRemaining.hours <= 0 && timeRemaining.minutes <= 0 && timeRemaining.seconds <= 0) {
                setChallengeActive(false);
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [endDate]);

    const { days, hours, minutes, seconds } = timeRemaining;

    const color = timeRemaining.days <= 1 ? (timeRemaining.days === 0 ? "red" : "orange") : "green";

    return (
        <Box>
            <Center>
                <Badge variant="subtle"
                    colorScheme={color}
                >
                    <Text fontSize="xl" fontWeight="bold" textAlign="center">
                        {challengeActive ? `Time Remaining: ${days}d ${hours}h ${minutes}m ${seconds}s` :
                            "Challenge has ended"}

                    </Text>
                </Badge>
            </Center>
        </Box>
    );
};



export default ChallengeTimer;
import React from 'react';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';

/**
 * InputField Component
 *
 * The `InputField` component provides a styled input field with optional validation error display.
 *
 * @component
 * @example
 * <InputField
 *   label='First Name'
 *   type='text'
 *   placeHolder='John'
 *   errors={errors.firstName}
 *   register={register('firstName', { required: 'Please enter your first name' })}
 * />
 *
 * @param {Object} props - Component properties
 * @param {string} props.label - The label for the input field.
 * @param {string} props.type - The type of the input field (e.g., 'text', 'email').
 * @param {string} props.placeHolder - The placeholder text for the input field.
 * @param {Object} props.register - The register object from react-hook-form for managing form state.
 * @param {Object} props.errors - Validation errors for the input field.
 * @returns {JSX.Element} - A styled input field.
 */
const InputField = (props: any) => {
  return (
    <>
      <FormControl isInvalid={Boolean(props.errors)}>
        <FormLabel
          fontSize={{ base: '20px', lg: '25px' }}
          fontWeight={'bold'} color='#E7E0DB'>
          {props.label}

        </FormLabel>
        <Input
          width={'100%'}
          padding={'10px'}
          backgroundColor={'white'}
          border={'1px solid'}
          borderColor={'gray.300'}
          borderRadius={'4px'}
          transition={'border-color 0.3s ease'}
          type={props.type}
          placeholder={props.placeHolder}
          defaultValue={props.initialValue}
          {...props.register}
        />
        <FormErrorMessage
          position={'absolute'}>
          {props.errors && props.errors.message?.toString()}
        </FormErrorMessage>
      </FormControl>
    </>
  );
};

export default InputField;

import { Button, Box } from "@chakra-ui/react";
import React from "react";
import SubmitChallengeProjectLinkModal from "./SubmitChallengeProjectLinkModal";

const SubmitProject: React.FC<any> = ({
  isProjectCreatedByUser,
  hasDeadlinePassed,
  currProject,
}) => {
  const submitProject = () => {
    return <SubmitChallengeProjectLinkModal />;
  };
  if (currProject.submissiionTime) {
    return <Box>Project Submitted. View Submission</Box>;
  }
  if (hasDeadlinePassed) {
    return (
      <Box>
        Challenge completed, fret not my friend we have more challenges for you{" "}
      </Box>
    );
  }
  if (isProjectCreatedByUser) {
    return <SubmitChallengeProjectLinkModal />;
  }
  return <></>;
};

export default SubmitProject;

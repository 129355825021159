import { createSlice } from "@reduxjs/toolkit";

const initialCurrProject: any = {};
export const currProjectSlice = createSlice({
    name: 'currProjectData',
    initialState: {
        value: initialCurrProject,
    },
    reducers: {
        setCurrProjectAction: (state, action) => {
            state.value = action.payload
        }
    }
});

export const { setCurrProjectAction } = currProjectSlice.actions;

import { Button, Center, FormControl, FormErrorMessage, InputGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Textarea, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDeleteProject } from './useDeleteProject';

const DeleteButton: React.FC<{ onOpen: () => void }> = ({ onOpen }) => (
    <Center>
        <Button sx={{ borderRadius: '50px', my: ['20px'] }} colorScheme='red' onClick={onOpen}>
            Delete Project
        </Button>
    </Center>
);

interface DeleteModalProps {
    isOpen: boolean;
    onClose: () => void;
    handleSubmit: any;
    register: any;
    errors?: any;
    handleDelete: () => void;
    callback: any;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ isOpen, onClose, handleSubmit, register, errors, handleDelete, callback }) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Reason for deletion</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <FormControl isInvalid={Boolean(errors.deletionReason)} >
                    <InputGroup >
                <Textarea {...register('deletionReason', {
                            required: 'Reason is required'
                        })} onChange={(e) => {callback(e.target.value)}}/>
                </InputGroup>
                <FormErrorMessage>
                    {errors.deletionReason && errors.deletionReason.message?.toString()}
                </FormErrorMessage>
            </FormControl>
            </ModalBody>
            <Center sx={{ my: ['10px'] }}>
                <Button type='submit' mr={3} sx={{ borderRadius: '50px' }} onClick={handleDelete}>
                    Delete
                </Button>
            </Center>
        </ModalContent>
    </Modal>
);

const DeleteProject: React.FC<{projectId: string}> = (props: {projectId:string}) => {
    const [reason, setReason] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const { deleteProject } = useDeleteProject();

    const handleDelete = () => {
        console.log(reason);
        deleteProject(props.projectId, reason);
        onClose();
        navigate(`/projects`);
    };

    const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    } = useForm();

    return (
        <>
            <DeleteButton onOpen={onOpen} />
            <DeleteModal isOpen={isOpen} onClose={onClose} handleSubmit={handleSubmit} handleDelete={handleDelete} register={register} errors={errors} callback={setReason}/>
        </>
    );
};

export default DeleteProject;

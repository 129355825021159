import React from 'react';
import { 
    Container, 
    Tab, 
    Tabs, 
    TabList, 
    TabPanel, 
    TabPanels,
    Image,
} from '@chakra-ui/react';
import Events from './community-pages/events';
import Grants from './community-pages/grants';
import Challenge from './community-pages/challenges';
import Chat from './community-pages/chat';

const Community: React.FC = () => {
    return (
        <Container>
            <Image src='/INDIECOMMUNITY.svg' sx={{ h: ['125px','125px','150px','150px'] }} />
            <Tabs isFitted>
                <TabList>
                    <Tab>Events</Tab>
                    <Tab>Grants</Tab>
                    <Tab>Chat</Tab>
                    <Tab>Challenges</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Events />
                    </TabPanel>
                    <TabPanel>
                        <Grants />
                    </TabPanel>
                    <TabPanel>
                        <Chat />
                    </TabPanel>
                    <TabPanel>
                        <Challenge />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    );
};

export default Community;
import { Box, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import EditModalInspireFiles from '../EditProject/Modal/EditModalInspireFiles';
import AutoplayCarousel from './AutoPlayCarousels';


const GetInspired: React.FC = () => {
    const currProjectData: ProjectData = useSelector((state: RootState) => state.currProject.value)
    const files = ['https://firebasestorage.googleapis.com/v0/b/indie-stry.appspot.com/o/projects%2Fdownload.jpeg-a0719e8b-c1e9-447d-9b1f-c01da1192488?alt=media&token=54f31956-281e-45e6-b6c9-59d75c3e8823',
        'https://firebasestorage.googleapis.com/v0/b/indie-stry.appspot.com/o/projects%2Favatar-new.jpeg-4bcf2f7b-40d9-4ecf-b093-25da3cf96c5b?alt=media&token=2ff16b69-43b1-412d-8ead-41738de5606d'];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isProjectCreator, setIsProjectCreator] = useState<boolean>(false);
    const profileData: any = useSelector((state: RootState) => state.profile.value);

    useEffect(() => {

        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % files.length);
        }, 2000);  // Change images every 2 seconds

        return () => clearInterval(intervalId);  // Cleanup interval on component unmount
    }, [files.length]);
    useEffect(() => {
        if (profileData?.id === currProjectData.createdBy) {
            setIsProjectCreator(true);
        }
    }, []);
    return (
        <Stack sx={{  }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: ['5px'], maxW: ['100%','100%','100%','65%'], fontSize: {base: "3xl", md: "5xl"}, fontWeight: ['800'], borderBottom: '4px solid #92C8C8' }}>Get Inspired {isProjectCreator && <EditModalInspireFiles />}</Box>
            {(!currProjectData.inspireFiles || currProjectData.inspireFiles.length === 0)
                ?
                <Box>No files to show currently</Box> :
                <Box maxW={["100%", "600px"]} maxH="450px" objectFit={'cover'} overflow={'hidden'} mx="auto" >
                    <AutoplayCarousel slides={currProjectData.inspireFiles}/>
                </Box>
            }
        </Stack>
    )
}

export default GetInspired
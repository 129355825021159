import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { HiMapPin } from "react-icons/hi2";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  defaultImgProfile,
  iconMap,
  stateAbbreviations,
} from "../utils/consts";

interface ProfileCardProps {
  profileInfo: any;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ profileInfo }) => {
  const navigate = useNavigate();

  const handleProfileCardClick = () => {
    navigate(`/user/${profileInfo.id}/personal-profile`);
  };

  return (
    <Card
      sx={{
        background:
          "linear-gradient(to top, rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.68) 20%, rgba(0, 0, 0, 0))",
        w: ["280px"],
        cursor: "pointer",
        margin: ["20px", "20px", "20px", "0px"],
      }}
      onClick={handleProfileCardClick}
    >
      <Box
        sx={{
          px: ["6px"],
          minH: ["30%"],
          boxShadow: "2px 5px 2px -2px #92c8c8",
          border: "1px solid rgba(146, 200, 200, 0.4)",
          borderRadius: ["15px"],
        }}
      >
        <Flex
          sx={{
            flexDir: "row",
            justifyContent: "space-between",
            mt: ["10px"],
            gap: ["20px"],
          }}
        >
          <Box sx={{ color: "#ffaf7a", minH: ["50px"] }}>
            <Icon as={HiMapPin} />{" "}
            {profileInfo.city + ", " + stateAbbreviations[profileInfo.state]}
          </Box>
          {/* <Box sx={{ color: '#fff' }}>{profileInfo.mainRole && profileInfo.mainRole}</Box> */}
        </Flex>
        <Center sx={{ my: ["10px"] }}>
          <Image
            src={
              profileInfo.headShotUrl
                ? profileInfo.headShotUrl
                : defaultImgProfile
            }
            sx={{
              h: ["116px"],
              w: ["116px"],
              objectFit: "cover",
              borderRadius: ["80px"],
            }}
          ></Image>
        </Center>
        <Center>
          <Text sx={{ color: "#fff" }}>
            {profileInfo.firstName + " " + profileInfo.lastName?.[0] + "."}
          </Text>
        </Center>
        <Flex
          sx={{ mx: ["12px"], my: ["12px"], gap: ["15px"], flexDir: "column" }}
        >
          <HStack sx={{ minH: ["50px"] }}>
            <Box sx={{ color: "brand2" }}>{iconMap(profileInfo?.mainRole)}</Box>
            <Text sx={{ color: "#fff" }}>{profileInfo?.mainRole}</Text>
          </HStack>
          <HStack sx={{ minH: ["50px"] }}>
            <Icon
              as={IoCheckmarkCircle}
              sx={{ color: "brand2", boxSize: ["26px"] }}
            ></Icon>
            <Text sx={{ color: "#fff" }}>
              {profileInfo.selectedInterests?.slice(0, 3)?.join(", ")}
            </Text>
          </HStack>
        </Flex>
        <Center sx={{}}>
          <Button
            sx={{
              borderRadius: ["50px"],
              bg: "brand2",
              fontSize: ["15px", "20px", "20px"],
              mb: ["10px"],
            }}
          >
            <Text sx={{ fontWeight: "600" }}>See More</Text>
          </Button>
        </Center>
      </Box>
    </Card>
  );
};

export default ProfileCard;

import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { doc, onSnapshot } from 'firebase/firestore';
import db from '../../utils/firebaseConfig';
import { updateChatrooms } from '../../store/slices/messaging';
import { setIsChatLoading } from "../../store/slices/loading";
import { RootState } from "../../store/store";

const useMessagingSystem = () => {
    const availableMessageRooms = useSelector((state:RootState) => state.messaging.availableChatrooms);
    const dispatch = useDispatch();
    const unsubscribesRef = useRef(new Map());

    useEffect(() => {
        if (availableMessageRooms.length > 0) {
            const listenToRoom = (roomId: string) => {
                const msgRoomRef = doc(db, "MessageRooms", roomId);
                const unsubscribe = onSnapshot(msgRoomRef, (roomSnapshot) => {
                    if (roomSnapshot.exists()) {
                        const chatroom = {
                            id: roomId,
                            messages: roomSnapshot.data()?.messages,
                            users: roomSnapshot.data()?.users,
                            metadata: roomSnapshot.data()?.metadata,
                        };
                        dispatch(updateChatrooms({roomId, chatroom}));
                    }
                });
                unsubscribesRef.current.set(roomId, unsubscribe);
            };

            dispatch(setIsChatLoading(true));
            availableMessageRooms.forEach((roomId: string) => {
                if (!unsubscribesRef.current.has(roomId)) {
                    listenToRoom(roomId);
                }
            });
            dispatch(setIsChatLoading(false));

            return () => {
                unsubscribesRef.current.forEach((unsubscribe) => unsubscribe());
                unsubscribesRef.current.clear();
            };
        }

        if(availableMessageRooms.length === 0) {
            dispatch(setIsChatLoading(false));
            if(unsubscribesRef.current.size > 0) {
                unsubscribesRef.current.forEach((unsubscribe) => unsubscribe());
                unsubscribesRef.current.clear();
            }
        }
    }, [availableMessageRooms]);

    const destroyMessageRoomListeners = () => {
        if (unsubscribesRef.current.size > 0) {
            unsubscribesRef.current.forEach((unsubscribe) => unsubscribe());
            unsubscribesRef.current.clear();
            console.log('destroy:messageRoomListeners');
        }
    };

    return { destroyMessageRoomListeners };
};

export default useMessagingSystem;

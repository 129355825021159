import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "./firebaseConfig";
import { v4 } from "uuid";

export const convertFirebaseURLToFileName = (url: string) => {
    const fileRef = ref(storage, url);
    const fileName = fileRef.name.slice(0, -36).replace(/%20/g, " ");
    return fileName;
}

export const uploadFileToStorage = async (file: File | undefined, buckeName: string) => {
    const imageRef = ref(storage, `${buckeName}/${file?.name}` + v4());
    await uploadBytes(imageRef, file!);
    const url = await getDownloadURL(imageRef);
    return url;
}
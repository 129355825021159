import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
    name: 'loggedInProfile',
    initialState: {
        value: null,
    },
    reducers: {
        setProfileInfoAction: (state, action) => {
            state.value = action.payload
        }
    }
});

export const { setProfileInfoAction } = profileSlice.actions;

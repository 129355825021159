import React from 'react';
import { Button } from '@chakra-ui/react';

interface SelectButtonInputProps {
  value: string;
  isActive: boolean;
  onToggleActive: (value: string) => void;
}

/**
 * SelectButtonInput Component
 *
 * The `SelectButtonInput` component is designed for select-all-that-apply buttons and arranges them in columns of 3.
 *
 * @component
 * @example
 * <SelectButtonInput value='Example' isActive={false} onToggleActive={handleToggle} />
 *
 * @param {Object} props - Component properties
 * @param {string} props.value - The value of the button.
 * @param {boolean} props.isActive - Indicates if the button is active.
 * @param {Function} props.onToggleActive - A callback function to toggle the button's active state.
 * @returns {JSX.Element} - A button element.
 */
const SelectButtonInput: React.FC<SelectButtonInputProps> = ({ value, isActive, onToggleActive }) => {
  return (
    <Button
      fontSize={{ sm: '12px', md: '16px', lg: '18px' }}
      onClick={() => onToggleActive(value)}
      flex={'0 0 calc(33% - 10px)'}
      borderRadius={'10px'}
      padding={'8px 16px'}
      fontWeight={'bold'}
      backgroundColor={isActive ? '#FFAF7A' : 'white'}>
      {value}
    </Button>
  );
};

export default SelectButtonInput;

import { useDispatch } from 'react-redux';
import { deleteEvent, getCommunityEvents, newEvent } from '../../services/community/events';
import { setChallenges, setEvents } from '../../store/slices/community';
import { useState } from 'react';
import iEvent from '../../domain/community/event';
import { getCommunityChallenges, newChallenge } from '../../services/community/challenges';
import { iChallenge } from '../../domain/community/challenge';

const useCommunityEditor = () => {
    const dispatch = useDispatch();
    const [eventsLoading, setEventsLoading] = useState<boolean>(false);
    const [challengesLoading, setChallengesLoading] = useState<boolean>(false);

    const deleteSelectedEvent = async (id: string) => {
        setEventsLoading(true);
        const response = await deleteEvent(id);
        const events = await getCommunityEvents();
        dispatch(setEvents(events));
        setEventsLoading(false);
    };

    const createNewEvent = async (event: iEvent) => {
        setEventsLoading(true);
        const response = await newEvent(event);
        const events = await getCommunityEvents();
        dispatch(setEvents(events));
        setEventsLoading(false);
    };

    const createNewChallenge = async (challenge: iChallenge) => {
        setChallengesLoading(true);
        const response = await newChallenge(challenge);
        const challenges = await getCommunityChallenges();
        console.log('challenges received',challenges)
        dispatch(setChallenges(challenges));
        setChallengesLoading(false);
     }

    return { deleteSelectedEvent, eventsLoading, createNewEvent, createNewChallenge, challengesLoading };
};
export default useCommunityEditor;

import React from 'react';
import { Sidebar } from '@chatscope/chat-ui-kit-react';
import MessagingSidebarSearch from '../atoms/MessagingSidebarSearch';
import MessagingSidebarList from '../atoms/MessagingSidebarList';

const IndieMessagingSidebar: React.FC = () => {
    return (
        <Sidebar position="left" scrollable={false}>
            <MessagingSidebarSearch />
            <MessagingSidebarList />
        </Sidebar>
    );
};

export default IndieMessagingSidebar;
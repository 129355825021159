import React, { useState } from 'react';
import { Box, Button, VStack, Heading, Input } from '@chakra-ui/react';
import { UseFormSetValue } from 'react-hook-form';
import { Roles } from '../../utils/consts';
interface RolesInfoProps {
    setValue: UseFormSetValue<any>;
}
const RolesInfo: React.FC<RolesInfoProps> = ({ setValue }) => {
    const [mainRole, setMainRole] = useState<string | null>(null);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [customMainRole, setCustomMainRole] = useState<string>('');
    const [customOtherRole, setCustomOtherRole] = useState<string>('');
    const [isCustomMainRoleActive, setIsCustomMainRoleActive] = useState<boolean>(false);
    const [isCustomOtherRoleActive, setIsCustomOtherRoleActive] = useState<boolean>(false);

    const handleMainRoleClick = (Role: string) => {
        if (Role === 'Other') {
            setIsCustomMainRoleActive(true);
            setMainRole(null);
            setValue('mainRole', '');
        } else {
            setIsCustomMainRoleActive(false);
            setMainRole(Role);
            setValue('mainRole', Role);
        }
    };

    const handleOtherRoleClick = (Role: string) => {
        if (Role === 'Other') {
            setIsCustomOtherRoleActive(true);
        } else {
            setIsCustomOtherRoleActive(false);
            const updatedRoles = selectedRoles.includes(Role)
                ? selectedRoles.filter(i => i !== Role)
                : [...selectedRoles, Role];
            setSelectedRoles(updatedRoles);
            setValue('selectedRoles', updatedRoles);
        }
    };

    const handleCustomMainRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setCustomMainRole(value);
        setValue('mainRole', value);
    };

    const handleCustomOtherRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomOtherRole(event.target.value);
        setValue('selectedRoles', [...selectedRoles, event.target.value]);
    };

    return (
        <VStack spacing={4}>
            <Box>
                <Heading
                    fontSize={{ base: '20px', lg: '25px' }}
                    fontWeight={'bold'}
                    color='#E7E0DB'
                >
                    Main Role
                </Heading>
                {Roles.map(Role => (
                    <Button
                        key={Role}
                        bgColor={mainRole === Role ? '#FFAF7A' : 'white'}
                        onClick={() => handleMainRoleClick(Role)}
                        m={2}
                        isDisabled={selectedRoles.includes(Role)}
                    >
                        {Role}
                    </Button>
                ))}
                {isCustomMainRoleActive && (
                    <Input
                        placeholder="Type your main Role"
                        value={customMainRole}
                        onChange={handleCustomMainRoleChange}
                        mt={2}
                    />
                )}
            </Box>
            <Box>
                <Heading
                    fontSize={{ base: '20px', lg: '25px' }}
                    fontWeight={'bold'}
                    color='#E7E0DB'
                >
                    Skills
                </Heading>{Roles.map(Role => (
                    <Button
                        key={Role}
                        colorScheme={selectedRoles.includes(Role) ? 'blue' : 'gray'}
                        onClick={() => handleOtherRoleClick(Role)}
                        m={2}
                        isDisabled={mainRole === Role}
                    >
                        {Role}
                    </Button>
                ))}
                {isCustomOtherRoleActive && (
                    <Input
                        placeholder="Type your other Role"
                        value={customOtherRole}
                        onChange={handleCustomOtherRoleChange}
                        mt={2}
                    />
                )}
            </Box>
        </VStack>
    );
};

export default RolesInfo;
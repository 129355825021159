import { createSlice } from "@reduxjs/toolkit";
import iEvent from "../../domain/community/event";
export default interface iGrants {
    id: string;
    title: string;
    url: string;
    deadline: string;
    org?: string;
    award?: string;
    createdAt?: string;
    description?: string;
  };
  export interface iChallenge  {
    id: string;
    name: string;
    prompt: string;
    startDate: string;
    endDate: string;
    description: string;
    status: string;
    projects: string[];
    winner: string | null;
};

// {
//     "name": "Challenge2",
//     "prompt": "What doesn't kill you makes you stronger.",
//     "url": "",
//     "description": "Filmmakers race against the clock to craft a compelling narrative around \"what doesn't kill you makes you stronger.\" The timer stops, and only the most resilient story emerges victorious in this thrilling filmmaking showdown.",
//     "startAt": "2024-04-07T22:00:00.263Z",
//     "endAt": "2024-04-13T22:00:00.000Z",
//     "createdAt": "Sun, 07 Apr 2024 22:49:05 GMT"
//   }
interface CommunityState {
    events: {event: iEvent, id: string}[];
    grants: iGrants[];
    challenges: iChallenge[];
    challengeProjectId: string;
    currentChallenge: any;
};

export const communitySlice = createSlice({
    name: "community",
    initialState: {
        events: [],
        grants: [],
        challenges: [],
        challengeProjectId: "",
        currentChallenge: {} as any
    } as CommunityState,
    reducers: {
        setEvents: (state, action) => {
            const sortedEvents = action.payload.sort((a:any, b:any) =>  a.event.eventDate < b.event.eventDate ? 1 : -1 );
            state.events = sortedEvents;
        },
        setGrants: (state, action) => {
            const sortDeadline = (grants: iGrants[]) => {
                return grants.sort((a, b) => {
                    const dateA = new Date(a.deadline);
                    const dateB = new Date(b.deadline);
                    return dateA < dateB ? 1 : -1;
                });
            };
            const sortedGrantsByDeadlineDate = sortDeadline(action.payload);
            state.grants = sortedGrantsByDeadlineDate;
        },
        setChallengeProjectsId: (state, action) => {
            let currentChallengeById = state.challenges.find((challenge) => challenge.id === action.payload);
            
            state.currentChallenge = currentChallengeById;
        },
         setChallenges: (state, action) => {
            state.challenges = action.payload;
            
        },
        updateChallengeStatus: (state, action) => {
            const { id, status } = action.payload;
            const challengeIndex = state.challenges.findIndex((challenge) => challenge.id === id);
            if (challengeIndex !== -1) {
              state.challenges[challengeIndex].status = status;
            }
          },
          updateChallenge: (state, action) => {
            const updatedChallenge = action.payload;
            const newChallenges = state.challenges.filter((challenge) => challenge.id !== updatedChallenge.id);
            state.challenges = [...newChallenges, updatedChallenge];
    },
}
});

export const { setEvents, setGrants, setChallengeProjectsId,setChallenges, updateChallenge, updateChallengeStatus } = communitySlice.actions;
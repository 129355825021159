import { useDisclosure, useToast } from "@chakra-ui/react";
import axios from "axios";
import { setCurrProfileAction } from "../store/slices/currProfile";
import { auth } from "../utils/firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useState } from "react";
interface VideoLink {
    id: number;
    url: string;
}
export const useEditPortfolio = () => {
    const toast = useToast();
    const currProfile = useSelector((state: RootState) => state.currProfile.value);
    const [isDeleteing, setIsDeleting] = useState<string>("");
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose} = useDisclosure();
    const dispatch = useDispatch();
    const [files, setFiles] = useState<File[]>([]);
    const [videoLinks, setVideoLinks] = useState<VideoLink[]>([]);
    const [nextId, setNextId] = useState(0);

    const isYoutubeOrVimeoLink = (url: string): boolean => {
        const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
        const vimeoPattern = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+$/;
        return youtubePattern.test(url) || vimeoPattern.test(url);
    };

    const handleAddLink = () => {
        setVideoLinks([...videoLinks, { id: nextId, url: '' }]);
        setNextId(nextId + 1);
    };

    const handleLinkChange = (id: number, url: string) => {
        setVideoLinks(videoLinks.map(link => link.id === id ? { ...link, url } : link));
    };

    const handleRemoveLink = (id: number) => {
        setVideoLinks(videoLinks.filter(link => link.id !== id));
    };

    const formatUrl = (url: string) => {
    if (url.length > 20) {
      return url.substring(0, 20) + "...";
    }
    return url;
    };

    const deleteFile = async (fileURL: string) => {
        const deleteFile = { id: currProfile.id, deleteFileURL: fileURL };
        try {
        const token = await auth.currentUser?.getIdToken();
        await axios.post(
            process.env.REACT_APP_BACKEND_URL + "profile/delete-file",
            JSON.stringify(deleteFile),
            {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            }
        );
        const updatedFiles = currProfile.files.filter(
            (file: string) => file !== fileURL
        );
        dispatch(setCurrProfileAction({ ...currProfile, files: updatedFiles }));
        toast({
            title: "Success.",
            description: "Deleted the file successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
        });
        } catch (error) {
        toast({
            title: "Error",
            description: `Could not delete the file`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
        });
        }
        setIsDeleting("");
        deleteOnClose();
    };

    const deleteVideoLink = async (videoLink: string) => { 
        const deleteVideoLink = { id: currProfile.id, videoLinkToDelete: videoLink };
        try {
        const token = await auth.currentUser?.getIdToken();
        await axios.post(
            process.env.REACT_APP_BACKEND_URL + "profile/delete-video-link",
            JSON.stringify(deleteVideoLink),
            {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            }
        );
        const updatedVideoLinks = currProfile.videoLinks.filter(
            (link: string) => link !== videoLink
        );
        dispatch(
            setCurrProfileAction({ ...currProfile, videoLinks: updatedVideoLinks })
        );
        toast({
            title: "Success.",
            description: "Deleted the video link successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
        });
        } catch (error) {
        toast({
            title: "Error",
            description: `Could not delete the video link`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
        });
        }
        setIsDeleting("");
        deleteOnClose();
    }
    const handleRemove = (file: File) => {
    setFiles(files?.filter((f: File) => f !== file));
  };
  return { currProfile,toast, dispatch, formatUrl, deleteFile, deleteIsOpen, deleteOnOpen, deleteOnClose, isDeleteing, setIsDeleting, handleRemove, files, setFiles, deleteVideoLink, handleAddLink, handleLinkChange, handleRemoveLink, videoLinks, setVideoLinks, isYoutubeOrVimeoLink};
}
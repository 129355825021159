import React, { useEffect } from 'react'
import SignInButton from '../SignIn/SignIn';
import { auth } from '../../utils/firebaseConfig';
import { Avatar, Box, Flex, Heading, Image, Hide, Menu, MenuButton, MenuItem, MenuList, Show, Text } from '@chakra-ui/react';
import { COLOR1, COLOR2 } from '../../utils/ColorConstants';
import NavbarDrawer from './NavbarDrawer';
import { useNavbar } from '../../hooks/useNavbar';
import posthog from 'posthog-js';

function Navbar() {

    const { isAuthenticated, setIsAuthenticated, loggedInProfile, navigate, signOutUser, handleProfileClick, profileData, populateData } = useNavbar();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setIsAuthenticated(true);
                populateData();
            } else {
                setIsAuthenticated(false);
            }
        });
    }, []);

    useEffect(() => {
        if (isAuthenticated && loggedInProfile) {
        posthog.identify(loggedInProfile.emailAddress, {email: loggedInProfile.emailAddress});
            
        }
    }, [loggedInProfile, isAuthenticated]);

    return (
        <Box sx={{ padding: '2vh clamp(2px, 2vw, 30px) 2vh clamp(2px, 2vw, 30px)' }}>
            <Flex sx={{ gap: ['20px'], color: 'white', alignItems: 'center', justifyContent: 'space-between' }}>
                <Image src='/saturated-banner-logo-indiestry_white_thin.svg' sx={{ w: ['200px','200px','200px','250px']}} onClick={() => { navigate('/') }} />
                <Hide below='lg'>
                    <Flex sx={{ gap: ['30px'], alignItems: 'center' }}>
                        {((isAuthenticated && loggedInProfile?.isAdmin) || loggedInProfile?.emailAddress === process.env.REACT_APP_SU_USER) && <Text sx={{ fontWeight: ['600'], cursor: 'pointer' }} onClick={() => { navigate('/admin') }} > Admin </Text>}
                        <Text sx={{ fontWeight: ['600'], cursor: 'pointer' }} onClick={() => { navigate('/community') }} > Community </Text>
                        <Text sx={{ fontWeight: ['600'], cursor: 'pointer' }} onClick={() => { navigate('/people') }} > People </Text>
                        <Text sx={{ fontWeight: ['600'], cursor: 'pointer' }} onClick={() => { navigate('/projects') }} > Projects </Text>
                        <Text sx={{ fontWeight: ['600'], cursor: 'pointer' }} onClick={() => { navigate('/messages') }} > Messages </Text>
                        {(isAuthenticated && profileData) ?
                            <Menu>
                                <MenuButton>
                                    {profileData.headShotUrl ?
                                        <Avatar name={profileData.firstName} src={profileData.headShotUrl} />
                                        :
                                        <Avatar name={profileData.firstName} bgColor={COLOR2} color={COLOR1} />
                                    }
                                </MenuButton>
                                <MenuList sx={{ bgColor: '#1E2626', }}>
                                    <MenuItem sx={{ cursor: 'pointer', bgColor: '#1E2626' }} onClick={handleProfileClick}>Profile</MenuItem>
                                    <MenuItem sx={{ cursor: 'pointer', bgColor: '#1E2626' }} onClick={signOutUser}>Logout</MenuItem>
                                </MenuList>
                            </Menu> :
                            <SignInButton></SignInButton>
                        }
                    </Flex>
                </Hide>
                <Show below='lg'>
                    <NavbarDrawer />
                </Show>
                {/* </div> */}
            </Flex>
        </Box>
    )
}

export default Navbar
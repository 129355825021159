import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { auth } from '../../../utils/firebaseConfig';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrProjectAction } from '../../../store/slices/currProject';
import { MdModeEditOutline } from 'react-icons/md';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import { RootState } from '../../../store/store';
import { IoClose } from 'react-icons/io5';
import { convertFirebaseURLToFileName, uploadFileToStorage } from '../../../utils/helpers';
const EditModalProjectFiles = () => {
    const [files, setFiles] = useState<File[]>([]);
    const { register, handleSubmit } = useForm();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
    const currProjectData: ProjectData = useSelector((state: RootState) => state.currProject.value);
    const [isUpdateHappenig, setisUpdateHappening] = useState<boolean>(false);
    const toast = useToast();
    const dispatch = useDispatch();
    const [isDeleteing, setIsDeleting] = useState<string>('');

    const handleRemove = (file: File) => {
        setFiles(files?.filter((f: File) => f !== file));
    };

    const deleteFile = async (fileURL: string) => {
        const deleteFile = { id: currProjectData.id, deleteFileURL: fileURL, fileSection: 'projectFiles' };
        try {
            const token = await auth.currentUser?.getIdToken();
            await axios.post(process.env.REACT_APP_BACKEND_URL + "delete-projects-file", JSON.stringify(deleteFile), {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            },);
            const updatedFiles = currProjectData.projectFiles.filter((file) => file !== fileURL);
            updatedFiles.map((filen) => {

            })
            dispatch(setCurrProjectAction({ ...currProjectData, projectFiles: updatedFiles }));
            toast({
                title: "Success.",
                description: 'Deleted the file successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            });
        } catch (error) {
            toast({
                title: "Error",
                description: `Could not delete the file`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            });
        }
        deleteOnClose();
    };

    const onSubmit: SubmitHandler<any> = async (data: any) => {
        setisUpdateHappening(true);
        try {
            let urls: string[] = [];
            for (const knowStoryFile in data['projectFiles']) {
                const file = data['projectFiles'][knowStoryFile];
                if (file instanceof File) {
                    const url = await uploadFileToStorage(file, 'projects');
                    urls.push(url);
                }
            }
            const updatefFiles = { id: currProjectData.id, newUploadedFiles: urls };
            const token = await auth.currentUser?.getIdToken();
            await axios.post(process.env.REACT_APP_BACKEND_URL + "upload-projects-file", JSON.stringify(updatefFiles), {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            },);
            toast({
                title: "Success.",
                description: 'Succesfully uploaded',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            });

            setFiles([]);
            const existingFiles = currProjectData.projectFiles;
            dispatch(setCurrProjectAction({ ...currProjectData, projectFiles: [...urls, ...existingFiles] }));
        } catch (error) {
            toast({
                title: "Error",
                description: `Failed to upload`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            });
        }
        setisUpdateHappening(false);

        onClose();
    };
    return (
        <>
            <Box sx={{ fontSize: {base: "3xl", md: "5xl"} }}>
                <MdModeEditOutline cursor={'pointer'} onClick={onOpen} />
            </Box>
            
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent sx={{ minW: '70vw',maxW: ['90vw'],  }}>
                    <ModalHeader>Edit Project Files</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Stack>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Button
                                    as={'label'}
                                    htmlFor={'projectFiles'}
                                    variant={'outline'}
                                    colorScheme={'teal'}
                                    leftIcon={<i className='fas fa-cloud-upload-alt'></i>}
                                    w='100%'
                                >
                                    Upload Files
                                </Button>
                                <input
                                    id={'projectFiles'}
                                    type={'file'}
                                    accept={'.jpg, .jpeg, .png, .pdf, .doc. .docx, .txt'}
                                    style={{ display: 'none' }}
                                    {...register('projectFiles')}
                                    multiple
                                    onChange={(e) => {
                                        register('projectFiles').onChange(e);  // React Hook Form's onChange
                                        const newFiles = e.target.files!;
                                        setFiles([...newFiles, ...files]);
                                    }}
                                />
                                <VStack>
                                    {files.map((file: File, idx: number) => (
                                        <Flex key={idx} sx={{ justifyContent: 'space-between', alignItems: 'center', minW: ['50%'] }}>
                                            {file.name}
                                            <IoClose color='#FFAF7A' onClick={() => handleRemove(file)} cursor='pointer' />
                                        </Flex>
                                    ))}
                                </VStack>
                                <VStack>
                                    {currProjectData.projectFiles?.map((fileURL: string, idx: number) => {
                                        return (<Flex sx={{ justifyContent: 'space-between', alignItems: 'center', minW: ['50%'] }} key={idx}>
                                            {convertFirebaseURLToFileName(fileURL)}
                                            <IoClose color='#FFAF7A' onClick={() => { setIsDeleting(fileURL); deleteOnOpen(); }} cursor='pointer' />
                                            {fileURL === isDeleteing &&
                                                <Modal isOpen={deleteIsOpen} onClose={deleteOnClose} isCentered>
                                                    <ModalContent sx={{ minW: '50vw',  }}>
                                                        <ModalHeader>Are you sure you want to remove the file {convertFirebaseURLToFileName(fileURL)}?</ModalHeader>
                                                        <ModalCloseButton />
                                                        <ModalBody sx={{ display: 'flex', justifyContent: 'center', gap: ['20px'] }}>
                                                            <Button sx={{ borderRadius: ['50px'] }} onClick={() => deleteFile(fileURL)}>Yes</Button>
                                                            <Button sx={{ borderRadius: ['50px'] }} onClick={deleteOnClose}>No</Button>
                                                        </ModalBody>
                                                    </ModalContent>
                                                </Modal>
                                            }
                                        </Flex>)
                                    })
                                    }
                                </VStack>
                                <Center sx={{ my: ['10px'] }}>
                                    <Button isLoading={isUpdateHappenig} sx={{ borderRadius: ['50px'] }} type='submit'>UPDATE</Button>
                                </Center>
                            </form>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>

        </>
    )
}

export default EditModalProjectFiles;
import { createSlice } from "@reduxjs/toolkit";
export const authTokenSlice = createSlice({
    name: 'authToken',
    initialState: {
        value: '',
    },
    reducers: {
        setAuthTokenAction: (state, action) => {
            state.value = action.payload
        }
    }
});
export const { setAuthTokenAction } = authTokenSlice.actions;

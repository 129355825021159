import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Box, Center, Heading, Text, Grid, GridItem, Flex, Stack, Badge } from '@chakra-ui/react';
import ProjectCard from '../../components/Projects/ProjectCards';
import ChallengerTimer from './challengeTimer';

const ChallengeDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const challenges = useSelector((state: RootState) => state.community.challenges);
  const projects = useSelector((state: RootState) => state.projects.value);
  const challenge: any = challenges.find((challenge: any) => challenge?.id === id);
  const filteredProjects = projects.filter((project: any) => project.challengeInfo?.id === id);
  const renderChallengeStatus = (challenge: any) => {
    const challengeStatus = challenge.challenge.status;
    if (challengeStatus === 'open') {
      return (
            <ChallengerTimer endDate={challenge.challenge.endAt}/>
      );
    }
    if (challengeStatus === 'closed') {
      return (
        <Badge variant="subtle" colorScheme="red">
        <Text fontSize="xl" fontWeight="bold" textAlign="center">
          Challenge has ended
        </Text>
        </Badge>
      );
    }
    if(challengeStatus === 'reviewing') {
      return (
        <Badge variant="subtle" colorScheme="yellow">
        <Text fontSize="xl" fontWeight="bold" textAlign="center">
            Challenge submissions are under review.
        </Text>
        </Badge>
      );
    }
  };
  return (
    <Box sx={{ mx: ['5px', '10px', '15px', '140px'] }}>
      <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(3, 1fr)">
        <GridItem />
        <GridItem>
          <Center>
            <Heading sx={{ fontWeight: ['700'] }}>Indie-Challenge</Heading>
          </Center>
        </GridItem>
      </Grid>
      <Flex sx={{ flexDir: 'column', gap: ['30px'] }}>
        <Stack sx={{ gap: ['6px'] }}>
          <Text sx={{ fontSize: { base: '3xl', md: '5xl' }, fontWeight: ['800'], display: 'flex', alignItems: 'center' }}>
            {challenge?.challenge.name}
          </Text>
          <Text sx={{ borderLeft: '3px solid #FFAF7A', p: ['5px'], fontSize: ['20px'] }}>
            {challenge?.challenge.prompt}
          </Text>
          {renderChallengeStatus(challenge)}
          <Text sx={{ fontSize: ['30px'] }}>{challenge?.challenge.description}</Text>
        </Stack>
        <Stack sx={{ my: '20px' }}>
          <Text sx={{ fontSize: { base: '3xl', md: '5xl' }, fontWeight: ['800'], borderBottom: '4px solid #92C8C8' }}>
            Projects
          </Text>
        </Stack>
        <Flex sx={{ gap: ['10px', '10px', '10px', '50px'], flexWrap: 'wrap', flexDir: 'row' }}>
          {filteredProjects.map((project: any) => (
            <ProjectCard key={project.id} projectCard={project} />
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ChallengeDetails;
import React from 'react';
import HeroSection from '../components/HeroSection/HeroSection'
import About from '../components/About/About'
import '../App.css';
import '../globals.css'
import ProfileSection from '../components/ProfileSection/ProfileSection';
import { Box } from '@chakra-ui/react';

export default function Home() {
  return (
    <Box>
      <HeroSection />
      <ProfileSection />
      <About />
    </Box>
  )
}
import React, { useEffect, useState } from 'react';
import {
    auth
} from '../../utils/firebaseConfig';
import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    useToast,
    Text
} from '@chakra-ui/react';
import { COLOR1, COLOR2 } from '../../utils/ColorConstants';
import { isSignInWithEmailLink, sendPasswordResetEmail, signInWithEmailLink, updatePassword } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';


/**
 * Accept Component
 *
 * The `Accept` component is responsible for handling the activation of user accounts using email links.
 *
 * @component
 * @example
 * <Accept />
 */
const Accept = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const [showTop, setShowTop] = useState(false);
    const [showBottom, setShowBottom] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailAddress, setEmailAddress] = useState<string>('');

    const displayToast = (title: string, description: string, status: 'success' | 'error' | 'warning' | 'info' | undefined) => { 
        toast({
            title: title,
            description: description,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top'
        })
    }


    /**
     * Toggles the visibility of the password input.
     */
    const handleClickTop = () => setShowTop(!showTop);

    /**
     * Toggles the visibility of the confirm password input.
     */
    const handleClickBottom = () => setShowBottom(!showBottom);

    const params = useParams();

    useEffect(() => {
        /**
         * Verifies the email confirmation link and updates user account activation status.
         */
        const verifyEmailCode = async () => {
            const res = await axios.get(process.env.REACT_APP_BACKEND_URL + 'users/get/activated/' + params.id, (
                {
                    headers: {
                        'Accept': 'application/json'
                    }
                }));

            if (res.data.activated) {
                navigate('/');
                toast({
                    title: 'Account has already been activated.',
                    description: 'If you did not activate your account, please contact us now.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                });
            } else if (auth.currentUser?.getIdToken == null) {
                let email = null;
                if (isSignInWithEmailLink(auth, window.location.href.toString())) {
                    while (email !== res.data.emailAddress) {
                        email = window.prompt('Please provide your email for confirmation');
                    }
                    email = email?.trim().toLowerCase();
                    setEmailAddress(email!);
                    await signInWithEmailLink(auth, email!, window.location.href.toString())
                        .then(() => {
                        })
                        .catch((error) => {
                        });
                }
            } else {
                navigate('/');
                toast({
                    title: 'Unconfirmed Email Link',
                    description: 'Try the email link again.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                });
            }
        };

        verifyEmailCode();
    }, []);

    /**
     * Handles the password update and account activation process.
     *
     * @param {Event} e - The form submission event.
     */
    async function handlePasswordUpdate(e: any) {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        try {
            const user = auth.currentUser;
            if (user) {
                await updatePassword(user, newPassword);
                await axios.post(process.env.REACT_APP_BACKEND_URL + 'users/set/activated/' + params.id, (
                    {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }));
                navigate('/');
                toast({
                    title: 'Account was activated.',
                    description: 'Enjoy!',
                    status: 'info',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                });
            } else {
                alert('No authenticated user found, try inputting your email again (or) Your email link may have expired, try to resend the email link below.');
            }
        } catch (error) {
            console.error('Error updating password:', error);
        }
    }

    const sendPasswordResetEmailLink = async () => { 
        try {
            await sendPasswordResetEmail(auth, emailAddress);
            //   await auth.sendPasswordResetEmail(email);
            displayToast('Password reset link sent!', 'Please check your email.', 'success');
        } catch (error) { 
            console.error('Error sending password reset email:', error);
            displayToast('Error sending password reset email', 'Please try again later.', 'error');
        }

    }

    return (
        <>
            <Flex sx={{ justifyContent: 'center', alignItems: 'center', mx: 'auto' }}>
                <form onSubmit={handlePasswordUpdate}>
                    <FormControl>
                        <Heading size={'lg'} marginBottom={'4vh'} textAlign={'center'}>Activate Account</Heading>
                        <Flex direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ color: 'black' }}>
                            <InputGroup size={'md'} marginBottom={'2vh'}>
                                <Input
                                    bg={'white'}
                                    pr={'4.5rem'}
                                    type={showTop ? 'text' : 'password'}
                                    placeholder={'Enter password'}
                                    padding={'5px'}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <InputRightElement width={'4.5rem'}>
                                    <Button h={'1.75rem'} size={'sm'} onClick={handleClickTop}>
                                        {showTop ? 'Hide' : 'Show'}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <InputGroup size='md' marginBottom={'2vh'}>
                                <Input
                                    bg={'white'}
                                    padding={'5px'}
                                    pr={'4.5rem'}
                                    type={showBottom ? 'text' : 'password'}
                                    placeholder={'Re-Enter password'}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <InputRightElement width='4.5rem'>
                                    <Button h='1.75rem' size='sm' onClick={handleClickBottom}>
                                        {showBottom ? 'Hide' : 'Show'}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <Button
                                color={COLOR1}
                                bgColor={COLOR2}
                                borderRadius='45px'
                                p={7}
                                fontSize={{ base: '17px', md: '20px' }}
                                mt={'4'}
                                type={'submit'}
                            >
                                Accept
                            </Button>
                            <Box sx={{ color: 'white' }}>Link expired (or) Having trouble sigining in? Resend new password reset email by clicking <Text sx={{cursor: 'pointer', textDecoration: 'underline', display: 'inline' }} onClick={sendPasswordResetEmailLink}>here</Text> </Box>
                        </Flex>
                    </FormControl>
                </form>
            </Flex>
        </>
    );
};

export default Accept;

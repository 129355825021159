import React, { useEffect, useState } from "react";
import {
  ConversationList,
  Conversation,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useDispatch } from "react-redux";
import {
  setActivieChatroom,
  setShowChatContent,
} from "../../../store/slices/messaging";
import IChatroom from "../../../domain/IChatroom";
import IMessage from "../../../domain/IMessage";
import { defaultImgProfile } from "../../../utils/consts";

const MessagingSidebarList: React.FC = () => {
  const dispatch = useDispatch();
  const currentUser: any = useSelector(
    (state: RootState) => state.profile?.value
  );
  const conversations: Record<string, IChatroom> = useSelector(
    (state: RootState) => state.messaging.chatrooms
  );
  const activeChatroom: string = useSelector(
    (state: RootState) => state.messaging.activeChatroom
  );
  const searchTerm: string = useSelector(
    (state: RootState) => state.messaging.searchTerm
  );
  const isLoading: boolean = useSelector(
      (state: RootState) => state.loading.isUserLoading || state.loading.isChatLoading
  );
  const [lastMessageSent, setLastMessageSent] = useState<
    Record<string, IMessage>
  >({});
  const [filteredConversations, setFilteredConversations] = useState<
    IChatroom[]
  >([]);

  useEffect(() => {
    Object.values(conversations).forEach((conversation) => {
      if (conversation.messages.length === 0) {
        setLastMessageSent((prev) => {
          return {
            ...prev,
            [conversation.id]: {
              id: "",
              sender: "Indie-stry",
              text: "No messages yet",
              timestamp: "",
              senderName: "Indie-stry",
            },
          };
        });
        return;
      }
      const conversationCopy = { ...conversation };
      const messagesCopy = conversationCopy.messages
        .map((msg: IMessage) => { return { ...msg } })
        .sort((a, b) => a.timestamp.localeCompare(b.timestamp));
      conversationCopy.messages = messagesCopy;
      const lastMessage = conversationCopy.messages[conversationCopy.messages.length - 1];
      setLastMessageSent((prev) => {
        return {
          ...prev,
          [conversationCopy.id]: lastMessage,
        };
      });
    });
  }, [conversations]);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredConversations(Object.values(conversations));
      return;
    } else {
      const searchResults = Object.values(conversations).filter((convo) => {
        const userInfo = convo.metadata?.userInfo;
        return Object.values(userInfo).some((user) => {
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchTerm.toLowerCase())
        });
      });
      if (searchResults.length === 0) {
        setFilteredConversations([]);
        return;
      }
      setFilteredConversations(searchResults);
    }
  }, [searchTerm, conversations]);

  const setActiveChatroom = (chatroomId: string) => {
    dispatch(setActivieChatroom(chatroomId));
    if (window.innerWidth < 576) {
      dispatch(setShowChatContent(true));
    }
  };

  const getLastSenderName = (conversation: IChatroom) => {
    if (lastMessageSent[conversation.id]?.sender === currentUser.id) {
      return "You";
    }
    const otherUser = Object.values(conversation.metadata?.userInfo).find((user) => {
      return user.id !== currentUser.id;
    });
    return otherUser?.firstName || "Indiestry";
  };

  const getOtherUserName = (conversation: IChatroom) => {
    const otherUser = Object.values(conversation.metadata?.userInfo).find((user) => {
      return user.id !== currentUser.id;
    });
    return otherUser?.firstName || "Indiestry";
  };

  const getChatAvatar = (conversation: IChatroom) => {
    const otherUser = Object.values(conversation.metadata?.userInfo).find((user) => {
      return user.id !== currentUser.id;
    });
    return otherUser?.headShotUrl !== "" ? otherUser?.headShotUrl : defaultImgProfile;
  };

  return (
    <>
      {Object.values(conversations).length > 0 && Object.keys(lastMessageSent).length !== 0 ? (
        <ConversationList scrollable={true} style={{ overflowY: "scroll" }} loading={isLoading}>
          {filteredConversations.map((conversation) => {
            return (
              <Conversation
                key={conversation.id}
                name={getOtherUserName(conversation)}
                lastSenderName={getLastSenderName(conversation)}
                info={lastMessageSent[conversation.id].text}
                onClick={(event) => setActiveChatroom(conversation.id)}
                {...(activeChatroom === conversation.id
                  ? { active: true }
                  : {})}
              >
                <Avatar
                  src={getChatAvatar(conversation)}
                  name={getOtherUserName(conversation)}
                />
              </Conversation>
            );
          })}
        </ConversationList>
      ) : (
        <h2>No Chats</h2>
      )}
    </>
  );
};

export default MessagingSidebarList;

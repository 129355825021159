import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getPeopleData } from "../api/getProfile";
interface ProfileInfo {
    id: string;
    firstName: string;
    lastName: string;
    city: string;
    state: string;
    roles: string[];
    files: string[];
    interests: string[];
    location: string[];
}
export const usePeopleFilter = () => { 
    const [filterUrl, setFilterUrl] = useState('get-people-data');

    const { data: profileCardData, isLoading, error } = useQuery({
        queryKey: ['people-data', filterUrl], queryFn: () => getPeopleData(filterUrl), refetchOnWindowFocus: false,
    });
    const [profiles, setProfiles] = useState<ProfileInfo[]>();
    const [filters, setFilters] = useState({ 'mainRole': '', 'interests': '', 'nameSearch': '', 'location': '' });
    const updateFilters = (field: string, value: string) => {
        const filter = { [field]: value }
        setFilters({ ...filters, ...filter });
    };

    const filterResults = () => {
        const filterQueries = Object.keys(filters)
            .map((key) => `${key}=${filters[key as keyof typeof filters]}`)
            .join('&');

        setFilterUrl('get-people-data?' + filterQueries);
    }

    return { profiles, setProfiles, filters, updateFilters, filterResults, profileCardData, isLoading, error };
}
import React, { useState } from 'react';
import {
    Button,
    Center,
    Stack,
    InputGroup,
    InputLeftAddon,
    Input,
    useToast,
    FormErrorMessage,
    FormControl,
    ChakraProvider,
    Box,
    Tooltip,
    Flex
} from '@chakra-ui/react'
import { auth } from '../../utils/firebaseConfig';
import { COLOR1, COLOR2 } from '../../utils/ColorConstants';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setProfileInfoAction } from '../../store/slices/profile';
import { useDispatch } from "react-redux";
import { FaInfoCircle } from 'react-icons/fa';

const SignInForm = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [loadingButton, setLoadingButton] = useState(false);
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const email = watch('email');

    const displayToast = (title: string, description: string, status: 'success' | 'error' | 'warning' | 'info' | undefined) => { 
        toast({
            title: title,
            description: description,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top'
        })
    }

     const validateEmail = (email: string): boolean => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const sendResetEmail = async () => {
        if (!validateEmail(email)) {
            setError('Invalid email format.');
            displayToast('Invalid email format.', 'Please enter a valid email address.', 'error');
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            //   await auth.sendPasswordResetEmail(email);
            setMessage('Password reset link sent!');
            displayToast('Password reset link sent!', 'Please check your email.', 'success');
            setError('');
        } catch (error) {
            const firebaseError = error as FirebaseError;

            switch (firebaseError.code) {
                case 'auth/user-not-found':
                    setError('No user found with this email.');
                    displayToast('No user found with this email.', '', 'error');
                    break;
                case 'auth/network-request-failed':
                    setError('Network error, please try again later.');
                    displayToast('Network error, please try again later.', '', 'error');
                    break;
                default:
                    displayToast('An error occurred, please try again.', '', 'error');
                    setError('An error occurred, please try again.');
            }
        };
    }



    async function onSubmit(values: any) {
        try {
            setLoadingButton(() => { return true });
            await signInWithEmailAndPassword(auth, values.email, values.password);
            const uid = auth.currentUser?.uid;
            const token = await auth.currentUser?.getIdToken();
            const { data } = await axios.get(process.env.REACT_APP_BACKEND_URL + `get-profile?id=${uid}`, {
                headers: {
                    Authorization: token
                }
            });
            dispatch(setProfileInfoAction(data));
            navigate(`/`);
        } catch (error) {
            if (error instanceof FirebaseError && error.code === 'auth/wrong-password') {
                toast({
                    title: 'Incorrect Credentials',
                    description: 'Are you sure you enetered the right credentials?',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                })
            } else {
                toast({
                    title: 'Error',
                    description: 'Something went wrong!',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                })
            }
        }
        setLoadingButton(() => { return false });
    }

    return (
        <>
            <ChakraProvider>
                <Stack>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl isInvalid={Boolean(errors.email)}>
                            <InputGroup mb={2}>
                                <InputLeftAddon style={{ justifyContent: 'center' }} w='100px' children='Email' />
                                <Input
                                    type='email'
                                    placeholder='name@example.com'
                                    {...register('email', {
                                        required: 'Email is required'
                                    })}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.email && errors.email.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={Boolean(errors.password)}>
                            <InputGroup>
                                <InputLeftAddon style={{ justifyContent: 'center' }} w='100px' children='Password' />
                                <Input
                                    type='password'
                                    placeholder='password'
                                    {...register('password', {
                                        required: 'Password is required'
                                    })}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.password && errors.password.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>
                        <Flex sx={{alignItems: 'center', gap: '4px', justifyContent: 'end' }} >
                            <Box onClick={sendResetEmail} sx={{cursor: 'pointer', textDecoration: 'underline'}}>Forgot Password?</Box>
                            <Tooltip label='Enter you password first' fontSize='md'>
                                <FaInfoCircle />
                            </Tooltip>
                        </Flex>

                        <Center>
                            <Button
                                color={COLOR1}
                                bgColor={COLOR2}
                                borderRadius={'45px'}
                                p={7}
                                fontSize={{ base: '16px', md: '20px' }}
                                width='50%'
                                mt='4'
                                isLoading={loadingButton}
                                type='submit'
                                _hover={{ bg: COLOR2 }}
                            >
                                Continue
                            </Button>
                        </Center>
                    </form>
                </Stack>
            </ChakraProvider>
        </>
    )
}

export default SignInForm;
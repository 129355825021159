import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { auth } from '../../utils/firebaseConfig';
import ProfileCard from '../../NewHomeComponents/ProfileCard';
import { Box, Flex, Stack } from '@chakra-ui/react';
import AddPeople from '../EditProject/AddPeople';

const ProjectsPeople: React.FC = () => {
    const user:any = useSelector((state: RootState) => state.profile.value);
    const currProject = useSelector((state: RootState) => state.currProject.value);
    const [isOwner, setIsOwner] = useState(false);
    useEffect(() => {
        if(currProject && user) {
            if(currProject.createdBy === user.id) {
                setIsOwner(true);
            }
        }
    }, [currProject]);

    const getPeopleProjectData = async () => {
        const token = await auth.currentUser?.getIdToken();
        const userIds = {
            userIds: currProject.people ? currProject.people : []
        }
        return await axios.post(process.env.REACT_APP_BACKEND_URL + 'get-people-projects', userIds, {
            headers: {
                'Authorization': token,
                'Accept': 'application/json'
            }
        })
    }
    const { data: profileCardsData, isLoading, error } = useQuery<any>({
        queryKey: ['poeople-project-data', currProject.people],
        queryFn: getPeopleProjectData,
        // refetchOnWindowFocus: false
    })

    return (
        <Stack sx={{gap: ['40px'] }}>
            {profileCardsData?.data?.length > 0 &&
                <Box sx={{ borderBottom: '#FFAF7A 6px solid', fontSize: {base: "3xl", md: "5xl"} , fontWeight: '800', w: ['100%','100%','100%','60%'] }}>Meet The Team</Box>
            }
            { isOwner && <AddPeople /> }
            <Flex sx={{ gap: ['10px', '10px', '10px', '50px'], flexWrap: 'wrap', flexDir: 'row' }}>
                {
                    profileCardsData?.data.map((profileData: ProfileCardType, idx: number) => {
                        return <ProfileCard key={idx} profileInfo={profileData} />
                    })
                }
            </Flex>
        </Stack>
    )
}

export default ProjectsPeople;
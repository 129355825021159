import React, { useState } from 'react';

import AsyncSelect from 'react-select/async';
import { getSuggestions } from '../../api/getPeople';
import { Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrProjectAction } from '../../store/slices/currProject';
interface Item {
    label: string;
    value: string;
}
const customStyles = {
    option: (provided: any, state: { isSelected: boolean }) => ({
        ...provided,
        backgroundColor: 'transparent',
        color: state.isSelected ? 'white' : 'black',
        padding: 20,
    }),
    input: (provided: any) => ({
        ...provided,
        fontWeight: '800',
        color: 'white'
    }),
    control: (provided: any) => ({
        ...provided,
        backgroundColor: 'transparent',
        color: '#fff'
    })
};

const AddPeople: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedOptions, setSelectedOptions] = useState<Item[]>([]);
    const currProject = useSelector((state: RootState) => state.currProject.value)
    const [addingPeople, setIsAddingPeople] = useState<boolean>(false);
    const toast = useToast();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const users = async (inputValue: string) => {
        const nameOptions = await getSuggestions(inputValue);
        const nameSuggestions = nameOptions.map((val: any) => {
            if (!currProject.people || !currProject.people.includes(val?.value)) {
                return { value: val.value, label: val.label };
            }
        });
        return nameSuggestions.filter((nameSuggestion: string) => nameSuggestion !== undefined);
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<Item[]>((resolve) => {
            setTimeout(() => {
                const data = users(inputValue);
                resolve(data);
            }, 1000);
        });

    const addPeopleProject = async () => {
        setIsAddingPeople(true);
        try {
            const projectWithCoverPageEdited = {
                projectId: currProject.id,
                userIds: selectedOptions.map((option) => option.value)
            }
            await axios.post(process.env.REACT_APP_BACKEND_URL + 'add-people-projects', projectWithCoverPageEdited, {
                headers: {
                    'Accept': 'application/json'
                }
            });
            dispatch(setCurrProjectAction({ ...currProject, people: currProject.people ? [...projectWithCoverPageEdited.userIds, ...currProject.people] : [...projectWithCoverPageEdited.userIds] }))
            toast({
                title: "Success",
                description: 'Added people successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })

        } catch (error) {
            toast({
                title: "Error",
                description: 'Failed to add People',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        }
        setSelectedOptions([]);
        onClose();

        setIsAddingPeople(false);
    }
    const handleChange = (options: any) => {
        setSelectedOptions(options);
    };

    return (
        <>
            <Center>
                <Button sx={{ borderRadius: '50px', my: ['20px'] }} onClick={onOpen}>Add People</Button>
            </Center>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent sx={{  }}>
                    <ModalHeader>Add People to your project</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AsyncSelect
                            styles={customStyles}
                            isMulti
                            cacheOptions
                            defaultOptions
                            loadOptions={promiseOptions}
                            onChange={handleChange}
                            value={selectedOptions}
                        />
                    </ModalBody>
                    <Center sx={{ my: ['10px'] }} >
                        <Button isLoading={addingPeople} mr={3} sx={{ borderRadius: '50px' }} onClick={() => { addPeopleProject() }}>
                            Add
                        </Button>
                    </Center>
                </ModalContent>
            </Modal >
        </>
    )
};
export default AddPeople;

import React, { useEffect, useCallback, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react'
import { Image } from '@chakra-ui/react';
import './carousel.css';
interface AutoplayCarouselProps{
    slides: any;
}
const AutoplayCarousel: React.FC<AutoplayCarouselProps> = ({ slides }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [autoplay, setAutoplay] = useState(true);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi || !autoplay) return;
    const interval = setInterval(scrollNext, 3000); // Change 3000 to your desired interval in milliseconds

    return () => clearInterval(interval);
  }, [emblaApi, autoplay, scrollNext]);

  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
    {
      slides.map((slide: any, index: any) => (
          <div className="embla__slide" key={index}>
          <Image maxW={'100%'} maxH={'400px'} objectFit={'cover'} overflow={'hidden'} src={slide} alt={`Slide ${index}`} />
          </div>
      ))}
      </div>
    </div>
  );
};

export default AutoplayCarousel;

// react chakra ui modal component that has a submit link input and a submit button, the input field should only accept Youtube or Vimeo links

import React from 'react'
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Input, Box, Link } from '@chakra-ui/react'
import ErrorComponent from '../Utils/ErrorComponent';
import { useForm } from 'react-hook-form';
import useSubmitChallengeProject from '../../hooks/challenges/useSubmitChallengeProject';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';


const SubmitChallengeProjectLinkModal: React.FC = () => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { register, handleSubmit, formState: { errors } } = useForm<{ link: string }>();
    const { submitProjectSubmissionLink, isYoutubeOrVimeoLink, submittingProjectLink, submittingProjectLinkError } = useSubmitChallengeProject();
    const currentProject = useSelector((state: RootState) => state.currProject.value);

    const onSubmit = (data: { link: string }) => {
        submitProjectSubmissionLink(currentProject.id, data.link);
    }


    return (
      <>
          <Button sx={{ borderRadius: ['50px'] }} onClick={onOpen}>{currentProject.submissionLink ? "View Submission" : "Submit Project"}</Button>
          <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>

                  <form onSubmit={handleSubmit(onSubmit)}>
                      <ModalHeader> {currentProject.submissionLink ? "View Submission" : "Submit Challenge Project Link"}</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                          {currentProject.submissionLink ?
                              <Link href={currentProject.submissionLink} target="_blank">{currentProject.submissionLink}</Link>
                              :
                              <Input placeholder='Enter Link' {...register("link", { required: 'Link is required', validate: isYoutubeOrVimeoLink })} />

                          }
                          {errors.link && <Box>{errors.link.message}</Box>}
                      </ModalBody>
                      {!currentProject.submissionLink &&

                          <ModalFooter>
                              <Button colorScheme='blue' type='submit' isLoading={submittingProjectLink}>
                                  Submit
                              </Button>
                          </ModalFooter>}
                      {submittingProjectLinkError && <ErrorComponent title='Error' message="Could not make project submission" />}
                  </form>
              </ModalContent>

          </Modal>

      </>
  )

}

export default SubmitChallengeProjectLinkModal;

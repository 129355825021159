import React from "react";
import { Box, Center, Input, Button } from "@chakra-ui/react";
import { useChat } from "../../../hooks/messages/useChat";
import { useDispatch } from "react-redux";
import { setSearchTerm } from "../../../store/slices/messaging";
import NewMessageRoom from "./NewChatRoom";

const MessagingSidebarSearch: React.FC = () => {
    const dispatch = useDispatch();

    const { openModal, closeModal, userId, isModalOpen } = useChat();
    return (
        <Box>
            <Center flexDirection={"column"}>
                <Input
                    placeholder="Search Chats"
                    borderColor={"#92C8C8"}
                    onChange={(e) => dispatch(setSearchTerm(e.target.value))}
                />
                <Button
                    marginTop={"2"}
                    bgColor={"#92C8C8"}
                    textColor={"white"}
                    w={"100%"}
                    onClick={openModal}
                >
                    + Start New Chat
                </Button>
                <NewMessageRoom
                onOpen={isModalOpen}
                onClose={closeModal}
                userId={userId}
                roomId=""
                ></NewMessageRoom>
            </Center>
        </Box>
    );
};

export default MessagingSidebarSearch;
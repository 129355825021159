import React from 'react';
import { Box, Text, VStack, Container, Link } from '@chakra-ui/react';

const Unsubscribe = () => {
  return (
    <Container centerContent>
      <VStack spacing={4} align="stretch">
        <Box textAlign="center" paddingTop="100px">
          <Text fontSize="4xl" fontWeight="bold">
            Unsubscribe
          </Text>
        </Box>
        <Box textAlign="center">
          <Text fontSize="md">
            If you've found this page, it's because you clicked on the 'Unsubscribe' link in one of our emails.
            We don't have an email list for you to unsubscribe from, but we need to have the link there for email validation purposes.
          </Text>
          <Text>
            You can go back home <Link href="/">here</Link>.
            </Text>
        </Box>
      </VStack>
    </Container>
  );
};

export default Unsubscribe;

import { Heading } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import SelectButtonInput from '../Forms/SelectButtonInput';
import styles from './applyforms.module.css';

interface InterestsProps {
    setValue: any;
    initialInterests?: string[];
}

/**
 * Interests Component
 *
 * The `Interests` component allows users to select their interests for a given application. Users can choose from a list of predefined interests, and the selected interests 
 * are made available via a callback function.
 *
 * @component
 * @example
 * <Interests setValue={handleSetValue} />
 *
 * @param {Object} props - Component properties
 * @param {Function} props.setValue - A callback function to set the selected interests in the parent component's state.
 */
const Interests: React.FC<InterestsProps> = ({ setValue, initialInterests }) => {
    const allInterests = ['Adventure', 'Drama', 'Horror', 'Non-Fiction', 'Documentary', 'Romance', 'Action', 'Television', 'Comedy', 'Musical', 'New Media', 'Narrative'];
    let defaultInterests: string[] = [];
    if (initialInterests) {
        defaultInterests = initialInterests;

    }

    const [activeInterests, setActiveInterests] = useState<string[]>(defaultInterests);

    const toggleInterestActive = (interest: string) => {
        if (activeInterests.includes(interest)) {
            setActiveInterests(activeInterests.filter(activeInterest => activeInterest !== interest));
        } else {
            setActiveInterests([...activeInterests, interest]);
        }
    };

    useEffect(() => {
        setValue('selectedInterests', activeInterests);
    }, [activeInterests, setValue]);

    return (
        <div>
            <Heading
                fontSize={{ base: '20px', lg: '25px' }}
                fontWeight={'bold'}>
                Interests
            </Heading>
            {/* Interest Buttons */}
            <div className={styles.buttonContainer}>
                {allInterests.map(interest => (
                    <SelectButtonInput
                        key={interest}
                        value={interest}
                        isActive={activeInterests.includes(interest)}
                        onToggleActive={() => toggleInterestActive(interest)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Interests;

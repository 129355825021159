import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdModeEditOutline } from "react-icons/md";
import {
  uploadFileToStorage,
} from "../../utils/helpers";
import { setCurrProfileAction } from "../../store/slices/currProfile";
import axios from "axios";
import { auth } from "../../utils/firebaseConfig";
import { useEditPortfolio } from "../../hooks/useEditPortfolio";
import EditPortfolioFiles from "./EditPortfolioFiles";
import EditPortfolioLinks from "./EditPortfolioLinks";
import ErrorComponent from "../Utils/ErrorComponent";

const EditModalProfileScripts: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isUpdateHappening, setisUpdateHappening] = useState<boolean>(false);

  const {currProfile, dispatch, toast, formatUrl, deleteFile, isDeleteing, setIsDeleting, handleRemove, files, setFiles, deleteVideoLink, handleAddLink, handleLinkChange, handleRemoveLink, videoLinks, setVideoLinks, isYoutubeOrVimeoLink } = useEditPortfolio();
  const onSubmit: SubmitHandler<any> = async (data: any) => {
    setisUpdateHappening(true);
    try {
      const token = await auth.currentUser?.getIdToken();
      if (data["files"].length > 0) {
      let urls: string[] = [];
      for (const inspireFile in data["files"]) {
        const file = data["files"][inspireFile];
        if (file instanceof File) {
          const url = await uploadFileToStorage(file, "projects");
          urls.push(url);
        }
      }
      const updatefFiles = { id: currProfile.id, newUploadedFiles: urls };
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "profile/upload-file",
        JSON.stringify(updatefFiles),
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      toast({
        title: "Success.",
        description: "Succesfully uploaded",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setFiles([]);
      const existingFiles = currProfile.files;
      dispatch(
        setCurrProfileAction({
          ...currProfile,
          files: [...urls, ...existingFiles],
        })
      );
      }
      if (videoLinks.length > 0) {
        
        const newVideoUrls = videoLinks.map((link) => link.url)   
        for (const videoLink in newVideoUrls) {
          const link = newVideoUrls[videoLink];
          if (!isYoutubeOrVimeoLink(link)) {
            throw new Error("Invalid video link");
            
          }
        }
      const updateVideoLinks = { id: currProfile.id, newVideoLinks: newVideoUrls };
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "profile/upload-video-link",
        JSON.stringify(updateVideoLinks),
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      toast({
        title: "Success.",
        description: "Succesfully uploaded",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      const existingVideoLinks = currProfile.videoLinks;
      dispatch(
        setCurrProfileAction({
          ...currProfile,
          videoLinks: [...newVideoUrls, ...existingVideoLinks],
        })
        );  
        setVideoLinks([]);
      }
    } catch (error) {
      if ( error instanceof Error && error.message === "Invalid video link") { 
        toast({
        title: "Error",
        description: 'Invalid youtube or vimeo link',
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      } else {
      toast({
        title: "Error",
        description: 'Failed to upload',
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      }); 
      }
    }
    setisUpdateHappening(false);
    onClose();
  };
  return (
    <>
      <Box sx={{ fontSize: { base: "3xl", md: "5xl" } }}>
            <MdModeEditOutline cursor={"pointer"} onClick={onOpen}/>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent sx={{ minW: "70vw", mx: ["2vw"], fontFamily: "Avenir" }}>
          <ModalHeader>Edit Project Files</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <form onSubmit={handleSubmit(onSubmit)}>
                <EditPortfolioFiles register={register} files={files} setFiles={setFiles} handleRemove={handleRemove} setIsDeleting={setIsDeleting} isDeleteing={isDeleteing} deleteFile={deleteFile} currProfile={currProfile} />
                <EditPortfolioLinks register={register} deleteVideoLink={deleteVideoLink} currProfile={currProfile} formatUrl={formatUrl} isDeleteing={isDeleteing} setIsDeleting={setIsDeleting} handleAddLink={handleAddLink} handleLinkChange={handleLinkChange} handleRemoveLink={handleRemoveLink} videoLinks={videoLinks} setVideoLinks={setVideoLinks}/>
                <Center sx={{ my: ["10px"] }}>
                  <Button
                    isLoading={isUpdateHappening}
                    sx={{ borderRadius: ["50px"] }}
                    type="submit"
                  >
                    UPDATE
                  </Button>
                </Center>
              </form>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditModalProfileScripts;

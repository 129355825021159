import { CloseIcon } from '@chakra-ui/icons';
import { VStack, Flex, Modal, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button, useDisclosure, Link, Box, FormControl, FormLabel, IconButton, Input, InputGroup, InputRightElement, Stack } from '@chakra-ui/react';
import React from 'react'
import { IoClose } from 'react-icons/io5';
interface EditPortfolioLinksProps { 
    register: any;
    videoLinks: any;
    setVideoLinks: React.Dispatch<React.SetStateAction<any>>;
    handleAddLink: () => void;
    handleLinkChange: (id: number, url: string) => void;
    handleRemoveLink: (id: number) => void;
    currProfile: any;
    isDeleteing: string;
    setIsDeleting: React.Dispatch<React.SetStateAction<string>>;
    formatUrl: (url: string) => string;
    deleteVideoLink: (videoLink: string) => void;
}

const EditPortfolioLinks: React.FC<EditPortfolioLinksProps> = ({register, currProfile, isDeleteing, setIsDeleting, formatUrl, deleteVideoLink, videoLinks, setVideoLinks, handleAddLink, handleLinkChange, handleRemoveLink}) => {
   const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose} = useDisclosure();
    
    return (
        <>
            <Box>
                <Stack spacing={4}>
                    {videoLinks.map((link: any, index: number ) => (
                        <FormControl key={link.id}>
                            <FormLabel htmlFor={`video-url-${link.id}`}>Video URL {index + 1}</FormLabel>
                            <InputGroup>
                                <Input
                                    id={`video-url-${link.id}`}
                                    type="text"
                                    value={link.url}
                                    onChange={(e) => {
                                        handleLinkChange(link.id, e.target.value)
                                    }}
                                    placeholder="Enter YouTube or Vimeo URL"
                                />
                                <InputRightElement>
                                    <IconButton
                                        aria-label="Remove video link"
                                        icon={<CloseIcon />}
                                        onClick={() => handleRemoveLink(link.id)}
                                    />
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                    ))}
                    <Button onClick={handleAddLink} colorScheme="teal" variant="outline">Add New Video Link</Button>
                </Stack>
            </Box>
            <VStack>
                  {currProfile.videoLinks?.map(
                    (videoLink: string, idx: number) => {
                      return (
                        <Flex
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            minW: ["50%"],
                          }}
                          key={idx}
                        >
                          <Link href={videoLink} target="_blank"> {formatUrl(videoLink)}</Link>
                          <IoClose
                            color="#FFAF7A"
                            onClick={() => {
                              setIsDeleting(videoLink);
                              deleteOnOpen();
                            }}
                            cursor="pointer"
                          />
                          {videoLink === isDeleteing && (
                            <Modal
                              isOpen={deleteIsOpen}
                              onClose={deleteOnClose}
                              isCentered
                            >
                              <ModalContent
                                sx={{ minW: "50vw", fontFamily: "Avenir" }}
                              >
                                <ModalHeader>
                                  Are you sure you want to remove the link{" "}
                                  {formatUrl(videoLink)}?
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: ["20px"],
                                  }}
                                >
                                  <Button
                                    sx={{ borderRadius: ["50px"] }}
                                    onClick={() => deleteVideoLink(videoLink)}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    sx={{ borderRadius: ["50px"] }}
                                    onClick={deleteOnClose}
                                  >
                                    No
                                  </Button>
                                </ModalBody>
                              </ModalContent>
                            </Modal>
                          )}
                        </Flex>
                      );
                    }
                  )}
            </VStack>
        </>

  )
}

export default EditPortfolioLinks
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
    Card,
    CardBody,
    CardFooter,
    Button,
    Flex,
    Badge,
    Text,
    Stack,
    Box
} from '@chakra-ui/react';
import iEvent from '../../domain/community/event';

type FormattedDate = {
    dayOfTheWeek: string;
    dateSection: string;
    timeSection: string;
};

const Events: React.FC = () => {
    const events = useSelector((state: RootState) => state.community.events);
    const [formattedDates, setFormatedDates] = useState<Record<string, FormattedDate>>({});

    useEffect(() => {
        if(events.length > 0) {
            const formattedDates: Record<string, FormattedDate> = events.reduce(
                (acc: Record<string, FormattedDate>, event: { event: iEvent, id:string} ) => {
                    const date = formatDate(event.event.eventDate)
                    acc[event.id] = date;
                    return acc;
            },{});
            setFormatedDates(formattedDates);
        }
    }, [events]);

    const formatDate = (dateString: string):FormattedDate => {
        const dateTimeObj = new Date(dateString);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayOfTheWeek = days[dateTimeObj.getDay()];
        const formattedDate = dateTimeObj.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
        const formattedTime = dateTimeObj.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
        return {
            dayOfTheWeek,
            dateSection: formattedDate,
            timeSection: formattedTime
        };;
    };

    const styles = {
        dateContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };

    return (
        <div>
            {events.length > 0 ?
                <div>
                    {events.map((event) => {
                        return (
                            <Card
                                key={event.id}
                                direction={{ base: 'column'}}
                                overflow='hidden'
                                variant='outline'
                                backgroundColor={'transparent'}
                                textColor={'white'}
                                width={{ base: '100%', md: '100%', lg: '100%' }}
                                marginBottom={{ base: '10px', md: '10px', lg: '10px' }}
                            >
                                    <CardBody>
                                        <Stack>
                                            {formattedDates[event.id] && (
                                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Badge variant='subtle' colorScheme='green'>
                                                        <div>
                                                            <div style={styles.dateContainer}>
                                                                <div>
                                                                    <strong>{formattedDates[event.id].dayOfTheWeek}, </strong>
                                                                </div>
                                                                <div>
                                                                    {formattedDates[event.id].dateSection} |
                                                                </div>
                                                                <div>
                                                                    {formattedDates[event.id].timeSection}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Badge>
                                                </Box>
                                            )}
                                            <Text
                                                style={{
                                                    fontSize: "17px"
                                                }}
                                            >
                                                <strong>{event.event.name}</strong>
                                            </Text>
                                            {event.event.eventOrganizer &&
                                                <Text>
                                                    {event.event.eventOrganizer}
                                                </Text>
                                            }
                                            {event.event.location &&
                                                <Text>Location: {event.event.location}</Text>
                                            }
                                        </Stack>
                                    </CardBody>
                                    <CardFooter padding={"0"}>
                                        <Flex justify="space-between" width="100%">
                                            {event.event.url && (
                                                <Button
                                                    flex="1"
                                                    style={{
                                                        borderRadius: "0px",
                                                    }}
                                                    colorScheme='green'
                                                    onClick={() => {
                                                        window.open(event.event.url, '_blank');
                                                    }}
                                                >
                                                    <p>Event Details</p>
                                                </Button>
                                            )}
                                        </Flex>
                                    </CardFooter>
                            </Card>
                        );
                    })}
                </div> : <h2>Check back for new events!</h2>
            }
        </div>
    );
};

export default Events;
import axios from "axios";
import { auth } from "../../utils/firebaseConfig";
import { useState } from "react";

const useSubmitChallengeProject = () => {

    const [submittingProjectLink, setSubmittingProjectLink] = useState<boolean>(false);
    const [submittingProjectLinkError, setSubmittingProjectLinkError] = useState<boolean>(false);


    const submitProjectSubmissionLink = async (projectId: string, link: string) => {
        // a post request to submit the link using edit-project end point, with the id and link in the body. Make a post request
        setSubmittingProjectLink(true);
        try {
            const token = await auth.currentUser?.getIdToken();
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "submit-challenge", JSON.stringify({ id: projectId, submissionLink: link }), {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', // Make sure this line is included
                    Authorization: token
                }
            })
        } catch (error) {
            setSubmittingProjectLinkError(true);
            console.error('Error fetching data:', error);
        }
        setSubmittingProjectLink(false);
    }

    const isYoutubeOrVimeoLink = (link: string) => {
        const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
        const vimeoPattern = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+$/;
        return youtubePattern.test(link) || vimeoPattern.test(link) || 'Enter valid Vimeo or YouTube link';
    };

    return { submittingProjectLink, submittingProjectLinkError, submitProjectSubmissionLink, isYoutubeOrVimeoLink }
}

export default useSubmitChallengeProject;
import { PayloadAction, createSlice } from "@reduxjs/toolkit";


const roles = [
    {label: 'Director', active: false},
    {label: 'Actor', active: false},
    {label: 'AD', active: false},
    {label: 'VFX', active: false},
    {label: 'Writer', active: false},
    {label: 'Composer', active: false},
    {label: 'Producer', active: false},
    {label: 'DP', active: false},
    {label: 'Gaffer', active: false},
    {label: 'Editor', active: false},
    {label: 'Production Designer', active: false},
    {label: 'Other', active: false, userInput: ''},
]

export const rolesSlice = createSlice({
    name: 'roles',
    initialState:{roles: roles},
    reducers: {
        setRole: (state, action) => {
            let role = state.roles.find(role => role.label === action.payload);
            if(role){
            if (role.active) role.active = false;
            else role.active = true;
            if(role.label === 'Other') role.userInput = '';
            let newRoles = [...state.roles]  
            newRoles[state.roles.findIndex(role => role.label === action.payload)] = role;
            state.roles = newRoles;         
            }    
        },
        setUserDefinedRole: (state, action) => {
            const role = state.roles.find(role => role.label === 'Other');
            if (role) {
                role.userInput = action.payload;
            }
        },
        resetRoles: (state) => {
            state.roles = roles;
        },
        setEditRole: (state, action: PayloadAction<any>) => {
            if (Array.isArray(action.payload) && action.payload.every((role) => typeof role === 'string')) {
                state.roles = state.roles.map((role) => {
                    if (action.payload.includes(role.label)) {
                        role.active = true;
                    }
                    return role;
                });
            }
            if (Array.isArray(action.payload) && action.payload.every((role) => typeof role === 'object')) {
                state.roles = action.payload;
            }
        }
    }
});

export const { setRole, setUserDefinedRole, resetRoles, setEditRole } = rolesSlice.actions;
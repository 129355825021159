import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import axios from "axios";
import { auth } from "../utils/firebaseConfig";
import { useDispatch } from "react-redux";
import { setProjectsAction } from "../store/slices/project";

export const useProjectsFilter = () => { 
    const [filterUrl, setFilterUrl] = useState('get-all-projects');
    const dispatch = useDispatch();
    const [projects, setProjects] = useState();
    const [filters, setFilters] = useState({ 'mainRole': '', 'interests': '', 'nameSearch': '', 'location': '' });

    const getAllProjects = async () => {
        return await axios.get(process.env.REACT_APP_BACKEND_URL + filterUrl, {
            headers: {
                Accept: 'application/json'
            }
        });
    };

    const updateFilters = (field: string, value: string) => {
        const filter = { [field]: value }
        setFilters({ ...filters, ...filter });
    };

    const { isLoading, error, data } = useQuery({ queryKey: ['all-projects', filterUrl], queryFn: getAllProjects, refetchOnWindowFocus: false });


    if (data) {
        dispatch(setProjectsAction(data?.data));
    }


    const filterResults = () => {
        const filterQueries = Object.keys(filters)
            .map((key) => `${key}=${filters[key as keyof typeof filters]}`)
            .join('&');
        setFilterUrl('get-all-projects?' + filterQueries);
    }

    return { projects, setProjects, filters, updateFilters, filterResults, data, isLoading, error };
}
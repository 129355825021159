import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
    Card,
    CardBody,
    CardFooter,
    Button,
    Flex,
    Badge,
    Text,
    Stack,
    Box
} from '@chakra-ui/react';
 interface iGrants {
    id: string;
    title: string;
    url: string;
    deadline: string;
    org?: string;
    award?: string;
    createdAt?: string;
    description?: string;
  };

type FormattedDate = {
    dayOfTheWeek: string;
    dateSection: string;
    timeSection: string;
};

const Grants: React.FC = () => {
    const grants = useSelector((state: RootState) => state.community?.grants)
    const [formattedDates, setFormattedDates] = useState<Record<string, FormattedDate>>({});

useEffect(() => {
    if (grants && grants.length > 0) {
        const formattedDates: Record<string, FormattedDate> = grants.reduce(
            (acc: Record<string, FormattedDate>, grant: any) => {
                const date = formatDate(grant.grant.deadline);
                acc[grant.id] = date;
                return acc;
            },
            {}
        );
        setFormattedDates(formattedDates);
    }
}, [grants]);

const formatDate = (dateString: string): FormattedDate => {
    const dateTimeObj = new Date(dateString);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayOfTheWeek = days[dateTimeObj.getDay()];
    const formattedDate = dateTimeObj.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });
    const formattedTime = dateTimeObj.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    return {
        dayOfTheWeek,
        dateSection: formattedDate,
        timeSection: formattedTime
    };
};
    const styles = {
        dateContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };

    const isDeadlinePassed = (date: string) => {
        const deadline = new Date(date);
        const currentDate = new Date();
        return currentDate > deadline;
    };    

    const onGrantClick = (url: string, deadline: string) => {
        if(isDeadlinePassed(deadline)) return undefined
        else return window.open(url, '_blank');
    }

    return (
        <div>
            {grants.length > 0 ? (
                <div>
                    {grants.map((grant: any) => (
                        <Card
                            key={grant.id}
                            direction={{ base: 'column' }}
                            overflow="hidden"
                            variant="outline"
                            backgroundColor={'transparent'}
                            textColor={'white'}
                            width={{ base: '100%', md: '100%', lg: '100%' }}
                            marginBottom={{ base: '10px', md: '10px', lg: '10px' }}
                        >
                            <CardBody>
                                <Stack>
                                    {formattedDates[grant.id] && (
                                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Badge variant="subtle" 
                                            colorScheme={isDeadlinePassed(grant.grant.deadline) ? 'red' : 'green'}
                                            >
                                                <div>
                                                    <div style={styles.dateContainer}>
                                                        <div>
                                                            <strong>{formattedDates[grant.id].dayOfTheWeek}, </strong>
                                                        </div>
                                                        <div>
                                                            {formattedDates[grant.id].dateSection} |
                                                        </div>
                                                        <div>
                                                            {formattedDates[grant.id].timeSection}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Badge>
                                        </Box>
                                    )}
                                    <Text
                                        style={{
                                            fontSize: '17px',
                                        }}
                                    >
                                        <strong>{grant.grant.title}</strong>
                                    </Text>
                                    {grant.grant.org && <Text>Organization: {grant.grant.org}</Text>}
                                    {grant.grant.award && <Text>Award: {grant.grant.award}</Text>}
                                    {grant.grant.description && <Text>{grant.grant.description}</Text>}
                                </Stack>
                            </CardBody>
                            <CardFooter padding={'0'}>
                                <Flex justify="space-between" width="100%">
                                    {grant.grant.url && (
                                        <Button
                                            flex="1"
                                            style={{
                                                borderRadius: '0px',
                                            }}
                                            colorScheme={isDeadlinePassed(grant.grant.deadline) ? 'red' : 'green'}
                                            onClick={() => {
                                                onGrantClick(grant.grant.url, grant.grant.deadline);                                                    
                                            }}
                                        >
                                            <p>Grant Details</p>
                                        </Button>
                                    )}
                                </Flex>
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            ) : (
                <h2>Check back for new grants!</h2>
            )}
        </div>
    );
};

export default Grants;
import React, { ReactNode, useEffect, useState } from 'react'
import { auth } from '../utils/firebaseConfig';
import { Box } from '@chakra-ui/react';

interface Props {
    children?: ReactNode
}

const ProtectedRoute = ({ children }: Props) => {

    const [isAuthenticated, setIsAuthenticated] = useState<Boolean | null>(null);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setIsAuthenticated(user !== null);
        });
    }, []);

    return <>{isAuthenticated ? children : <Box sx={{ mx: 'auto', fontSize: ['25px'], fontWeight: '500', color: '#E7E0DB' }}>Uh, oh! Looks like you are not signed in! Please sign in to access the page!</Box>}</>
};

export default ProtectedRoute;
import { createSlice } from "@reduxjs/toolkit";

const initialCurrProfile: any = {};
export const currProfileSlice = createSlice({
    name: 'currProfileData',
    initialState: {
        value: initialCurrProfile,
    },
    reducers: {
        setCurrProfileAction: (state, action) => {
            state.value = action.payload
        }
    }
});

export const { setCurrProfileAction } = currProfileSlice.actions;

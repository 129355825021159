import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Center,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import EditModalBasicInfo from "../EditProject/Modal/EditModalBasicInfo";
import AvatarEditor from "react-avatar-editor";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, storage } from "../../utils/firebaseConfig";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setCurrProjectAction } from "../../store/slices/currProject";
import { defaultProjectCoverPage } from "../../utils/consts";
import { useChat } from "../../hooks/messages/useChat";
import { useNavigate } from "react-router-dom";
import { setEditRole } from "../../store/slices/roles";
import {challengeBadge} from '../../utils/consts'
const ProjectBio: React.FC = () => {
  const dispatch = useDispatch();
  
  const joinRolesNeeded = (roles: any) => {
    if (Array.isArray(roles) && roles.every((role) => typeof role === 'string')) {
      return roles.map((role: string, idx: number) => {
        return (
          <Text sx={{ fontSize: "25px", fontWeight: ["500"] }} key={idx}>
            {" "}
            {role}
          </Text>
        );
      })
    }
    if (Array.isArray(roles) && roles.every((role) => typeof role === 'object')) {
      return roles.map((role: any, i: number) => {
        if (role.active) {
          return (
            <Text sx={{ fontSize: "25px", fontWeight: ["500"] }} key={i}>
              {" "}
              {role.label === "Other" ? role.userInput : role.label}{" "}
            </Text>
          )
        }
      })
    }
  }

  const currProjectData: any = useSelector(
    (state: RootState) => state.currProject.value
  );
  dispatch(setEditRole(currProjectData.rolesNeeded))
  const hasChallenge = currProjectData.challengeInfo ? true : false;

  const [isProjectCreator, setIsProjectCreator] = useState<boolean>(false);
  const profileData: any = useSelector(
    (state: RootState) => state.profile.value
  );
  const loggedInProfile: any = useSelector((state: RootState) => state.profile.value);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [img, setImg] = useState<File | null>(null);
  const editorRef = useRef<AvatarEditor | null>(null);
  const [filename, setFilename] = useState<string | undefined>("");

  const toast = useToast();
  const { createMessageRoom } = useChat();
  const navigate = useNavigate();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setFilename(file?.name);
    setImg(file);
    onOpen();
  };
  const handleUpload = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob(async (blob: any) => {
        if (blob) {
          try {
            blob.name = filename;
            const storageRef = ref(
              storage,
              `projects/${blob.name || "unnamed"}`
            );
            const uploadTaskSnapshot = await uploadBytes(storageRef, blob);
            const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
            const projectWithCoverPageEdited = {
              ...currProjectData,
              coverPageURL: downloadURL,
            };
            const token = await auth.currentUser?.getIdToken();
            await axios.post(
              process.env.REACT_APP_BACKEND_URL + "edit-basic-info",
              JSON.stringify(projectWithCoverPageEdited),
              {
                headers: {
                  Authorization: token,
                  "Content-Type": "application/json",
                },
              }
            );
            toast({
              title: "Success",
              description: "Your project has been updated",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            dispatch(
              setCurrProjectAction({
                ...currProjectData,
                coverPageURL: downloadURL,
              })
            );
          } catch (error) {
            toast({
              title: "Error",
              description: "Your project failed to update",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          }
          onClose();
        }
      });
    }
  };
  useEffect(() => {
    if (profileData?.id === currProjectData.createdBy) {
      setIsProjectCreator(true);
    }
  }, []);

  const onConnect = async () => {
    const userData = [loggedInProfile.id, currProjectData.createdBy];
    createMessageRoom(userData);
    navigate(`/messages`);
  };

  return (
    <Flex
      sx={{
        gap: ["10px", "20px", "30px", "100px", "150px"],
        justifyContent: "space-between",
        flexDir: ["column-reverse", "column-reverse", "column-reverse", "row"],
      }}
    >
      <Stack sx={{ gap: ["6px"] }}>
      {hasChallenge && (
      <>
      <Box
        // position="absolute"
        // top="10px"
        // right="10px"
        width="100px"
        height="100px"
        backgroundImage={`url(${challengeBadge})`}
        backgroundSize="cover"
        backgroundPosition="center"
      />
      <Text sx={{ fontSize: "20px", fontWeight: "bold", color: "brand3" }}>
       Indie-Challenge: {currProjectData.challengeInfo.challenge.prompt}  
      </Text>
      </>
    )}
        <Text
          sx={{
            fontSize: { base: "3xl", md: "5xl" },
            fontWeight: ["800"],
            display: "flex",
            alignItems: "center",
          }}
        >
          {currProjectData.projectName}{" "}
          {isProjectCreator && <EditModalBasicInfo />}
        </Text>
        <Text sx={{ fontSize: ["30px"] }}>
          {currProjectData.genresInterested.join(" | ")}
        </Text>
        <Text
          sx={{
            borderLeft: "3px solid #FFAF7A",
            p: ["5px"],
            fontSize: ["20px"],
          }}
        >
          {currProjectData.logline}
        </Text>
        {!isProjectCreator && (
          <Button
            sx={{
              borderRadius: ["50px"],
              w: ["250px"],
              bgColor: "#FFAF7A",
              fontSize: ["28px"],
              color: "white",
            }}
            onClick={onConnect}
          >
            Collaborate
          </Button>
        )}
        <Stack sx={{ my: "20px" }}>
          <Text
            sx={{
              fontSize: { base: "3xl", md: "5xl" },
              fontWeight: ["800"],
              borderBottom: "4px solid #92C8C8",
            }}
          >
            Who we need
          </Text>
          {joinRolesNeeded(currProjectData.rolesNeeded)}
        </Stack>
      </Stack>
      <Box sx={{ mx: "auto" }}>
        {isProjectCreator ? (
          <Image
            src={
              currProjectData.coverPageURL
                ? currProjectData.coverPageURL
                : defaultProjectCoverPage
            }
            sx={{ borderRadius: ["16px"], minW: ["200px"], objectFit: "cover" }}
            onClick={onOpen}
          />
        ) : (
          <Image
            src={currProjectData.coverPageURL}
            sx={{ borderRadius: ["16px"] }}
          />
        )}
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Crop and Upload Image</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              sx={{ display: "flex", flexDir: "column", alignItems: "center" }}
            >
              <input type="file" onChange={handleFileChange} />
              {img && (
                <AvatarEditor
                  ref={editorRef}
                  image={img}
                  style={{ width: "250px", height: "250px" }}
                  width={1000}
                  height={1000}
                  border={50}
                />
              )}
            </ModalBody>
            {img && (
              <Center sx={{ my: ["10px"] }}>
                <Button
                  sx={{
                    bgColor: "#FFAF7A",
                    w: ["100px"],
                    borderRadius: ["50px"],
                  }}
                  onClick={handleUpload}
                >
                  Upload
                </Button>
              </Center>
            )}
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
};

export default ProjectBio;

import React from 'react'
import { Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrProfileAction } from '../store/slices/currProfile';
import DetailedProfileSection from '../components/ProfileContent/DetailedProfileSection';
import DetailedProfileInfoSection from '../components/Projects/DetailedProfileInfoSection';
import { useProfile } from '../hooks/profile/useProfile';
import LoadingComponent from '../components/Utils/LoadingComponent';
import ErrorComponent from '../components/Utils/ErrorComponent';



const PersonalProfile = () => {
  const { profileData, isLoading, error } = useProfile();

  return (
    <>
      {isLoading && <LoadingComponent />}
      {error && (
        <ErrorComponent
          title={"Error"}
          message={"Could not load the profile page"}
        />
      )}
      {profileData && (
        <Flex
          sx={{
            flexDir: "column",
            gap: "5",
            p: "10",
            px: ["15px", "15px", "15px", "80px"],
          }}
        >
          <DetailedProfileSection />
          <DetailedProfileInfoSection />
        </Flex>
      )}
    </>
  );
};

export default PersonalProfile;

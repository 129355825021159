import { Button, Box, ButtonGroup, Flex, Input } from "@chakra-ui/react";
import { debounce } from "lodash";
import React from "react";
import { setRole, setUserDefinedRole } from "../../store/slices/roles";
import { useDispatch, useSelector } from "react-redux";

export const RoleToggle: React.FC = () => {
  const dispatch = useDispatch();
  const debouncedDispatch = debounce(
    (value) => dispatch(setUserDefinedRole(value)),
    100
  );
  const rolesNeeded = useSelector((state: any) => state.roles.roles);
  let otherIsActive = rolesNeeded.find(
    (role: any) => role.label === "Other"
  )?.active;

  const Role: React.FC<{ label: string }> = ({ label }) => {
    const isActiveRole = (label: string) => {
      const role = rolesNeeded.find((role: any) => role.label === label);
      return role ? role.active : false;
    };
    return (
      <Button
        variant={isActiveRole(label) ? "outline" : "ghost"}
        onClick={() => {
          dispatch(setRole(label));
        }}
        sx={{ color: "brand2", borderRadius: ["50px"], minW: ["400px"] }}
      >
        {label}
      </Button>
    );
  };
  const handleOtherRoleChange = (e: any) => {
    debouncedDispatch(e.target.value);
  };

  return (
    <Box
      padding="4"
      borderRadius="md"
      sx={{ bg: "transparent", color: "white" }}
    >
      <Box marginBottom="4" fontSize="lg" fontWeight="bold">
        Needed roles
      </Box>
      <ButtonGroup spacing="4" isAttached>
        <Flex
          sx={{ flexWrap: "wrap", gap: ["30px"], justifyContent: "center" }}
        >
          {rolesNeeded.map((role: any, idx: number) => {
            return role.label === "Other" && otherIsActive ? (
              <>
                <Role label={role.label} key={idx} />
                <Input
                  type={"text"}
                  bg={"white"}
                  textColor={"black"}
                  border={"1px solid"}
                  borderColor={"gray.300"}
                  borderRadius={"4px"}
                  transition={"border-color 0.3s ease"}
                  placeholder={"Input other role here!"}
                  onChange={handleOtherRoleChange}
                />
              </>
            ) : (
              <Role label={role.label} key={idx} />
            );
          })}
        </Flex>
      </ButtonGroup>
    </Box>
  );
};

export default RoleToggle;

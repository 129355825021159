import React from 'react'
import { Box, Flex, Hide, Image } from '@chakra-ui/react'
import { FaCheck } from "react-icons/fa";


function About() {
    return (
        <Flex sx={{ flexDir: ['column', 'column', 'row'], mx: 'auto', gap: ['10px'], marginTop: ['30px','45px','120px'] }}>
            <Flex sx={{
                color: '#E7E0DB',
                flexDir: 'column',
                gap: ['15px'],
            }}>
                <Box sx={{ color: '#E7E0DB', fontSize: ['30px', '30px', '40px'], fontWeight: ['500'] }}>Why use The Indie-stry?</Box>
                <Box sx={{ fontSize: ['20px', '20px', '30px'], fontWeight: ['300'] }}>We know how hard it can be finding the right set of people to bring your project to life.</Box>
                <Box>
                    <Flex sx={{ fontSize: ['14px', '16px', '20px'], alignItems: 'center', gap: ['5px'], fontWeight: ['100'] }} > <FaCheck />Get to know each artist and their work</Flex>
                    <Flex sx={{ fontSize: ['14px', '16px', '20px'], alignItems: 'center', gap: ['5px'], fontWeight: ['100'] }} > <FaCheck />Filter people and projects to find exactly what you’re looking for</Flex>
                    <Flex sx={{ fontSize: ['14px', '16px', '20px'], alignItems: 'center', gap: ['5px'], fontWeight: ['100'] }} > <FaCheck />Chat instantly to launch your project wherever you are</Flex>
                </Box>
            </Flex>
            <Hide below='sm'>
                <Image sx={{ maxW: ['450px'] }} objectFit={'cover'} src='BTS-PHOTO1.jpeg' alt='about-img' />
            </Hide>
        </Flex>

    )
}

export default About
import axios from "axios";
import { auth } from "../../utils/firebaseConfig";

export const useDeleteProject = () => {
    const deleteProject = async (id: string, reason: string) => {
        const token = await auth.currentUser?.getIdToken();
        const { data } = await axios.post(process.env.REACT_APP_BACKEND_URL + 'delete-project', {
            id: id,
            deletionReason: reason,
        }, {
            headers: {
                'Authorization': token,
                'Accept': 'application/json'
            }
        });
        return data;
    };
    return { deleteProject };
}
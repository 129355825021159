import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Hide, Input, InputGroup, InputRightElement, Select, Show } from '@chakra-ui/react';
import React from 'react'
import { states } from '../../utils/consts';
import { Roles, Interests } from '../../utils/consts';

interface PeopleFilterProps { 
    updateFilters: (filter: string, value: string) => void;
    filterResults: () => void;
}

const SearchBar: React.FC<PeopleFilterProps> = ({updateFilters, filterResults}) => { 
        return (
            <InputGroup _focus={{ botderColor: 'brand2' }}>
                <Input sx={{ borderRadius: ['50px'] }} placeholder='search by artists' onChange={(e) => updateFilters("nameSearch", e.target.value)}
                    onKeyDown={(e) => { if (e.key === 'Enter') { filterResults() } }}
                />
                <InputRightElement>
                    <SearchIcon />
                </InputRightElement>
            </InputGroup>
        );
    }
const PeopleFilter: React.FC<PeopleFilterProps> = ({ updateFilters, filterResults }) => {
    
    return (
      <Flex sx={{ justifyContent: 'space-between' }}>
            <Flex sx={{ maxW: ['900px'], gap: ['8px'], flexWrap: 'wrap' }}>
                <Box sx={{ w: ['100%', '100%', '180px'] }}>
                        <Select  placeholder='Location' _focus={{ borderColor: 'brand2' }} onChange={(e) => updateFilters("location", e.target.value)}>
                                {states.map((state, idx) => {
                                    return (<option value={state} key={idx}>{state}</option>);
                                })}
                        </Select>
                    </Box>
                    <Box sx={{ w: ['100%', '100%', '180px'] }}>
                        <Select placeholder='Role' _focus={{ borderColor: 'brand2' }} onChange={(e) => updateFilters("mainRole", e.target.value)}>
                            {Roles.map((role: string, idx: number) => {
                                return (<option value={role} key={idx}>{role}</option>);
                            })}
                        </Select>
                    </Box>
                    <Box sx={{ w: ['100%', '100%', '180px'] }}>
                        <Select placeholder='Interests' _focus={{ borderColor: 'brand2' }} onChange={(e) => updateFilters("interests", e.target.value)}>
                            {Interests.map((interest: string, idx: number) => {
                                return (<option value={interest} key={idx}>{interest} </option>);
                            })}
                        </Select>
                    </Box>
                    <Show below='md'>
                        <Box sx={{ w: ['100%', '100%', '180px'] }}>
                                <SearchBar updateFilters={updateFilters} filterResults={filterResults} />
                        </Box>
                    </Show>
                    <Button sx={{ minW: ['120px'], bgColor: 'brand2', borderRadius: ['50px'], mx: ['auto','auto','','' ] }} onClick={filterResults}>
                        Search
                    </Button>
            </Flex>
            
            <Hide below='md'>
                <Box sx={{ w: ['180px', '180px', '220px'] }}>
                    <SearchBar updateFilters={updateFilters} filterResults={filterResults} />
                </Box>
            </Hide>
        </Flex>
  )
}

export default PeopleFilter;
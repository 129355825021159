import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import EditModalProjectFiles from "../EditProject/Modal/EditModalProjectFiles";
import { convertFirebaseURLToFileName } from "../../utils/helpers";

const KnowTheStory: React.FC = () => {
  const currProjectData: ProjectData = useSelector(
    (state: RootState) => state.currProject.value
  );
  const [isProjectCreator, setIsProjectCreator] = useState<boolean>(false);

  const profileData: any = useSelector(
    (state: RootState) => state.profile.value
  );

  const handleDownload = (fileURL: string) => {
    window.open(fileURL, "_blank");
  };

  useEffect(() => {
    if (profileData?.id === currProjectData.createdBy) {
      setIsProjectCreator(true);
    }
  }, []);
  return (
    <Stack>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: ["5px"],
          maxW: ["100%", "100%", "100%", "65%"],
          fontSize: { base: "3xl", md: "5xl" },
          fontWeight: ["800"],
          borderBottom: "4px solid #92C8C8",
        }}
      >
        Project Files {isProjectCreator && <EditModalProjectFiles />}
      </Box>
      <Flex sx={{ flexDir: "row" }}>
        <Stack>
          <Text sx={{ fontSize: ["25px"], fontWeight: ["700"] }}>Scripts</Text>
          {currProjectData.projectFiles?.map((file, idx) => {
            return (
              <Text
                key={idx}
                cursor={"pointer"}
                onClick={() => handleDownload(file)}
              >
                {convertFirebaseURLToFileName(file)}
              </Text>
            );
          })}
          {(!currProjectData.projectFiles ||
            currProjectData.projectFiles.length === 0) && (
            <Box>No files to show currently</Box>
          )}
        </Stack>
      </Flex>
    </Stack>
  );
};

export default KnowTheStory;

import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import styles from "./applyforms.module.css";
import InputField from "../Forms/InputField";
import LargeInput from "../Forms/LargeInput";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Interests from "./Interests";
import FileUpload from "./FileUpload";
import Location from "./Location";
import VideoLink from "./VideoLink";
import Headshot from "./Headshot";
import RolesInfo from "./RolesInfo";

import {
  Heading,
  Stack,
  useToast,
  Text,
  Button,
  Box,
  Center,
} from "@chakra-ui/react";
import { uploadFileToStorage } from "../../utils/helpers";
import { useEditPortfolio } from "../../hooks/useEditPortfolio";
import { to } from "@react-spring/web";

// Define the structure of an item in the 'PreviousWork' section
type PreviousWorkItem = {
  value: string;
  link: string;
};

// Define the structure of a user's application data
type User = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  state: string;
  city: string;
  bio: string;
  previousWork: Array<PreviousWorkItem>;
  selectedRoles: string[];
  selectedInterests: string[];
  files: File[];
  mainRole: string;
};

const ApplyForms = () => {
  const navigation = useNavigate();
  const toast = useToast();

  // State to hold selected files for upload
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedVideos, setSelectedVideos] = useState<String[]>([]);
  const [headshotImg, setHeadshotImg] = useState<File>();
  const [mainRoleValidation, setMainRoleValidation] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { isYoutubeOrVimeoLink } = useEditPortfolio();

  // Function to handle file selection and update the state
  const handleFileChange = (e: any) => {
    const files = e;
    if (files) {
      setSelectedFiles(Array.from(files));
    }
  };

  const handleVideoChange = (e: any) => {
    const video = e;
    if (video) {
      setSelectedVideos(Array.from(video));
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<User>();

  /**
   * Function to handle form submission.
   *
   * @param {User} data - The user's application data.
   * @returns {Promise<void>} - A Promise that resolves when the submission is complete.
   */
  const onSubmit: SubmitHandler<User> = async (data) => {
    try {
      if (!data.mainRole) {
        toast({
          title: "Main Role not selected",
          description: `Please select a main role`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        return;
      }
      setIsSubmitting(true);

      const uploadPromises = selectedFiles.map((file) =>
        uploadFileToStorage(file, "images")
      );

      const files = await Promise.all(uploadPromises);

      const filteredVideos = selectedVideos.filter((link: any) => {
        if (!isYoutubeOrVimeoLink(link)) {
          if (typeof link == "string" && link.length > 0) {
            toast({
              title: "Video link incompatibility",
              description: `Sorry, it seems that a link you have given is not from youtube. Please check the link and try again!`,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            throw new Error(
              "Video link that was supplied is not a valid youtube link."
            );
          }
        }
        return link;
      });
      let headShotUrl = "";
      try {
        if (headshotImg) {
          headShotUrl = await uploadFileToStorage(headshotImg, "images");
        }
      } catch (error) {
        toast({
          title: "Error",
          description: `Failed to upload headshot`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        throw new Error("Failed to upload the headshot");
      }

      const user = {
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        state: data.state,
        city: data.city,
        bio: data.bio,
        selectedRoles: data.selectedRoles ? data.selectedRoles : [],
        selectedInterests: data.selectedInterests ? data.selectedInterests : [],
        isAccepted: false,
        isAdmin: false,
        fileUrls: files,
        videoLinks: filteredVideos,
        mainRole: data.mainRole,
        headShotUrl: headShotUrl,
      };
      const response = await axios
        .post(
          process.env.REACT_APP_BACKEND_URL + "users/create",
          JSON.stringify(user),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          navigation("/");
          toast({
            title: "Successfully Submitted Application",
            description: `Thank you ${user.firstName} for applying. You should be receiving an email shortly! Make sure to check your spam folder if you don't see it in your inbox.`,
            status: "info",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        })
        .catch((res) => {
          if (res.response.data.error === "Email already exists.") {
            // Display an error toast if the email already exists in the system
            toast({
              title: "Submission Failure",
              description: `Sorry, it seems that your email is already in our system. 
            If you have already applied, please wait for a response from our team!`,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          }
        });
    } catch (error) {
      console.error("An unexpected error occurred when applying: " + error);
    }
    setIsSubmitting(false);
  };

  return (
    <div className={styles.applyContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          className={styles.applyStack}
          spacing={10}
          sx={{ color: "#E7E0DB" }}
        >
          <Heading
            sx={{ color: "#E7E0DB" }}
            className={styles.applyHeading}
            size={"lg"}
            fontSize={"50px"}
          >
            Apply Today!
          </Heading>
          <InputField
            label={"First Name"}
            type={"text"}
            placeHolder={"John"}
            errors={errors.firstName}
            register={register("firstName", {
              required: "Please enter your first name",
            })}
          />
          <InputField
            label={"Last Name"}
            type={"text"}
            placeHolder={"Doe"}
            errors={errors.lastName}
            register={register("lastName", {
              required: "Please enter your last name",
            })}
          />
          <InputField
            label={"Email Address"}
            type={"email"}
            placeHolder={"person@email.com"}
            errors={errors.emailAddress}
            register={register("emailAddress", {
              required: "Please enter your email",
            })}
          />
          <RolesInfo setValue={setValue} />
          <Location register={register} errors={errors} />
          <LargeInput
            label={"Bio"}
            type={"text"}
            placeHolder={"A little about yourself..."}
            register={register("bio")}
          />
          <Interests setValue={setValue} />
          <Box
            sx={{ border: "1px solid white", borderRadius: "md", py: ["4px"] }}
          >
            <Center>
              <Text fontSize={{ base: "20px", lg: "25px" }} fontWeight={"bold"}>
                {" "}
                Upload Portfolio
              </Text>
            </Center>
            <FileUpload onChange={(files: File[]) => handleFileChange(files)} />
            <VideoLink
              onChange={(videoLinks: String[]) => handleVideoChange(videoLinks)}
            />
          </Box>
          <Headshot
            headshotImg={headshotImg}
            setHeadshotImg={setHeadshotImg}
            register={register}
          />
          <Button
            isLoading={isSubmitting}
            className={styles.submitButton}
            variant={"outline"}
            colorScheme="teal"
            borderRadius="['50px']"
            type="submit"
          >
            Submit Application
          </Button>
        </Stack>
      </form>
    </div>
  );
};

export default ApplyForms;

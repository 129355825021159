import React, { useState } from 'react'
import { Box, Button, ButtonGroup, Center, Flex, FormControl, FormErrorMessage, Input, InputGroup, InputLeftAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Stack, Textarea, useDisclosure, useToast } from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { MdModeEditOutline } from 'react-icons/md';
import { Roles, genres, states } from '../../../utils/consts';
import axios from 'axios';
import { auth } from '../../../utils/firebaseConfig';
import { useDispatch } from 'react-redux';
import { setCurrProjectAction } from '../../../store/slices/currProject';
import { resetRoles, setEditRole, setRole, setUserDefinedRole } from '../../../store/slices/roles';
import { debounce, rest } from 'lodash';
interface Genre {
    label: string;
}
interface RoleProps {
    label: string;
}
const EditModalBasicInfo: React.FC = () => {
    const dispatch = useDispatch();
    const currProjectData: ProjectData = useSelector((state: RootState) => state.currProject.value);
    const currActiveRoles = useSelector((state: RootState) => state.roles.roles);
    let otherIsActive = currActiveRoles.find((role: any) => role.label === 'Other')?.active;
    const debouncedDispatch = debounce((value) => dispatch(setUserDefinedRole(value)), 100);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [currGenres, setCurrentGenres] = useState(currProjectData.genresInterested);
    //const [currActiveRoles, setCurrentActiveRoles] = useState(currProjectData.rolesNeeded);
    const toast = useToast();
    const Genre: React.FC<Genre> = ({ label }) => {
        return (
            <Button
                variant={currGenres.includes(label) ? "outline" : "ghost"}
                onClick={() => {
                    if (currGenres.includes(label)) {
                        setCurrentGenres(currGenres.filter((genre) => genre !== label));
                    } else {
                        setCurrentGenres([label, ...currGenres])
                    }
                }}
                sx={{ color: 'brand2', borderRadius: ['50px'], minW: ['100px', '300px'] }}
            >
                {label}
            </Button>
        );
    };

    const Role: React.FC<{ label: string }> = ({ label }) => {
        const isActiveRole = (label: string) => {
            const role = currActiveRoles.find((role: any) => role.label === label);
            return role ? role.active : false;
          };
        return (
          <Button
            variant={isActiveRole(label) ? 'outline' : 'ghost'}
            onClick={() => {
              dispatch(setRole(label))
            }}
            sx={{ color: 'brand2', borderRadius: ['50px'], minW: ['400px'] }}
          >
            {label}
          </Button>
        );
    };
    const onSubmit: SubmitHandler<any> = async (data: any) => {
        const editBasicInfo = { ...data, rolesNeeded: currActiveRoles, genresInterested: currGenres, id: currProjectData.id };
        const token = await auth.currentUser?.getIdToken();
        try {
            await axios.post(process.env.REACT_APP_BACKEND_URL + "edit-basic-info", JSON.stringify(editBasicInfo), {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });
            toast({
                title: 'Success',
                description: 'Your project has been updated',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
            dispatch(setCurrProjectAction({ ...currProjectData, ...editBasicInfo }));
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Your project failed to update',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        }
        dispatch(resetRoles());
        onClose();
    }
    const handleOtherRoleChange = (e: any) => {
        debouncedDispatch(e.target.value);
      };
      
    return (
        <>
            <Box sx={{ fontSize: { base: "3xl", md: "5xl" } }}>
                <MdModeEditOutline cursor={'pointer'} onClick={onOpen} />
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent sx={{ minW: ['70vw'], mx: ['15px', '0px'] }}>
                    <ModalHeader>Edit Basic Info</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Stack>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormControl isInvalid={Boolean(errors.projectName)} >
                                    <InputGroup sx={{ my: ['5px'], flexDir: ['column','column', 'row'] }} >
                                        <InputLeftAddon sx={{ bgColor: 'transparent', border: '0', w: ['180px'] }} children='Name of Project' />
                                        <Input
                                            defaultValue={currProjectData.projectName}
                                            {...register('projectName', {
                                                required: 'Project name is req'
                                            })} />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {errors.projectName && errors.projectName.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={Boolean(errors.logline)} >
                                    <InputGroup sx={{flexDir: ['column','column', 'row']}} >
                                        <InputLeftAddon sx={{ bgColor: 'transparent', border: '0', w: ['180px'] }} children='Log Line' />
                                        <Textarea
                                            defaultValue={currProjectData.logline}
                                            {...register('logline', {
                                                required: 'Logline is required'
                                            })}
                                            placeholder='Type in the logline for your project here' />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {errors.logline && errors.logline.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>
                                <Box sx={{ px: ['16px'], my: ['20px'], fontSize: ['20px'], fontWeight: ['600'] }}>Roles</Box>

                                <ButtonGroup spacing="4" isAttached>
                                    <Flex sx={{ flexWrap: "wrap", gap: ["20px"], justifyContent: "center" }}>
                                        {currActiveRoles.map((role: any, idx: number) => {
                                            return role.label === "Other" && otherIsActive ? (
                                                <>
                                                    <Role label={role.label} key={idx} />
                                                    <Input
                                                        type={'text'}
                                                        bg={'white'}
                                                        textColor={'black'}
                                                        border={'1px solid'}
                                                        borderColor={'gray.300'}
                                                        borderRadius={'4px'}
                                                        transition={'border-color 0.3s ease'}
                                                        placeholder={role.userInput ? role.userInput : 'Input other role here!'}
                                                        defaultValue={role.userInput}
                                                        onChange={handleOtherRoleChange}
                                                    />
                                                </>
                                            ) : (
                                                <Role label={role.label} key={idx} />
                                            );
                                        })}
                                    </Flex>
                                </ButtonGroup>
                                <Box sx={{ px: ['16px'], my: ['20px'], fontSize: ['20px'], fontWeight: ['600'] }}>Location</Box>

                                <Flex sx={{ gap: ['20px'] }}>
                                    <FormControl isInvalid={Boolean(errors.state)} >
                                        <InputGroup >
                                            <Select placeholder='Select State'
                                                defaultValue={currProjectData.state}
                                                {...register('state', {
                                                    required: 'State is required'
                                                })} >
                                                {states.map((stateName: string, idx: number) => {
                                                    return (<option value={stateName} key={idx}>{stateName}</option>);
                                                })}
                                            </Select>
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {errors.state && errors.state.message?.toString()}
                                        </FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={Boolean(errors.city)} >
                                        <InputGroup >
                                            <Input
                                                defaultValue={currProjectData.city}
                                                placeholder='City'
                                                {...register('city', {
                                                    required: 'City is required'
                                                })} />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {errors.city && errors.city.message?.toString()}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Flex>

                                <Box sx={{ px: ['16px'], my: ['20px'], fontSize: ['20px'], fontWeight: ['600'] }}>Genres</Box>
                                <ButtonGroup spacing="4" isAttached>
                                    <Flex sx={{ flexWrap: 'wrap', gap: ['30px'], justifyContent: 'center' }}>
                                        {genres.map((genre: string, idx: number) => {
                                            return <Genre label={genre} key={idx} />
                                        })}
                                    </Flex>
                                </ButtonGroup>
                                <Center sx={{ my: ['10px'] }}>
                                    <Button sx={{ borderRadius: ['50px'] }} type='submit'>UPDATE</Button>
                                </Center>
                            </form>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default EditModalBasicInfo
import React from 'react';
import { Accordion, Box, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Grid, GridItem, Button, Stack, useToast, Flex, Text, Link } from '@chakra-ui/react';
import axios from 'axios';
import { auth } from '../../utils/firebaseConfig';
import { FaFileLines } from 'react-icons/fa6';
import { convertFirebaseURLToFileName } from '../../utils/helpers';
import { ExternalLinkIcon } from '@chakra-ui/icons';
const sxProps = {
    fieldHeader: {
        fontSize: ['sm', 'md', 'lg'],
        textDecoration: 'underline'
    },
    fieldValue: {
        fontSize: ['md', '2xl', '3xl']
    },
    parentContainer: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    } as React.CSSProperties
};
interface AccordianProps {
    lastName: string;
    previousWork: {
        link: string;
        value: string;
    }[];
    emailAddress?: string;
    selectedInterests: string[];
    isAccepted: boolean;
    bio: string;
    location: string;
    selectedRoles: string[];
    firstName: string;
    city: string,
    state: string,
    files: string[],
    videoLinks: string[],
    setShouldFetch: React.Dispatch<React.SetStateAction<boolean>>
}

const AdminAccordian: React.FC<AccordianProps> = (profile) => {
    const toast = useToast();

    const onAccepted = async (data: any) => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "users/approve/" + data.id, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });
            toast({
                title: "Successfully approved application.",
                description: `Thank you ${data.firstName} has been accepted.`,
                status: 'info',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        } catch (error) {
            console.error("Error submitting approved application: ", error);
        }
        profile.setShouldFetch(true);
    }


    const onDelete = async (data: any) => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const response = await axios.delete(process.env.REACT_APP_BACKEND_URL + "users/remove/" + data.id, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });
            toast({
                title: "Successfully removed application.",
                description: `Thank you ${data.firstName} has been removed.`,
                status: 'info',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        } catch (error) {
            console.error("Error submitting removing application: ", error);
        }
        profile.setShouldFetch(true);
    };

    return (
        <Accordion allowMultiple>
            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                            {profile.firstName + ' ' + profile.lastName}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} textAlign='left'>
                        <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(5, 1fr)' rowGap={5}>
                            <GridItem rowSpan={1} colSpan={3}>
                                <Box sx={sxProps.fieldHeader}>First Name</Box>
                                <Box sx={sxProps.fieldValue}>{profile.firstName}</Box>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={2}  >
                                <Box sx={sxProps.fieldHeader}>Last Name</Box>
                                <Box sx={sxProps.fieldValue}>{profile.lastName}</Box>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}  >
                                <Box sx={sxProps.fieldHeader}>Roles</Box>
                                <Box sx={sxProps.fieldValue}>{profile.selectedRoles?.join(" | ")}</Box>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={2}  >
                                <Box sx={sxProps.fieldHeader}>Interests</Box>
                                <Box sx={sxProps.fieldValue}>{profile.selectedInterests?.join(" | ")}</Box>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}  >
                                <Box sx={sxProps.fieldHeader}>Bio</Box>
                                <Box sx={sxProps.fieldValue}>{profile.bio}</Box>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={2}  >
                                <Box sx={sxProps.fieldHeader}>Location</Box>
                                <Box sx={sxProps.fieldValue}>{profile.state +', ' +profile.city} </Box>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={6}  >
                                <Box sx={sxProps.fieldHeader}>Portfolio </Box>
                                <Flex sx={{ flexDir: 'column', gap: ['20px'] }}>
                                    <Flex sx={{ flexDir: 'column', gap: '5px' }}>
                                        {profile.files?.map((file: string, idx: number) => {
                                            return <Flex key={idx} sx={{ alignItems: 'center' }}>
                                                <FaFileLines />
                                                <Text sx={{ mx: '2px' }} cursor={'pointer'} onClick={() => window.open(file, "_blank")}>{convertFirebaseURLToFileName(file)}</Text>
                                            </Flex>
                                        })}
                                    </Flex>
                                    <Flex sx={{ flexDir: 'column' }}>
                                        {profile?.videoLinks?.map((videoLink: string, idx: number) => {
                                            return (
                                                <Link key={idx} href={videoLink} isExternal >
                                                    Video - {idx + 1} <ExternalLinkIcon marginBottom='3px' mx='2px' />
                                                </Link>
                                            )
                                        })}
                                    </Flex>
                                </Flex>

                            </GridItem>


                            {/* {profile.previousWork?.map((work, idx) => {
                                return (
                                    <GridItem key={idx} rowSpan={1} colSpan={3}  >
                                        <Box >{work.value}</Box>
                                        <Box>{work.link}</Box>
                                    </GridItem>
                                );
                            })} */}
                        </Grid>
                        {
                            (!profile.isAccepted) ? (<Stack direction='row' spacing='4' mt={6} gap={6}>
                                <Button bg='#204B3D' color='#fff' _hover={{ bg: '#204B3D' }} onClick={() => onAccepted(profile)}>Accept!</Button>
                                <Button colorScheme='red' variant='outline' onClick={() => onDelete(profile)}>Reject</Button>
                            </Stack>) : <></>
                        }
                    </AccordionPanel>
                </h2>
            </AccordionItem>
        </Accordion>
    )
}

export default AdminAccordian
import React from "react";
import { MainContainer } from "@chatscope/chat-ui-kit-react";
import IndieMessagingSidebar from "../components/Messages/molecules/IndieMessagingSidebar";
import IndieMessagingContent from "../components/Messages/molecules/IndieMessagingContent";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useChat } from "../hooks/messages/useChat";

const chatContainerStyle = {
  height: "75vh",
  bg: "transparent !important",
  fontFamily: "Courier New",
};

const MainContainerStyle = {
  bg: "transparent !important",
  height: "100%",
};

const NewMessages: React.FC = () => {
  const showChatContent: boolean = useSelector(
    (state: RootState) => state.messaging.showChatContent
  );
  const isSmallScreen = window.innerWidth < 576;
  useChat();
  return (
    //determine out a good height so that the sidebar is actually scrollable
    <>
      {!isSmallScreen ? (
        <div style={chatContainerStyle}>
          <MainContainer style={MainContainerStyle} responsive={true}>
            <IndieMessagingSidebar />
            <IndieMessagingContent />
          </MainContainer>
        </div>
      ) : (
        <div style={chatContainerStyle}>
          {showChatContent ? (
            <IndieMessagingContent />
          ) : (
            <IndieMessagingSidebar />
          )}
        </div>
      )}
    </>
  );
};

export default NewMessages;

import { useState } from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import axios from "axios";
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import db from "../../utils/firebaseConfig";
import { setActivieChatroom } from "../../store/slices/messaging";
import * as uuid from "uuid";
import IMessage from "../../domain/IMessage";
import { setIsChatLoading } from "../../store/slices/loading";

export const useChat = () => {
  const dispatch = useDispatch();
  const loggedInProfile: any = useSelector(
    (state: RootState) => state.profile.value
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showChatContainer, setShowChatContainer] = useState<boolean>(false);
  const breakpoint = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });
  let isLessThanMd =
    breakpoint !== "md" && breakpoint !== "lg" && breakpoint !== "xl";

  const userId = loggedInProfile?.id;
  const userFirstName = loggedInProfile?.firstName;
  const userLastName = loggedInProfile?.lastName;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const sendMessage = async (text: string, chat: string) => {
    const newMessage:IMessage = {
      id: uuid.v4(),
      text,
      sender: userId,
      senderName: userFirstName + " " + userLastName,
      timestamp: new Date().toISOString(),
    };
    try {
      const docRef = doc(db, "MessageRooms", chat);
      await updateDoc(docRef, {
        messages: arrayUnion(newMessage)
      });
    } catch (e) {
      console.log(e);
    }
  };

  const convertTimestampToDate = (timestamp: string) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
  };

  const createMessageRoom = async (users: string[]) => {
    const userDocRef = collection(db, "users");
    const userSnapshot = await getDocs(userDocRef);
    const user = userSnapshot.docs.find((doc) => doc.id === userId)?.data();
    const currentUserRooms = user?.messageRooms || [];

    if (currentUserRooms.length > 0) {
      const existingRoomDataPromises = currentUserRooms.map(async (room: string) => {
        const roomDocRef = doc(db, "MessageRooms", room);
        const roomSnapshot = await getDoc(roomDocRef);
        const roomData = {...roomSnapshot.data(), id: roomSnapshot.id};
        return roomData;
      });

      const existingRoomData = await Promise.all(existingRoomDataPromises);
      const existingRoom = existingRoomData.find((room: any) => room?.users.includes(users[1]));

      if (existingRoom) {
        dispatch(setActivieChatroom(existingRoom.id));
        return; // Stop the function if the room exists
      }
    }

    // Only call createNewMessageRoom if no existing room was found
    await createNewMessageRoom(users);
  };

  const createNewMessageRoom = async (users: string[]) => {
    dispatch(setIsChatLoading(true));
    const currentUser = users[0];
    const userCurrentDocRef = doc(db, "users", currentUser);
    const userCurrentSnapshot = await getDoc(userCurrentDocRef);
    const currentUserData = userCurrentSnapshot.data();
    const otherUser = users[1];
    const otherUserDocRef = doc(db, "users", otherUser);
    const otherUserSnapshot = await getDoc(otherUserDocRef);
    const otherUserData = otherUserSnapshot.data();

    const newRoom = {
      users: [userCurrentDocRef.id, otherUserDocRef.id],
      messages: [],
      metadata: {
        userInfo: {
          [userCurrentDocRef.id]: {
            id: currentUser,
            firstName: currentUserData?.firstName,
            lastName: currentUserData?.lastName,
            headShotUrl: currentUserData?.headShotUrl,
          },
          [otherUserDocRef.id]: {
            id: otherUser,
            firstName: otherUserData?.firstName,
            lastName: otherUserData?.lastName,
            headShotUrl: otherUserData?.headShotUrl,
          },
        },
      },
    };

    try {
      const messageRoomsRef = collection(db, "MessageRooms");
      const newRoomDocRef = await addDoc(messageRoomsRef, newRoom);
      const newRoomId = newRoomDocRef.id;

      // Update the current user's messageRooms
      const userCurrentDocRef = doc(db, "users", currentUser);
      await updateDoc(userCurrentDocRef, {
        messageRooms: arrayUnion(newRoomId) // Use arrayUnion to add the new room ID to the array
      });

      // Update the other user's messageRooms
      const userOtherDocRef = doc(db, "users", otherUser);
      await updateDoc(userOtherDocRef, {
        messageRooms: arrayUnion(newRoomId) // Do the same for the other user
      });

      dispatch(setActivieChatroom(newRoomId));
    } catch (e) {
      console.log(e);
    }
  };

  return {
    createMessageRoom,
    convertTimestampToDate,
    isModalOpen,
    setIsModalOpen,
    searchTerm,
    setSearchTerm,
    showChatContainer,
    setShowChatContainer,
    isLessThanMd,
    userId,
    openModal,
    closeModal,
    sendMessage,
  }
};

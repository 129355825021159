import React from 'react'
import { genres } from '../../utils/consts'
import { Box, Button, ButtonGroup, Flex, Heading } from '@chakra-ui/react';
interface GenreProps {
    currGenres: any;
    setCurrentGenres: any;
}
interface Genre {
    label: string;
}
const Genres: React.FC<GenreProps> = ({ currGenres, setCurrentGenres }) => {
    const Genre: React.FC<Genre> = ({ label }) => {

        return (
            <Button
                variant={currGenres[label] ? "outline" : "ghost"}
                onClick={() => {
                    setCurrentGenres({ ...currGenres, [label]: !currGenres[label] })
                }}
                sx={{ color: 'brand2', borderRadius: ['50px'], minW: ['400px'] }}
            >
                {label}
            </Button>
        );
    };
    return (
        <Box>
            <Heading sx={{ marginBottom: ['20px'] }} fontSize="lg" fontWeight="bold">
                Genre
            </Heading>
            <ButtonGroup spacing="4" isAttached>
                <Flex sx={{ flexWrap: 'wrap', gap: ['30px'], justifyContent: 'center' }}>
                    {genres.map((genre: string, idx: number) => {
                        return <Genre label={genre} key={idx} />
                    })}
                </Flex>
            </ButtonGroup>
        </Box>
    )
}

export default Genres
import axios from "axios";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setCurrProfileAction } from "../../store/slices/currProfile";
import { auth } from "../../utils/firebaseConfig";
import { useQuery } from "@tanstack/react-query";

export const useProfile = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const getProfileData = async () => {
        const token = await auth.currentUser?.getIdToken();
        const uid = params.id;
        const { data } = await axios.get(process.env.REACT_APP_BACKEND_URL + `get-profile?id=${uid}`, {
            headers: {
                Authorization: token,
                Accept: 'application/json'
            }
        });
        dispatch(setCurrProfileAction({ ...data, id: uid }));
        return { ...data, id: uid };
    };
    
    const { data: profileData, isLoading, error } = useQuery<any>({
        queryKey: [`profile-data-${params.id}`],
        queryFn: getProfileData,
    });
    console.log('profileData', profileData);
    return { profileData, isLoading, error };

    

}

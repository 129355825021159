import { createSlice } from "@reduxjs/toolkit";

interface ILoadingState {
    isUserLoading: boolean;
    isChatLoading: boolean;
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        isUserLoading: false,
        isChatLoading: false,
    } as ILoadingState,
    reducers: {
        setIsUserLoading: (state, action) => {
            state.isUserLoading = action.payload;
        },
        setIsChatLoading: (state, action) => {
            state.isChatLoading = action.payload;
        },
    }
});
export const {
    setIsUserLoading,
    setIsChatLoading
} = loadingSlice.actions;

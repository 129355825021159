import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IChatroom from "../../domain/IChatroom";
interface IMessageState {
    availableChatrooms: string[];
    chatrooms: Record<string, IChatroom>;
    activeChatroom: string;
    searchTerm: string;
    safeToLoadFlag: boolean;
    showChatContent: boolean;
}
const initialState: IMessageState = {
    availableChatrooms: [],
    chatrooms: {},
    activeChatroom: '',
    searchTerm: '',
    safeToLoadFlag: false,
    showChatContent: false,
};

export const messagingSlice = createSlice({
    name: 'messaging',
    initialState: initialState,
    reducers: {
        updateChatrooms: (state, action: PayloadAction<{roomId:string, chatroom:IChatroom}>) => {
            const { roomId, chatroom } = action.payload;
            state.chatrooms[roomId] = chatroom;
        },
        setActivieChatroom: (state, action: PayloadAction<string>) => { state.activeChatroom = action.payload; },
        resetMessagingState: (state) => {
            state.chatrooms = {};
            state.activeChatroom = '';
            state.safeToLoadFlag = false;
            state.showChatContent = false;
        },
        setAvailableChatrooms: (state, action: PayloadAction<string[]>) => { state.availableChatrooms = action.payload; },
        setSearchTerm: (state, action: PayloadAction<string>) => { state.searchTerm = action.payload; },
        setSafeToLoadFlag: (state, action: PayloadAction<boolean>) => { state.safeToLoadFlag = action.payload; },
        setShowChatContent: (state, action: PayloadAction<boolean>) => { state.showChatContent = action.payload; },
    },
});

export const {
    updateChatrooms,
    setActivieChatroom,
    resetMessagingState,
    setSearchTerm,
    setSafeToLoadFlag,
    setShowChatContent,
    setAvailableChatrooms,
} = messagingSlice.actions;
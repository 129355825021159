import { Box, Button, Center, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrProjectAction } from '../../store/slices/currProject';
import { IoSearchSharp } from 'react-icons/io5';
import { defaultProjectCoverPage, challengeBadge } from '../../utils/consts';

interface ProjectCardProps {
    projectCard: any;
    size?: 'sm' | 'lg';
}

const ProjectCard: React.FC<ProjectCardProps> = ({ projectCard, size = 'lg' }) => {

    const hasChallenge = projectCard.challengeInfo ? true : false;
    if (projectCard.challengeInfo) (console.log('challenge', projectCard))
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = () => {
        navigate(`/project/${projectCard.id}`);
        dispatch(setCurrProjectAction(projectCard));
    };

    const joinRolesNeeded = (roles: any) => {
        if (Array.isArray(roles) && roles.every((role) => typeof role === 'string')) {
            return roles.join(', ');
        }
        if (Array.isArray(roles) && roles.every((role) => typeof role === 'object')) {
            const activeRoles = roles.filter((role: any) => role.active);
            const roleNames = activeRoles.map((role: any) => {
                return role.label === 'Other' ? role.userInput : role.label;
            });
            const rolesString = roleNames.join(', ');
            return rolesString;
        }
    };

    return (
        <Box
            sx={{
                minW: size === 'sm' ? '150px' : '200px',
                w: size === 'sm' ? '150px' : '250px',
                maxh: size === 'sm' ? '240px' : '600px',
                cursor: 'pointer',
                fontFamily: 'Avenir',
            }}
            onClick={handleClick}
        >
            <Box
                sx={{
                    borderRadius: size === 'sm' ? '15px' : '15px 15px 0px 0px',
                    minHeight: size === 'sm' ? '150px' : '260px',
                    objectFit: 'cover',
                    backgroundImage: `url(${projectCard.coverPageURL || defaultProjectCoverPage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                }}
            >
                {size === 'lg' && (
                    <>
                        <Text sx={{ color: 'black', position: 'absolute', bottom: '10px', left: '10px' }}>
                            {projectCard.city}
                        </Text>
                        {hasChallenge && (
                            <Box
                                position="absolute"
                                top="10px"
                                right="10px"
                                width="50px"
                                height="50px"
                                backgroundImage={`url(${challengeBadge})`}
                                backgroundSize="cover"
                                backgroundPosition="center"
                            />
                        )}
                    </>
                )}
            </Box>
            <Stack
                sx={{
                    bg: 'black',
                    gap: size === 'sm' ? '0px' : '2px',
                    borderRadius: size === 'sm' ? '0px 0px 15px 15px' : '0px 0px 15px 15px',
                    boxShadow: '0 5px 2px -2px rgba(255, 175, 122, 0.50)',
                    px: size === 'sm' ? '10px' : '5px',
                    py: size === 'sm' ? '10px' : undefined,
                    maxHeight: size === 'sm' ? '60px' : '',
                    minHeight: size === 'sm' ? '60px' : '',
                }}
            >

                <Text
                    sx={{
                        fontSize: size === 'sm' ? '12px' : '15px',
                        textTransform: 'uppercase',
                        fontWeight: size === 'sm' ? '400' : '800',
                        textAlign: size === 'sm' ? 'center' : undefined,
                    }}
                >
                    {size === 'sm' && projectCard.projectName.length > 40 ? `${projectCard.projectName.substring(0, 40)}...` : projectCard.projectName}
                </Text>


                {size === 'lg' && (
                    <>
                        <Text sx={{ fontSize: '12px', display: 'flex', gap: '3px', alignItems: 'center' }}>
                            <IoSearchSharp /> {joinRolesNeeded(projectCard?.rolesNeeded)}
                        </Text>
                        <Center>
                            <Button
                                sx={{
                                    borderRadius: '50px',
                                    bg: 'rgba(255, 175, 122, 0.50)',
                                    color: 'brand2',
                                    fontSize: '25px',
                                    mb: '8px',
                                }}
                            >
                                See More
                            </Button>
                        </Center>
                    </>
                )}
            </Stack>
        </Box>
    );
};

export default ProjectCard;
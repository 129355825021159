import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import { setProfileInfoAction } from "../store/slices/profile";
import { useEffect, useState } from "react";
import db  from "../utils/firebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";
import { setSafeToLoadFlag, setAvailableChatrooms } from "../store/slices/messaging";
import { Unsubscribe } from "firebase/auth";
import { setIsUserLoading } from "../store/slices/loading";

const useUserListener = () => {
    const dispatch = useDispatch();
    const user:any = useSelector((state: RootState) => state.profile.value);
    const [userUnsubscribeRef, setUnsubscribeRef] = useState<Unsubscribe | null>(null);

    useEffect(() => {
        if (user?.id) {
            const userDocRef = doc(db, 'users', user.id);
            try{
                const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
                    dispatch(setIsUserLoading(true));
                    if (snapshot.exists()) {
                        const userData = snapshot.data();
                        dispatch(setProfileInfoAction({id: snapshot.id, ...userData}));
                        dispatch(setSafeToLoadFlag(true));
                        if (userData.messageRooms){
                            dispatch(setAvailableChatrooms(userData.messageRooms));
                        } else {
                            dispatch(setAvailableChatrooms([]));
                        }
                    }
                    dispatch(setIsUserLoading(false));
                });
                setUnsubscribeRef(() => unsubscribe);
                return () => unsubscribe();
            } catch (error) {
                console.error('error:userSnapshot:', error);
            }
        }
    }, [user?.id]);

    const destroyUserListener = () => {
        if (userUnsubscribeRef) {
            userUnsubscribeRef();
            console.log('destroy:userListener');
        }
    };

    return { destroyUserListener };
};

export default useUserListener;
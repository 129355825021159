import axios from "axios";
import { auth } from "../../utils/firebaseConfig";
import iEvent from "../../domain/community/event";

const getCommunityEvents = async () => {
    try {
            const token = await auth.currentUser?.getIdToken();
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `community/events/all`, (
            {
                headers: {
                    'Accept': 'application/json',
                    Authorization: token
                }
            }));

            const result = response.data.map((val: any) => {
                return { id: val.id, event: val.event as iEvent };
            });
            return result;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const getCommunityGrants = async () => {
    try {
            const token = await auth.currentUser?.getIdToken();
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `community/grants/all`, (
            {
                headers: {
                    'Accept': 'application/json',
                    Authorization: token
                }
            }));

            return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

const deleteEvent = async (eventId: string) => {
    try {
            const token = await auth.currentUser?.getIdToken();
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `community/events/delete/${eventId}`, (
            {
                headers: {
                    'Accept': 'application/json',
                    Authorization: token
                }
            }));
            if (response.status !== 200) {
                throw new Error('Error deleting event');
            }
            return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

const newEvent = async (event: iEvent) => {
    try {
        const token = await auth.currentUser?.getIdToken();
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}community/events`,
            event,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token
                }
            }
        );

        if (response.status !== 200) {
            throw new Error('Error creating event');
        }

        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


export {
    getCommunityEvents,
    deleteEvent,
    newEvent,
    getCommunityGrants,
};
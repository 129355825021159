import React, { useEffect } from 'react';
import { Flex, Button, VStack, Box } from '@chakra-ui/react';
import { FieldErrors, FieldValues, UseFormGetValues } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

type KnowTheStoryProps = {
    errors: FieldErrors<FieldValues>;
    register: any;
    getValues: UseFormGetValues<any>;
    storyFilesToRemove: any;
    setStoryFilesToRemove: any;
};

const EditKnowTheStory: React.FC<KnowTheStoryProps> = ({ storyFilesToRemove, setStoryFilesToRemove, errors, register, getValues }) => {

    const handleRemove = (file: File) => {

        const data = getValues();
        setStoryFilesToRemove(data['knowTheStoryFiles'])
        setStoryFilesToRemove(storyFilesToRemove.filter((f: File) => f !== file));
    };

    const currProjectData: ProjectData = useSelector((state: RootState) => state.currProject.value);
    async function urlToFile(url: string): Promise<File> {
        const response = await fetch(url);
        const blob = await response.blob();
        const regex: RegExp = /%2F(.*?)-/;
        const match: RegExpExecArray | null = regex.exec(url);
        const fileName = (match && match[1]) ? match[1].replace(/%20/g, " ") : "NA";
        const file = new File([blob], fileName, { type: blob.type });
        return file;
    }

    useEffect(() => {
        Promise.all(currProjectData.storyFiles.map((url, index) => urlToFile(url)))
            .then(filesArray => {
                setStoryFilesToRemove(filesArray);
            })
            .catch(error => console.error('Error fetching files:', error));
    }, []);


    return (
        <Box>
            <Box sx={{fontSize: ['35px'], fontWeight: '800' }}>Know the story section</Box>
            <Flex direction="column" alignItems="center">
                <Button
                    as={'label'}
                    htmlFor={'editKnowTheStoryFiles'}
                    variant={'outline'}
                    colorScheme={'teal'}
                    leftIcon={<i className='fas fa-cloud-upload-alt'></i>}
                    w='100%'
                >
                    Upload Files
                </Button>
                <input
                    id={'editKnowTheStoryFiles'}
                    type={'file'}
                    accept={'.jpg, .jpeg, .png, .pdf, .doc. .docx, .txt'}
                    style={{ display: 'none' }}
                    {...register('knowTheStoryFiles')}
                    multiple="multiple"
                    onChange={(e) => {
                        register('knowTheStoryFiles').onChange(e);
                        const newFiles = e.target.files!;
                        setStoryFilesToRemove([...newFiles, ...storyFilesToRemove]);
                    }}
                />
                <VStack>
                    {storyFilesToRemove.map((file: File, idx: number) => (
                        <Box key={idx}>
                            {file.name}
                            <Button onClick={() => handleRemove(file)}>Remove</Button>
                        </Box>
                    ))}
                </VStack>
            </Flex>
        </Box>
    )
}

export default EditKnowTheStory
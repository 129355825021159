import { Box, Button, ButtonGroup, Flex } from '@chakra-ui/react';
import React from 'react'
import { Roles } from '../../utils/consts';
interface RoleToggleProps {
    currentActiveRoles: any;
    setCurrentActiveRoles: any;
}
interface RoleProps {
    label: string;
}
const EditRolesToggle: React.FC<RoleToggleProps> = ({ currentActiveRoles, setCurrentActiveRoles }) => {
    const Role: React.FC<RoleProps> = ({ label }) => {
        return (
            <Button
                variant={currentActiveRoles[label] ? "outline" : "ghost"}
                onClick={() => {
                    setCurrentActiveRoles({ ...currentActiveRoles, [label]: !currentActiveRoles[label] })
                }}
                sx={{ color: 'brand2', borderRadius: ['50px'], minW: ['400px'] }}
            >
                {label}
            </Button>
        );
    };
    return (
        <Box padding="4" borderRadius="md" sx={{ bg: 'transparent', color: 'white' }}>
            <Box marginBottom="4" fontSize="lg" fontWeight="bold">
                Needed roles
            </Box>
            <ButtonGroup spacing="4" isAttached>
                <Flex sx={{ flexWrap: 'wrap', gap: ['30px'], justifyContent: 'center' }}>
                    {Roles.map((role: string, idx: number) => {
                        return <Role label={role} key={idx} />
                    })}
                </Flex>
            </ButtonGroup>
        </Box>
    )
}

export default EditRolesToggle
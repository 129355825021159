import React from 'react'
import styles from "./copyright.module.css"

function Copyright() {
    return (
        <div className={styles.copyRightBg}>
            © 2023. All rights reserved.
        </div>
    )
}

export default Copyright
import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Button,
    useDisclosure,
    Center,
    Divider,
    Box,
    ChakraProvider,
    AbsoluteCenter,
} from '@chakra-ui/react'
import { COLOR1, COLOR2 } from '../../utils/ColorConstants';
import SignInForm from './SignInForm';
import { Link } from 'react-router-dom';

const signInButtonStyles = {
    'w': { lg: '150px', sm: "auto" },
    'color': COLOR1,
    'bgColor': COLOR2,
    '_hover': { bg: COLOR2 },
    borderRadius: '25px'
}

type SignInButtonProps = {
    closeDrawer?: () => void
}

const SignInButton: React.FC<SignInButtonProps> = ({closeDrawer}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <ChakraProvider>
                <Button
                    onClick={onOpen}
                    sx={signInButtonStyles}
                >
                    SIGN IN
                </Button>
                <Modal
                    isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent width={{ base: '90vw', md: '45vw' }}>
                        <ModalHeader>
                            <Center>
                                Start Collaborating Today!
                            </Center>
                        </ModalHeader>
                        <ModalBody >
                            {/* Sign In Form */}
                            <SignInForm />
                            {/* Divider */}
                            <Box position='relative' padding='8'>
                                <Divider />
                                <AbsoluteCenter bg='white' px='4'>
                                    OR
                                </AbsoluteCenter>
                            </Box>
                            <Center>
                                <Link to="/apply"><Button
                                    color={COLOR1}
                                    bgColor={COLOR2}
                                    borderRadius={'45px'}
                                    onClick={() => { onClose(); closeDrawer && closeDrawer()}}
                                    gap='8px'
                                    px={8}
                                    py={6}
                                    fontSize={{ base: '16px', md: '20px' }}
                                    _hover={{ bg: COLOR2 }}>
                                    Apply
                                </Button>
                                </Link>
                            </Center>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </ChakraProvider>
        </>

    )
}

export default SignInButton
import { createSlice } from "@reduxjs/toolkit";

export const projectSlice = createSlice({
    name: 'projectsList',
    initialState: {
        value: [],
    },
    reducers: {
        setProjectsAction: (state, action) => {
            state.value = action.payload
        }
    }
});
export const { setProjectsAction } = projectSlice.actions;
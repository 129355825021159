import React from 'react'
import { states } from '../../utils/consts'
import { Box, Flex, FormControl, FormErrorMessage, Heading, Input, InputGroup, Select } from '@chakra-ui/react'
import { FieldErrors, FieldValues } from 'react-hook-form';

interface LocationProps {
    errors: FieldErrors<FieldValues>,
    register: any;
}

const Location: React.FC<LocationProps> = ({ errors, register }) => {
    return (
        <Box sx={{ w: ['600px'] }}>
            <Heading sx={{ marginBottom: ['30px'] }} fontSize="lg" fontWeight="bold">
                Locations
            </Heading>
            <Flex sx={{ gap: ['20px'] }}>
                <FormControl isInvalid={Boolean(errors.state)} >
                    <InputGroup >
                        <Select placeholder='Select State'  {...register('state', {
                            required: 'State is required'
                        })} >
                            {states.map((stateName: string, idx: number) => {
                                return (<option value={stateName} key={idx}>{stateName}</option>);
                            })}
                        </Select>
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.state && errors.state.message?.toString()}
                    </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={Boolean(errors.city)} >
                    <InputGroup >
                        <Input
                            placeholder='City'
                            {...register('city', {
                                required: 'City is required'
                            })} />
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.city && errors.city.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
        </Box>
    )
}

export default Location
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Box, Flex, Stack, Tag, Text, VStack } from '@chakra-ui/react';
import { convertFirebaseURLToFileName } from '../../utils/helpers';
import { useParams } from 'react-router-dom';
import EditModalProfileScripts from '../ProfileContent/EditModalProfileScripts';
import { useQuery } from '@tanstack/react-query';
import ProjectCards from './ProjectCards';
import axios from 'axios';
import { auth } from '../../utils/firebaseConfig';
import { FaFileLines } from "react-icons/fa6";
interface TagComponentProps { 
    tag: string;
}
const TagComponent: React.FC<TagComponentProps> = ({tag}) => { 
    return <Tag>{tag}</Tag>
}
interface DetailedProfileSectionProps {
    currProfileData?: any;
}
const DetailedProfileInfoSection: React.FC<DetailedProfileSectionProps> = () => {
    const currProfileData = useSelector((state: RootState) => state.currProfile.value);
    const loggedInProfile: any = useSelector((state: RootState) => state.profile.value);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const params = useParams();
    const [shouldFetchProjects, setShouldFetchProjects] = useState(false);

    const getProjects = async () => {
        const token = await auth.currentUser?.getIdToken();
        const projectIds = {
            projectIds: currProfileData.projects
        }
        return await axios.post(process.env.REACT_APP_BACKEND_URL + 'get-project', projectIds, {
            headers: {
                'Authorization': token,
                'Accept': 'application/json'
            }
        })
    }
    const { data: projectCardsData, isLoading, error } = useQuery<any>({
        queryKey: [`poeople-project-data-${currProfileData.id}`],
        queryFn: getProjects,
        enabled: shouldFetchProjects,
        refetchOnWindowFocus: true
    })
    const handleDownload = (fileURL: string) => {
        window.open(fileURL, "_blank");
    }

    function getEmbedUrl(url: string): string | undefined {
        if (url.includes('vimeo.com')) {
            // Vimeo URL
            const parts = url.split('/');

            const videoId = parts[parts.length - 1];

            if (videoId) {
                return `https://player.vimeo.com/video/${videoId}`;
            }
        } else if (url.includes('youtube.com') || url.includes('youtu.be')) {
            // YouTube URL
            let videoId = null;
            let urlObj = new URL(url);
            let pathname = urlObj.pathname;
            let searchParams = new URLSearchParams(urlObj.search);
             if (url.includes('youtube.com') && searchParams.has('v')) {
                videoId = searchParams.get('v');
            } 
            // Case 2: Shortened URL (e.g., https://youtu.be/VIDEO_ID)
            else if (url.includes('youtu.be')) {
                videoId = pathname.split('/')[1];
            }
            // Case 3: Embedded Video URL (e.g., https://www.youtube.com/embed/VIDEO_ID)
            else if (url.includes('youtube.com/embed/')) {
                videoId = pathname.split('/embed/')[1];
            }
            if (videoId) {
                return `https://www.youtube.com/embed/${videoId}`;
            }
        }

        return undefined;
    }


    useEffect(() => {
        if (currProfileData.projects?.length > 0) {
            setShouldFetchProjects(() => true);
        }
    }, [currProfileData, currProfileData.id]);

    useEffect(() => {
        setCanEdit(loggedInProfile.id === params.id);
    }, [params.id, loggedInProfile.id]);



    return (
        <Flex sx={{ flexDir: ['column', 'column', 'column','row'] }}>
            <Stack sx={{ gap: ['10px'], maxW: ['100%','100%','100%', '350px']}}>
                <Text sx={{ fontSize: ['25px'], fontWeight: ['800'] }}>{currProfileData.city + ', ' + currProfileData.state}</Text>
                <Text sx={{ borderLeft: '3px solid #92C8C8', px: ['4px'] }}>{currProfileData.bio}</Text>
                <VStack sx={{ alignItems: 'start', borderRight: '3px solid #92C8C8' }}>
                    <Box sx={{ fontWeight: ['800'], fontSize: ['25px'] }}>Skills</Box>
                    <Flex sx={{ alignItems: 'start', flexWrap: ['wrap'], gap: ['8px'] }}>
                        {currProfileData.selectedRoles?.map((role: string, idx: number) => {
                            return (
                            <TagComponent key={idx} tag={role} />
                            );
                        })}
                    </Flex>
                    <Box sx={{ fontWeight: ['800'], fontSize: ['25px'] }}>Interests</Box>
                    <Flex sx={{  flexWrap: ['wrap'], gap: ['8px'] }}>
                        {currProfileData.selectedInterests?.map((interest: string, idx: number) => {
                            return (
                                <TagComponent key={idx} tag={interest} />
                            );
                        })}
                    </Flex>
                </VStack>
            </Stack>
            
            <Flex sx={{ px: ['0px','0px','0px','40px'], flexDir: ['column'], justifyContent: 'start' }}>
                {projectCardsData?.data?.length > 0 && 
                <>
                    <Flex sx={{ alignItems: 'center', fontSize: ['30px'], fontWeight: ['800'], borderBottom: '3px solid #FFAF7A', my: ['20px'] }}>In Development</Flex>
                    <Flex sx={{ flexWrap: 'wrap', gap: ['60px'] }}>
                        {
                        projectCardsData?.data.map((pojectCardData: any, idx: number) => {
                            return <ProjectCards key={idx} projectCard={pojectCardData} />
                        })
                        }
                    </Flex>
                    </>
                }

                <Flex sx={{ alignItems: 'center', fontSize: ['30px'], fontWeight: ['800'], borderBottom: '3px solid #FFAF7A', my: ['20px'] }}>Portfolio {canEdit && <EditModalProfileScripts />}</Flex>
                {currProfileData.files?.map((file: string, idx: number) => {
                    return <Flex key={idx} sx={{ alignItems: 'center' }}>
                        <FaFileLines />
                        <Text sx={{ mx: '2px' }} cursor={'pointer'} onClick={() => handleDownload(file)}>{convertFirebaseURLToFileName(file)}</Text>
                    </Flex>
                })}

                {/* <Flex sx={{ alignItems: 'center', fontSize: ['30px'], fontWeight: ['800'], borderBottom: '3px solid #FFAF7A', my: ['20px'] }}>Videos </Flex> */}
                {currProfileData.videoLinks?.map((videoLink: string, idx: number) => {
                    return <Flex key={idx} sx={{ alignItems: 'center',  minW: ['100%','100%','100%','500px'], minH: ['100%','100%','100%','315px'] }}>
                        <Box sx={{ w:'100%',h:'100%', my:['4px','8px']}}>
                            <iframe
                                title='videoLink'
                                width={'100%'}
                                height={'100%'}
                                src={getEmbedUrl(videoLink)}
                                allowFullScreen
                            ></iframe>
                        </Box>

                    </Flex>
                })}
            </Flex>
        </Flex>
    )
}

export default DetailedProfileInfoSection
import React from 'react';
import { Button } from '@chakra-ui/react';
import { COLOR1, COLOR2 } from '../../utils/ColorConstants';

type ButtonProps = {
    label: string;
    onClick: () => void;
};

const IndieButtonStyle = {
    'w': { lg: '150px', sm: "auto" },
    'color': COLOR1,
    'bgColor': COLOR2,
    '_hover': { bg: COLOR2 },
    borderRadius: '25px'
}

const IndieButton: React.FC<ButtonProps> = ({ label, onClick }) => <Button
        sx={IndieButtonStyle}
        onClick={onClick}
    >
        {label}
    </Button>;

export default IndieButton;

import axios from "axios";
import { auth } from "../utils/firebaseConfig";

export const getProfileData = async () => {
    const token = await auth.currentUser?.getIdToken();
    const uid = auth.currentUser?.uid;

        return await axios.get(process.env.REACT_APP_BACKEND_URL + `get-profile?id=${uid}`, {
            headers: {
                Authorization: token,
                Accept: 'application/json'
            }
        });
    }
export const getPeopleData = async (endPoint='get-people-data') => {

    return await axios.get(process.env.REACT_APP_BACKEND_URL + endPoint, {
        headers: {
            Accept: 'application/json',
        }
    })
}

export const getProjectsData = async () => {
    const token = await auth.currentUser?.getIdToken();
    return await axios.get(process.env.REACT_APP_BACKEND_URL + 'get-all-projects', {
        headers: {
            Authorization: token,
            Accept: 'application/json',
        }
    })
}

export const getUserData = async (id: string) => {
    const token = await auth.currentUser?.getIdToken();

    return await axios.get(process.env.REACT_APP_BACKEND_URL + `get-profile?id=${id}`, {
        headers: {
            Authorization: token,
            Accept: 'application/json'
        }
    });
}
import { Box, Flex, FormControl, FormErrorMessage, Input, InputGroup, InputLeftAddon, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { FieldErrors, FieldValues, UseFormGetValues } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
type EditBasicInfoProps = {
    errors: FieldErrors<FieldValues>;
    register: any;
    getValues: UseFormGetValues<any>;
};
const EditBasicInfo: React.FC<EditBasicInfoProps> = ({ errors, register, getValues }) => {
    const currProjectData = useSelector((state: RootState) => state.currProject.value);
    return (<Flex sx={{ flexDir: 'row', my: ['40px'], gap: ['50px'] }}>
        <Box>
            <Text>Basic Info</Text>
            <Stack>
                <FormControl isInvalid={Boolean(errors.projectName)} >
                    <InputGroup >
                        <InputLeftAddon sx={{ bgColor: 'transparent', border: '0', w: ['180px'] }} children='Name of Project' />
                        <Input
                            defaultValue={currProjectData.projectName}
                            {...register('projectName', {
                                required: 'Project name is req'
                            })} />
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.projectName && errors.projectName.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.project_coordinator_name)}>
                    <InputGroup>
                        <InputLeftAddon sx={{ bgColor: 'transparent', border: '0', w: ['180px'] }} children='Project Coordinator' />
                        <Input
                            defaultValue='Mushtaq'
                            {...register('project_coordinator_name', {
                                required: 'Project coordinator name is required'
                            })}></Input>
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.project_coordinator_name && errors.project_coordinator_name.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.project_coordinator_email)}>
                    <InputGroup>
                        <InputLeftAddon sx={{ bgColor: 'transparent', border: '0', w: ['180px'] }} children='Coordinator Email'
                        />
                        <Input   {...register('project_coordinator_email', {
                            required: 'Project coordinator email is required',
                            pattern: {
                                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                message: 'Not a valid email format'
                            }
                        })}></Input>
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.project_coordinator_email && errors.project_coordinator_email.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
            </Stack>
        </Box>
    </Flex >);
}

export default EditBasicInfo;
import React, { useState } from "react";

import AsyncSelect from 'react-select/async';
import { getSuggestions } from '../../../api/getPeople';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { addUsersMessageRoom, createMessageRoom, getMessageRoom } from '../../../api/messageRoom';
import { useChat } from '../../../hooks/messages/useChat';
import { useSelector } from 'react-redux';
import IChatroom from '../../../domain/IChatroom';

interface Item {
  label: string;
  value: string;
}
interface NewMessage {
  onOpen: boolean;
  onClose: any;
  userId: string;
  roomId: string;
}

const NewMessageRoom: React.FC<NewMessage> = ({ onOpen, onClose, userId, roomId }) => {
    const { createMessageRoom } = useChat();
    const conversations: Record<string, IChatroom> = useSelector((state: any) => state.messaging.chatrooms);

    const users = async (inputValue: string) => {
        const nameOptions = await getSuggestions(inputValue);
        let existingChatsUsers: string[] = [];
        if(Object.entries(conversations).length > 0) {
            existingChatsUsers = Object.values(conversations).map((chat) => {
                return chat.users.filter((user) => user !== userId)[0];
            });
        }
        const suggestions = nameOptions.map((val: any) => {
            if (!existingChatsUsers.includes(val.value)) {
                return { value: val.value, label: val.label };
            }
        });
        return suggestions.filter((nameSuggestion: string) => nameSuggestion !== undefined);
    };

    const customStyles = {
        option: (provided: any, state: { isSelected: boolean }) => ({
            ...provided,
            backgroundColor: 'transparent',
            color: state.isSelected ? 'white' : 'black',
            padding: 20,
        }),
        input: (provided: any) => ({
            ...provided,
            fontWeight: '800',
            color: 'white' // Change this to the desired color for the input text
        }),
        control: (provided: any) => ({
            ...provided,
            backgroundColor: 'transparent', // Set your desired background color here
            color: '#fff'
        })
    };
    const handleUserSelect = (selectedOptions: any) => {
        createMessageRoom([userId, selectedOptions[0].value]);
        onClose();
    };
    const promiseOptions = (inputValue: string) =>
        new Promise<Item[]>((resolve) => {
            setTimeout(() => {
                const data = users(inputValue);
                resolve(data);
            }, 1000);
        });
    return (
        <>
            <Modal isOpen={onOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    {roomId === null ?
                        <ModalHeader>Create Chat Room</ModalHeader>
                        : <ModalHeader>Add User</ModalHeader>}

                    <ModalCloseButton />
                    <ModalBody>
                        <AsyncSelect
                            styles={customStyles}
                            isMulti
                            cacheOptions
                            defaultOptions
                            loadOptions={promiseOptions}
                            onChange={handleUserSelect} // Add this line
                        />
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={saveUsers}>
                            Save
                        </Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    )
};
export default NewMessageRoom;

import React, { useState } from 'react';
import { Flex, Button, VStack, Box } from '@chakra-ui/react';
import { FieldErrors, FieldValues, UseFormGetValues } from 'react-hook-form';

type KnowTheStoryProps = {
    errors: FieldErrors<FieldValues>;
    register: any;
    getValues: UseFormGetValues<any>;
};

const KnowTheStory: React.FC<KnowTheStoryProps> = ({ errors, register, getValues }) => {
    const [files, setFiles] = useState<File[]>([]);
    const handleRemove = (file: File) => {
        const data = getValues();
        setFiles(data['knowTheStoryFiles'])
        setFiles(files.filter((f) => f !== file));
    };
    return (
        <Box>
            <Box sx={{fontSize: ['35px'], fontWeight: '800' }}>Know the story section</Box>
            <Flex direction="column" alignItems="center">
                <Button
                    as={'label'}
                    htmlFor={'knowTheStoryFiles'}
                    variant={'outline'}
                    colorScheme={'teal'}
                    leftIcon={<i className='fas fa-cloud-upload-alt'></i>}
                    w='100%'
                >
                    Upload Files
                </Button>
                <input
                    id={'knowTheStoryFiles'}
                    type={'file'}
                    accept={'.jpg, .jpeg, .png, .pdf, .doc. .docx, .txt'}
                    style={{ display: 'none' }}
                    {...register('knowTheStoryFiles')}
                    multiple="multiple"
                    onChange={(e) => {
                        register('knowTheStoryFiles').onChange(e);  // React Hook Form's onChange
                        const newFiles = e.target.files!;
                        setFiles([...newFiles, ...files]);
                    }}
                />
                <VStack>
                    {files.map((file, idx) => (
                        <Box key={idx}>
                            {file.name}
                            <Button onClick={() => handleRemove(file)}>Remove</Button>
                        </Box>
                    ))}
                </VStack>
            </Flex>
        </Box>
    )
}

export default KnowTheStory
import React from 'react'
import { states } from '../../utils/consts'
import { Box, Flex, FormControl, FormErrorMessage, Heading, Input, InputGroup, Select } from '@chakra-ui/react'
import { FieldErrors, FieldValues } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface EditLocationProps {
    errors: FieldErrors<FieldValues>,
    register: any;
}

const EditLocation: React.FC<EditLocationProps> = ({ errors, register }) => {
    const currProjectData = useSelector((state: RootState) => state.currProject.value);

    return (
        <Box sx={{ w: ['600px'] }}>
            <Heading sx={{ marginBottom: ['30px'] }} fontSize="lg" fontWeight="bold">
                Locations
            </Heading>
            <Flex sx={{ gap: ['20px'] }}>
                <FormControl isInvalid={Boolean(errors.state)} >
                    <InputGroup >
                        <Select placeholder='Select State'
                            defaultValue={currProjectData.state}
                            {...register('state', {
                                required: 'State is required'
                            })} >
                            {states.map((stateName: string, idx: number) => {
                                return (<option value={stateName} key={idx}>{stateName}</option>);
                            })}
                        </Select>
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.state && errors.state.message?.toString()}
                    </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={Boolean(errors.city)} >
                    <InputGroup >
                        <Input
                            defaultValue={currProjectData.city}
                            placeholder='City'
                            {...register('city', {
                                required: 'City is required'
                            })} />
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.city && errors.city.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
        </Box>
    )
}

export default EditLocation
import React, { useEffect, useState } from "react";
import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
  EllipsisButton,
} from "@chatscope/chat-ui-kit-react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import IMessage from "../../../domain/IMessage";
import { useChat } from "../../../hooks/messages/useChat";
import { setShowChatContent } from "../../../store/slices/messaging";
import IChatroom from "../../../domain/IChatroom";
import { useNavigate } from "react-router-dom";
import { defaultImgProfile } from "../../../utils/consts";

const IndieMessagingContent: React.FC = () => {
  const { sendMessage, convertTimestampToDate } = useChat();
  const navigate = useNavigate();
  const currentUser: any = useSelector(
    (state: RootState) => state.profile.value
  );
  const chatrooms: Record<string, IChatroom> = useSelector(
    (state: RootState) => state.messaging.chatrooms
  );
  const activeChatroom: string = useSelector(
    (state: RootState) => state.messaging.activeChatroom
  );
  const [currentChat, setCurrentChat] = useState<IChatroom>();
  const [showHeader, setShowHeader] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleBackClick = () => {
    dispatch(setShowChatContent(false));
  };

  useEffect(() => {
    const currentChat = Object.values(chatrooms).find((chat) => chat.id === activeChatroom);
    if (currentChat) {
      const chatCopy = { ...currentChat };
      const messagesCopy = chatCopy.messages
        .map((msg: IMessage) => ({ ...msg }))
        .sort((a, b) => a.timestamp.localeCompare(b.timestamp));
      chatCopy.messages = messagesCopy;
      setCurrentChat(chatCopy);
    }
  }, [chatrooms, activeChatroom]);

  useEffect(() => {
    setShowHeader(activeChatroom !== "");
  }, [activeChatroom]);

  const getOtherUserName = (conversation: IChatroom | undefined) => {
    if (!conversation) return "Indiestry";
    const otherUser = Object.values(conversation.metadata?.userInfo).find((user) => {
      return user.id !== currentUser.id;
    });
    return otherUser?.firstName + " " + otherUser?.lastName || "Indiestry";
  };

  const getChatAvatar = (conversation: IChatroom | undefined) => {
    if (!conversation) return defaultImgProfile;
    const otherUser = Object.values(conversation.metadata?.userInfo).find((user) => {
      return user.id !== currentUser.id;
    });
    return otherUser?.headShotUrl !== "" ? otherUser?.headShotUrl : defaultImgProfile;
  };

  const getOtherUserId = (conversation: IChatroom | undefined) => {
    if (!conversation) return "Indiestry";
    const otherUser = Object.values(conversation.metadata?.userInfo).find((user) => {
      return user.id !== currentUser.id;
    });
    return otherUser?.id;
  };

  const goToUserProfile = () => {
    const otherUserId = getOtherUserId(currentChat);
    navigate(`/user/${otherUserId}/personal-profile`);
  };

  return (
    <ChatContainer>
      {showHeader ?
        <ConversationHeader>
          <ConversationHeader.Back onClick={handleBackClick} />
          <Avatar src={getChatAvatar(currentChat)} onClick={goToUserProfile}/>
          <ConversationHeader.Content
            userName={getOtherUserName(currentChat)}
          />
          <ConversationHeader.Actions>
            {/* <EllipsisButton onClick={openInfoModal} /> will come back to this if we need some kind of details or something.*/}
          </ConversationHeader.Actions>
        </ConversationHeader>
        :
        <ConversationHeader>
        <ConversationHeader.Content
          userName="Choose a chat to start messaging"
        />
        </ConversationHeader>
      }
      <MessageList>
        {currentChat?.messages.map((message: IMessage) => (
          <Message
            key={message.id}
            model={{
              message: message.text,
              sentTime: message.timestamp,
              sender: message.sender,
              direction:
                message.sender === currentUser.id ? "outgoing" : "incoming",
              position: "single",
            }}
          >
            <Message.Footer
              sentTime={convertTimestampToDate(message.timestamp)}
            />
          </Message>
        ))}
      </MessageList>
      <MessageInput
        style={{ background: "transparent" }}
        attachButton={false}
        placeholder="Type your message here"
        onSend={(html, content) => sendMessage(content, activeChatroom)}
      />
    </ChatContainer>
  );
};

export default IndieMessagingContent;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
    Card,
    CardBody,
    CardFooter,
    Button,
    Flex,
    Badge,
    Text,
    Stack,
    Box,
    Spinner,
    Modal,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    useDisclosure,
    ModalFooter,
    ModalHeader,
    Input
} from '@chakra-ui/react';
import iEvent from '../../domain/community/event';
import useCommunityEditor from '../../hooks/admin/useCommunityEditor';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

type FormattedDate = {
    dayOfTheWeek: string;
    dateSection: string;
    timeSection: string;
};

const EventsAdmin: React.FC = () => {
    const events = useSelector((state: RootState) => state.community.events);
    const [formattedDates, setFormatedDates] = useState<Record<string, FormattedDate>>({});
    const { deleteSelectedEvent, eventsLoading, createNewEvent } = useCommunityEditor();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [eventName, setEventName] = useState<string>("");
    const [eventURL, setEventURL] = useState<string>("");
    const [eventLocation, setEventLocation] = useState<string>("");
    const [eventOrganizer, setEventOrganizer] = useState<string>("");
    const [startDate, setStartDate] = useState<string>(new Date().toUTCString());
    const [eventIsValid, setEventIsValid] = useState<boolean>(false);

    useEffect(() => {
        if(events.length > 0) {
            const formattedDates: Record<string, FormattedDate> = events.reduce(
                (acc: Record<string, FormattedDate>, event: { event: iEvent, id:string} ) => {
                    const date = formatDate(event.event.eventDate)
                    acc[event.id] = date;
                    return acc;
            },{});
            setFormatedDates(formattedDates);
        }
    }, [events]);

    const formatDate = (dateString: string):FormattedDate => {
        const dateTimeObj = new Date(dateString);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayOfTheWeek = days[dateTimeObj.getDay()];
        const formattedDate = dateTimeObj.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
        const formattedTime = dateTimeObj.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
        return {
            dayOfTheWeek,
            dateSection: formattedDate,
            timeSection: formattedTime
        };;
    };

    const styles = {
        dateContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
    
    useEffect(() => {
        if(eventName 
            && startDate 
            && startDate !== "Invalid Date" 
            && (eventURL || eventLocation)
        ) {
            setEventIsValid(true);
        } else {
            setEventIsValid(false);
        }
    }, [eventName, startDate, eventURL, eventLocation]);

    const saveEvent = () => {
        const newEvent: iEvent = {
            name: eventName,
            eventDate: startDate,
            eventOrganizer: eventOrganizer,
            location: eventLocation,
            url: eventURL,
            createdAt: new Date().toISOString(),
        };
        createNewEvent(newEvent);
        clearForm();
    };

    const clearForm = () => {
        setEventName("");
        setEventURL("");
        setEventLocation("");
        setEventOrganizer("");
        setStartDate(new Date().toUTCString());
    };

    return (
        <div>
            <Button 
                marginBottom={"15px"}
                colorScheme='green'
                onClick={onOpen}
            >
                Create New Event
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalCloseButton onClick={clearForm}/>
                <ModalHeader>Create New Event</ModalHeader>
                <ModalBody>
                    <Stack spacing={3}>
                        <Input variant="flushed" placeholder='Event Name*' value={eventName} onChange={(event) => {setEventName(event.target.value)}}/>
                        <Input variant="flushed" placeholder='Event URL' value={eventURL} onChange={(event) => {setEventURL(event.target.value)}}/>
                        <Input variant="flushed" placeholder='Event Location' value={eventLocation} onChange={(event) => {setEventLocation(event.target.value)}}/>
                        <Input variant="flushed" placeholder='Event Organizer' value={eventOrganizer} onChange={(event) => {setEventOrganizer(event.target.value)}}/>
                        <Text>Event Date*</Text>
                        <DatePicker
                            wrapperClassName='date-picker'
                            selected={new Date(startDate)} 
                            onChange={(date:Date) => setStartDate(date.toISOString())}
                            showTimeSelect
                        />
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={() => {
                            onClose();
                            clearForm();
                        }}
                    >
                        Cancel
                    </Button>
                    {
                        eventIsValid ? 
                        <Button colorScheme='green' onClick={() => {
                                saveEvent();
                                onClose();
                                clearForm();
                            }}
                        >
                            Save Event
                        </Button> :
                        <Button disabled>Save Event</Button>
                    }
                </ModalFooter>
                </ModalContent>
            </Modal>
            {eventsLoading && <div><Spinner /></div>}
            {events.length > 0 ?
                <div>
                    {events.map((event) => {
                        return (
                            <Card
                                key={event.id}
                                direction={{ base: 'column'}}
                                overflow='hidden'
                                variant='outline'
                                backgroundColor={'transparent'}
                                textColor={'white'}
                                width={{ base: '100%', md: '100%', lg: '100%' }}
                                marginBottom={{ base: '10px', md: '10px', lg: '10px' }}
                            >
                                    <CardBody>
                                        <Stack>
                                            {formattedDates[event.id] && (
                                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Badge variant='subtle' colorScheme='green'>
                                                        <div>
                                                            <div style={styles.dateContainer}>
                                                                <div>
                                                                    <strong>{formattedDates[event.id].dayOfTheWeek}, </strong>
                                                                </div>
                                                                <div>
                                                                    {formattedDates[event.id].dateSection} |
                                                                </div>
                                                                <div>
                                                                    {formattedDates[event.id].timeSection}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Badge>
                                                </Box>
                                            )}
                                            <Text
                                                style={{
                                                    fontSize: "17px"
                                                }}
                                            >
                                                <strong>{event.event.name}</strong>
                                            </Text>
                                            {event.event.eventOrganizer &&
                                                <Text>
                                                    {event.event.eventOrganizer}
                                                </Text>
                                            }
                                            {event.event.location &&
                                                <Text>Location: {event.event.location}</Text>
                                            }
                                        </Stack>
                                    </CardBody>
                                    <CardFooter padding={"0"}>
                                        <Flex justify="space-between" width="100%">
                                            {event.event.url && (
                                                <Button
                                                    flex="1"
                                                    style={{
                                                        borderRadius: "0px",
                                                    }}
                                                    colorScheme='green'
                                                    onClick={() => {
                                                        window.open(event.event.url, '_blank');
                                                    }}
                                                >
                                                    <p>Link</p>
                                                </Button>
                                            )}
                                            <Button
                                                flex="1"
                                                style={{
                                                    borderRadius: "0px",
                                                }}
                                                colorScheme='red'
                                                onClick={() => {
                                                    deleteSelectedEvent(event.id);
                                                }}
                                            >
                                                <p>Delete</p>
                                            </Button>
                                        </Flex>
                                    </CardFooter>
                            </Card>
                        );
                    })}
                </div> : <h2>Add some new events to see them here!</h2>
            }
        </div>
    );
};

export default EventsAdmin;
import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  useToast,
} from '@chakra-ui/react';

const MaxCharacterCount = 250;

/**
 * LargeInput Component
 *
 * The `LargeInput` component is used for bio input on the apply page, allowing users to input a maximum of 250 characters.
 *
 * @component
 * @param {object} props - Component properties.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.placeHolder - The placeholder text for the input field.
 * @param {object} props.register - The registration properties for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} props.helperText - Additional helper text.
 * @returns {JSX.Element} Large bio input component.
 */
const LargeInput = (props: any) => {
  const toast = useToast();
  const [inputValue, setInputValue] = useState('');
  const [characterCount, setCharacterCount] = useState(0);

  /**
   * Handles changes in the textarea input and updates character count.
   *
   * @param {Event} event - The input change event.
   */
  const handleTextareaChange = (event: any) => {
    const inputText = event.target.value;

    if (inputText.length <= MaxCharacterCount) {
      setCharacterCount(inputText.length);
      setInputValue(inputText);
      if (inputText.length === MaxCharacterCount) {
        toast({
          title: 'Reached maximum character count.',
          description: `You have reached the maximum character count for ${props.name}`,
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top',
        });
      }
      if (props.register && props.register.onChange) {
        props.register.onChange(event);
      }
    } else if (inputText.length <= inputValue.length) {
      setInputValue(inputText); // Allow removing characters
    }
  };

  return (

    < FormControl color='#E7E0DB'>
      <FormLabel
        fontSize={{ base: '20px', lg: '25px' }}
        fontWeight={'bold'}>
        {props.label}
      </FormLabel>
      <Textarea
        width={'100%'}
        backgroundColor={'white'}
        padding={'10px'}
        border={'1px solid'}
        borderColor={'gray.300'}
        borderRadius={'4px'}
        {...props.register}
        placeholder={props.placeHolder}
        onChange={handleTextareaChange}
        value={inputValue}
      />
      <FormHelperText
        fontSize={'12px'}
        color={'gray.500'}>
        {characterCount} / {MaxCharacterCount} characters
        {props.helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default LargeInput;

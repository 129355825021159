import { Box, FormControl, FormErrorMessage, Heading, InputGroup, Textarea } from '@chakra-ui/react';
import React from 'react'
import { FieldErrors, FieldValues } from 'react-hook-form'

interface LogLineProps {
    errors: FieldErrors<FieldValues>,
    register: any,
}

const Logline: React.FC<LogLineProps> = ({ errors, register }) => {
    return (
        <Box>
            <Heading sx={{ marginBottom: ['20px'] }} fontSize="lg" fontWeight="bold">
                Logline
            </Heading>
            <FormControl isInvalid={Boolean(errors.logline)} >
                <InputGroup >
                    <Textarea  {...register('logline', {
                        required: 'Logline is required'
                    })}
                        placeholder='Type in the logline for your project here' />
                </InputGroup>
                <FormErrorMessage>
                    {errors.logline && errors.logline.message?.toString()}
                </FormErrorMessage>
            </FormControl>
        </Box>
    )
}

export default Logline
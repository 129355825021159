import {
    Box,
    Button,
    Center,
    CloseButton,
    Input,
    List,
    ListItem,
    Text,
    VStack,
    useToast
} from '@chakra-ui/react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setProfileInfoAction } from '../../store/slices/profile';


interface VideoInputProps {
    /**
     * A callback function to handle file changes. It receives an array of selected files.
     */
    onChange: (videoLinks: String[]) => void;
    existinLinks?: String[];
}

/**
 * VideoLink Component
 *
 * The `VideoLink` component allows users to upload youtube video links for their application. Users can 
 * upload multiple video links.
 *
 * @component
 * @example
 * <FileUpload onChange={handleFileChange} />
 *
 * @param {Object} props - Component properties
 * @param {Function} props.onChange - A callback function to handle video changes. It receives an array 
 * of selected files.
 */
interface State {
    profile: { value: Object },
    _persist: Object
}
const VideoLink: React.FC<VideoInputProps> = ({ onChange, existinLinks }) => {
    let initialLinks: String[] = [];
    const profileData: any = useSelector((state: State) => state.profile.value);
    const dispatch = useDispatch();

    if (existinLinks) {
        initialLinks = existinLinks;
    }
    const [selectedVideos, setSelectedVideos] = useState<String[]>(initialLinks);

    const handleRemoveVideo = (indexToRemove: any) => {
        const updatedVideos = selectedVideos.filter((_, index) => index !== indexToRemove);
        setSelectedVideos(updatedVideos);
        onChange([...updatedVideos]);
    };

    const handleAddInput = () => {
        setSelectedVideos([...selectedVideos, '']);
    }

    const handleInputChange = (index: any, newValue: any) => {
        const updatedVideos = [...selectedVideos];
        updatedVideos[index] = newValue;
        setSelectedVideos(updatedVideos);
        onChange([...updatedVideos]);
    };

    useEffect(() => {
        dispatch(setProfileInfoAction(Object.assign({}, profileData, { 'videoLinks': selectedVideos })));

    }, [selectedVideos]);

    return (
        <Center>
            <Box sx={{ bgColor: 'transparent' }} p={4} borderRadius={'lg'} boxShadow={'md'} textAlign={'center'} bg={'white'}
                width={'100%'}
                max-width={'800px'} /* Set a maximum width to avoid overly wide content */
                margin={'0 auto'}
                padding={'20px'}>
                <VStack spacing={4} align="stretch">
                    {/* <Text fontWeight="bold">Selected Videos:</Text> */}
                    <List styleType="none">
                        {selectedVideos.map((link, index) => (
                            <ListItem
                                key={index}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                bg="white"
                                borderRadius="md"
                                border="1px solid #E0E0E0"
                                padding="4"
                                marginBottom={'10px'}
                                bgColor='transparent'
                            >
                                <Input
                                    value={`${link}`}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    placeholder="Enter video URL"
                                />
                                <CloseButton color="red" onClick={() => handleRemoveVideo(index)} />
                            </ListItem>
                        ))}
                    </List>
                    <Button variant={'outline'}
                        colorScheme={'teal'} sx={{ bgColor: 'transparent' }} onClick={handleAddInput}>Add Video Links</Button>
                </VStack>
            </Box>

        </Center>
    );
};

export default VideoLink;

import React from 'react';
import {
    Box,
    Card,
    CardBody,
    Flex,
    Text,
} from '@chakra-ui/react';


const Chat: React.FC = () => {
    const discordEmbedUrl = "https://discord.com/widget?id=1215173914989301790&theme=dark"


    return (
        <Card
            direction={{ base: 'column' }}
            overflow='hidden'
            variant='outline'
            backgroundColor={'transparent'}
            textColor={'white'}
            width={{ base: '100%', md: '100%', lg: '100%' }}
            marginBottom={{ base: '10px', md: '10px', lg: '10px' }}
        >
            <CardBody>
                <Flex flexDirection="column" alignItems="center">
                    <Text fontSize="xl" fontWeight="bold" mb={4}>
                        Join the Conversation 
                    </Text>
                    <Box
                        as="iframe"
                        src={discordEmbedUrl}
                        width="350px"
                        height="300px"
                        allowTransparency
                        frameBorder="0"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
                    />
                </Flex>
            </CardBody>
        </Card>
    );
};

export default Chat;
import { Box, Button, Center, Heading, useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import EditBasicInfo from '../components/EditProject/EditBasicInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import EditRolesToggle from '../components/EditProject/EditRolesToggle';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { auth, storage } from '../utils/firebaseConfig';
import axios from 'axios';
import { v4 } from 'uuid';
import EditLocation from '../components/EditProject/EditLoaction';
import EditLogline from '../components/EditProject/EditLogline';
import Genres from '../components/CreateProject/Genres';
import EditKnowTheStory from '../components/EditProject/EditKnowTheStory';
import EditGetInspired from '../components/EditProject/EditGetInspireSection';

const EditProject: React.FC = () => {
    const currProjectData: ProjectData = useSelector((state: RootState) => state.currProject.value);

    const { register, handleSubmit, formState: { errors }, getValues } = useForm();


    const toast = useToast();
    const initalRolesState: Record<string, boolean> = {};
    const initalGenresState: Record<string, boolean> = {};

    currProjectData.rolesNeeded?.forEach((key: string) => {
        initalRolesState[key] = true;
    });

    currProjectData.genresInterested?.forEach((key: string) => {
        initalGenresState[key] = true;
    });
    const [currActiveRoles, setCurrentActiveRoles] = useState(initalRolesState);
    const [currGenreRoles, setCurrentGenres] = useState(initalGenresState);
    const [storyFilesToRemove, setStoryFilesToRemove] = useState<File[]>([]);


    const onSubmit: SubmitHandler<any> = async (data: any) => {
        var files: String[] = [];
        var storyFiles: String[] = [];
        var inspireFiles: String[] = [];

        try {
            if (data['project_cover_art'].length > 0) {
                const imageRef = ref(storage, `projects/${data['project_cover_art'][0].name + '-' + v4()}`);
                await uploadBytes(imageRef, data['project_cover_art'][0])
                const url = await getDownloadURL(imageRef);
                files.push(url);
            }

            if (data['knowTheStoryFiles'].length > 0) {
                for (const knowStoryFile in data['knowTheStoryFiles']) {
                    const storyFile = data['knowTheStoryFiles'][knowStoryFile];
                    if (storyFile instanceof File) {
                        const imageRef = ref(storage, `projects/${storyFile.name + '-' + v4()}`);
                        await uploadBytes(imageRef, storyFile)
                        const url = await getDownloadURL(imageRef);
                        storyFiles.push(url);
                    }
                }
            }

            if (data['getInspiredFiles'].length > 0) {
                for (const inspFile in data['getInspiredFiles']) {
                    const getInspFile = data['getInspiredFiles'][inspFile];
                    if (getInspFile instanceof File) {
                        const imageRef = ref(storage, `projects/${getInspFile.name + '-' + v4()}`);
                        await uploadBytes(imageRef, getInspFile)
                        const url = await getDownloadURL(imageRef);
                        inspireFiles.push(url);
                    }
                }
            }


            const rolesNeeded: string[] = Object.keys(currActiveRoles).filter((role) => currActiveRoles[role] === true);
            const genresInterested: string[] = Object.keys(currGenreRoles).filter((role) => currGenreRoles[role] === true);

            const editProjectData: any = {
                id: currProjectData.id,
                projectName: data['projectName'],
                projectCoordinatorName: data['project_coordinator_name'],
                projectEmail: data['project_coordinator_email'],
                // coverPageURL: files,
                rolesNeeded: rolesNeeded,
                genresInterested: genresInterested,
                state: data['state'],
                city: data['city'],
                logline: data['logline'],
                storyFiles: storyFiles,
                inspireFiles: inspireFiles
            };
            if (files.length > 0) {
                editProjectData['coverPageURL'] = files
            }

            const token = await auth.currentUser?.getIdToken();

            await axios.post(process.env.REACT_APP_BACKEND_URL + "edit-project", JSON.stringify(editProjectData), {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            },);
            toast({
                title: 'Success',
                description: 'Your project has been created successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        } catch (error) {
            toast({
                title: 'Project creation failed',
                description: `Sorry, it seems that the project creation failed for some reason`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        }

    }
    return (
        <Box sx={{ px: ['80px'] }}>
            <Center>
                <Heading sx={{ fontFamily: 'courier-new', fontStyle: 'bold' }}>
                    Edit Project
                </Heading>
            </Center>
            <form onSubmit={handleSubmit(onSubmit)}>
                <EditBasicInfo errors={errors} register={register} getValues={getValues} />
                <EditRolesToggle currentActiveRoles={currActiveRoles} setCurrentActiveRoles={setCurrentActiveRoles} />
                <EditLocation errors={errors} register={register} />
                <EditLogline errors={errors} register={register} />
                <Genres currGenres={currGenreRoles} setCurrentGenres={setCurrentGenres} />
                <EditKnowTheStory storyFilesToRemove={storyFilesToRemove} setStoryFilesToRemove={setStoryFilesToRemove} errors={errors} register={register} getValues={getValues} />
                <EditGetInspired errors={errors} register={register} getValues={getValues} />

                <Center>
                    <Button sx={{ bg: 'brand2', color: 'white', borderRadius: ['50px'], py: ['20px'], px: ['40px'], my: ['40px'] }}
                        type='submit'
                    >
                        SUBMIT
                    </Button>
                </Center>
            </form>
        </Box>
    )
}

export default EditProject;
import axios from "axios";
import { auth } from "../utils/firebaseConfig";

export const getSuggestions = async (inputValue: string) => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `get-people-suggestions?prefix=${inputValue}`, (
                {
                    headers: {
                        'Accept': 'application/json',
                        Authorization: token
                    }
                }));

            const result = response.data.map((val: any) => {
                return { value: val.id, label: val.name };
            });
            return result;
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

import { Box, Button, ButtonGroup, Center, Flex, FormControl, FormErrorMessage, Input, InputGroup, InputLeftAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Stack, Textarea, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdModeEditOutline } from 'react-icons/md'
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { states, genres, Roles as RolesList } from '../../utils/consts';
import axios from 'axios';
import { auth } from '../../utils/firebaseConfig';
import { useDispatch } from 'react-redux';
import { setCurrProfileAction } from '../../store/slices/currProfile';
interface GenreProp {
    label: string;
}

interface RoleProps {
    label: string;
}
const EditModalProfileBasicInfo: React.FC = () => {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const currProfile = useSelector((state: RootState) => state.currProfile.value);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currGenres, setCurrentGenres] = useState(currProfile.selectedInterests);
    const [currActiveRoles, setCurrentActiveRoles] = useState(currProfile.selectedRoles);
    const [currMainRole, setCurrentMainRole] = useState(currProfile.mainRole);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const toast = useToast();
    const dispatch = useDispatch();

    const Genre: React.FC<GenreProp> = ({ label }) => {
        return (
            <Button
                variant={currGenres.includes(label) ? "outline" : "ghost"}
                onClick={() => {
                    if (currGenres.includes(label)) {
                        setCurrentGenres(currGenres.filter((genre: string) => genre !== label));
                    } else {
                        setCurrentGenres([label, ...currGenres])
                    }
                }}
                sx={{ color: 'brand2', borderRadius: ['50px'], minW: ['100px', '300px'] }}
            >
                {label}
            </Button>
        );
    };

    const Role: React.FC<RoleProps> = ({ label }) => {
        return (
            <Button
                variant={currActiveRoles.includes(label) ? "outline" : "ghost"}
                onClick={() => {
                    if (currActiveRoles.includes(label)) {
                        setCurrentActiveRoles(currActiveRoles.filter((role: string) => role !== label));
                    } else {
                        setCurrentActiveRoles([label, ...currActiveRoles])
                    }
                }}
                sx={{ color: 'brand2', borderRadius: ['50px'], minW: ['100px', '300px'] }}
            >
                {label}
            </Button>
        );
    };

    const MainRole: React.FC<RoleProps> = ({ label }) => {
        return (
            <Button
                variant={currMainRole === label ? "outline" : "ghost"}
                onClick={() => {

                    setCurrentMainRole(label)
                }}
                sx={{ color: 'brand2', borderRadius: ['50px'], minW: ['100px', '300px'] }}
            >
                {label}
            </Button>
        );
    };
    const onSubmit: SubmitHandler<any> = async (data: any) => {
        if (currMainRole === '') {
            setError('mainRole', {
                type: 'manual',
                message: 'A main role should be selected'
            });
            return;
        }
        setIsUploading(true);
        const updatedProfileData = { ...data, mainRole: currMainRole, selectedInterests: currGenres, selectedRoles: currActiveRoles, id: currProfile.id };
        try {
            const token = await auth.currentUser?.getIdToken();

            await axios.post(process.env.REACT_APP_BACKEND_URL + "profile/edit-basic-info", JSON.stringify(updatedProfileData), {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });
            toast({
                title: 'Success',
                description: 'Your profile has been updated',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
            dispatch(setCurrProfileAction({ ...currProfile, ...updatedProfileData }));
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Your profile failed to update',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        }
        setIsUploading(false);
        onClose();

    }
    return (
        <>
            <Box sx={{ fontSize: { base: "3xl", md: "5xl" } }}>
                <MdModeEditOutline cursor={'pointer'} onClick={onOpen} />
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent sx={{ minW: '70vw',  }}>
                    <ModalHeader>Edit Basic Info</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Stack>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormControl isInvalid={Boolean(errors.firstName)} >
                                    <InputGroup sx={{ my: ['5px'] }} >
                                        <InputLeftAddon sx={{ bgColor: 'transparent', border: '0', w: ['180px'] }} children='First Name' />
                                        <Input
                                            defaultValue={currProfile.firstName}
                                            {...register('firstName', {
                                                required: 'First name is required'
                                            })} />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {errors.firstName && errors.firstName.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={Boolean(errors.lastName)} >
                                    <InputGroup >
                                        <InputLeftAddon sx={{ bgColor: 'transparent', border: '0', w: ['180px'] }} children='Last Name' />
                                        <Input
                                            defaultValue={currProfile.lastName}
                                            {...register('lastName', {
                                                required: 'Last name is required'
                                            })}
                                            placeholder='Type in the last name for your project here' />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {errors.lastName && errors.lastName.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={Boolean(errors.bio)} >
                                    <InputGroup sx={{ my: ['5px'] }} >
                                        <InputLeftAddon sx={{ bgColor: 'transparent', border: '0', w: ['180px'] }} children='Bio' />
                                        <Textarea
                                            defaultValue={currProfile.bio}
                                            {...register('bio', {
                                                required: 'Bio is required'
                                            })}
                                            placeholder='Type in the bio for your project here' />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {errors.bio && errors.bio.message?.toString()}
                                    </FormErrorMessage>
                                </FormControl>
                                <Box sx={{ px: ['16px'], my: ['20px'], fontSize: ['20px'], fontWeight: ['600'] }}> Main Role</Box>
                                <ButtonGroup spacing="4" isAttached>
                                    <Flex sx={{ flexWrap: 'wrap', gap: ['30px'], justifyContent: 'center' }}>
                                        {RolesList.map((role: string, idx: number) => {
                                            return <MainRole label={role} key={idx} />
                                        })}
                                    </Flex>
                                </ButtonGroup>

                                <Box sx={{ px: ['16px'], my: ['20px'], fontSize: ['20px'], fontWeight: ['600'] }}>Roles</Box>
                                <ButtonGroup spacing="4" isAttached>
                                    <Flex sx={{ flexWrap: 'wrap', gap: ['30px'], justifyContent: 'center' }}>
                                        {RolesList.map((role: string, idx: number) => {
                                            return <Role label={role} key={idx} />
                                        })}
                                    </Flex>
                                </ButtonGroup>
                                <Box sx={{ px: ['16px'], my: ['20px'], fontSize: ['20px'], fontWeight: ['600'] }}>Location</Box>

                                <Flex sx={{ gap: ['20px'] }}>
                                    <FormControl isInvalid={Boolean(errors.state)} >
                                        <InputGroup >
                                            <Select placeholder='Select State'
                                                defaultValue={currProfile.state}
                                                {...register('state', {
                                                    required: 'State is required'
                                                })} >
                                                {states.map((stateName: string, idx: number) => {
                                                    return (<option value={stateName} key={idx}>{stateName}</option>);
                                                })}
                                            </Select>
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {errors.state && errors.state.message?.toString()}
                                        </FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={Boolean(errors.city)} >
                                        <InputGroup >
                                            <Input
                                                defaultValue={currProfile.city}
                                                placeholder='City'
                                                {...register('city', {
                                                    required: 'City is required'
                                                })} />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {errors.city && errors.city.message?.toString()}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Flex>

                                <Box sx={{ px: ['16px'], my: ['20px'], fontSize: ['20px'], fontWeight: ['600'] }}>Genres</Box>
                                <ButtonGroup spacing="4" isAttached>
                                    <Flex sx={{ flexWrap: 'wrap', gap: ['30px'], justifyContent: 'center' }}>
                                        {genres.map((genre: string, idx: number) => {
                                            return <Genre label={genre} key={idx} />
                                        })}
                                    </Flex>
                                </ButtonGroup>
                                <Center sx={{ my: ['10px'] }}>
                                    <Button isLoading={isUploading} sx={{ borderRadius: ['50px'] }} type='submit'>UPDATE</Button>
                                </Center>
                            </form>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default EditModalProfileBasicInfo


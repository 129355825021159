import {
  Button,
  Center,
  Flex,
  Modal,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { IoClose } from "react-icons/io5";
interface HeadshotProps {
  headshotImg: any;
  setHeadshotImg: any;
  register: any;
}
const Headshot: React.FC<HeadshotProps> = ({
  headshotImg,
  setHeadshotImg,
  register,
}) => {
  const editorRef = useRef<AvatarEditor | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filename, setFilename] = useState<string | undefined>("");
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setFilename(file?.name);
    setHeadshotImg(file);
    onOpen();
  };

  const handleUpload = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob(async (blob: any) => {
        if (blob) {
          blob.name = filename;
          setHeadshotImg(blob);
          onClose();
        }
      });
    }
  };

  return (
    <>
      <Button
        as={"label"}
        htmlFor={"headshot"}
        variant={"outline"}
        colorScheme={"teal"}
        leftIcon={<i className="fas fa-cloud-upload-alt"></i>}
        w="100%"
        onClick={onOpen}
      >
        Upload Headshot
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crop and Upload Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            sx={{ display: "flex", flexDir: "column", alignItems: "center" }}
          >
            <input type="file" onChange={handleFileChange} />
            {headshotImg && (
              <AvatarEditor
                ref={editorRef}
                image={headshotImg}
                style={{ width: "250px", height: "250px" }}
                width={1000}
                height={1000}
                borderRadius={550}
                border={50}
              />
            )}
          </ModalBody>
          {headshotImg && (
            <Center sx={{ my: ["10px"] }}>
              <Button
                sx={{
                  bgColor: "#FFAF7A",
                  w: ["100px"],
                  borderRadius: ["50px"],
                }}
                onClick={handleUpload}
              >
                Confirm
              </Button>
            </Center>
          )}
        </ModalContent>
      </Modal>
      {headshotImg && (
        <Flex
          sx={{ alignItems: "center", justifyContent: "center", gap: ["50px"] }}
        >
          <Text> {filename}</Text>
          <IoClose
            color="#FFAF7A"
            onClick={() => {
              setHeadshotImg(null);
            }}
            cursor="pointer"
          />
        </Flex>
      )}
    </>
  );
};

export default Headshot;

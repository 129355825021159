import { Box, Button, Center, Flex,  Heading, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import ProjectCards from '../components/Projects/ProjectCards';
import { useNavigate } from 'react-router-dom';
import ProjectsFilter from '../components/Projects/ProjectsFilter';
import { useProjectsFilter } from '../hooks/useProjectsFilter';
import ErrorComponent from '../components/Utils/ErrorComponent';
import LoadingComponent from '../components/Utils/LoadingComponent';

const Projects: React.FC = () => {
    const navigate = useNavigate();

    const {projects, setProjects, filters, updateFilters, filterResults, data, isLoading, error} = useProjectsFilter();
    useEffect(() => {
        setProjects(data?.data)
    }, [data, setProjects]);

    return (
        <Box sx={{ mx: ['50px'] }}>
            <Box sx={{ mb: ['30px'] }}>
                <Heading sx={{ textAlign: 'center' }}>Projects</Heading>
                <Center>
                    <Text>Search our list of projects</Text>
                </Center>
                <Center sx={{my: ['5px']}}>
                    <Button onClick={() => { navigate('/create-project') }} sx={{ borderRadius: ['50px'] }}> Add Project </Button> 
                </Center>
            </Box>
              <Box sx={{ mx: ['auto', '0px'], fontSize: {base: "sm", md: "md", lg: "lg" }}}>
                <ProjectsFilter  updateFilters={updateFilters} filterResults={filterResults}/>
                {error ?<ErrorComponent title='Error' message='Something went wrong while getting profiles information.'/>: <></>
                }
                {isLoading ?
                    <Center><LoadingComponent /></Center>
                    :
                    <Flex sx={{ gap: ['40px','80px'], flexWrap: 'wrap', my: ['30px'] }}>
                       {data?.data.map((projectCard: any, idx: number) => {
                            return (
                                <ProjectCards projectCard={projectCard} key={idx} />
                            )
                        })
                        }
                    </Flex>
                }
            </Box>
        </Box>
    )
}

export default Projects

import { Box, Button, Center, Flex, Heading, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BasicInfo from '../components/CreateProject/BasicInfo'
import RoleToggle from '../components/CreateProject/RolesToggle'
import { SubmitHandler, useForm } from 'react-hook-form'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { auth, storage } from '../utils/firebaseConfig'
import { v4 } from 'uuid'
import axios from 'axios'
import { Roles, genres } from '../utils/consts';
import Location from '../components/CreateProject/Location'
import Logline from '../components/CreateProject/Logline'
import Genres from '../components/CreateProject/Genres'
import KnowTheStory from '../components/CreateProject/KnowTheStory'
import GetInspired from '../components/CreateProject/GetInspired'
import { useNavigate } from 'react-router-dom'
import { uploadFileToStorage } from '../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { resetRoles } from '../store/slices/roles'

type CreateProjectFormInput = {
    projectName: string,
    project_coordinator_name: string,
    project_coordinator_email: string,
    projectCoverArt: File[],
    state: string,
    city: string,
    logline: string,
    knowTheStoryFiles: File[],
    getInspiredFiles: File[]
}
const initalRolesState: Record<string, boolean> = {};
const initalGenresState: Record<string, boolean> = {};
genres.forEach(key => {
    initalGenresState[key] = false;
});
Roles.forEach(key => {
    initalRolesState[key] = false;
});

const CreateProject: React.FC = () => {
    const currActiveRoles: any =  useSelector((state: any) => state.roles.roles);
    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors }, getValues } = useForm<CreateProjectFormInput>();
    const toast = useToast();
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const uid = auth.currentUser?.uid;
    const navigate = useNavigate();
    const [coverPageImg, setCoverPageImg] = useState<File>();
    const onSubmit: SubmitHandler<CreateProjectFormInput> = async (data) => {
        var files: String[] = [];
        var storyFiles: String[] = [];
        var inspireFiles: String[] = [];

        setButtonLoading(true);
        try {
            if (coverPageImg) {
                const url = await uploadFileToStorage(coverPageImg, "projects");
                files.push(url);
            }

            if (data['knowTheStoryFiles'].length > 0) {
                for (const knowStoryFile in data['knowTheStoryFiles']) {
                    const storyFile = data['knowTheStoryFiles'][knowStoryFile];
                    if (storyFile instanceof File) {
                        const imageRef = ref(storage, `projects/${storyFile.name + v4()}`);
                        await uploadBytes(imageRef, storyFile)
                        const url = await getDownloadURL(imageRef);
                        storyFiles.push(url);
                    }

                }
            }

            if (data['getInspiredFiles'].length > 0) {
                for (const inspFile in data['getInspiredFiles']) {
                    const getInspFile = data['getInspiredFiles'][inspFile];
                    if (getInspFile instanceof File) {
                        const imageRef = ref(storage, `projects/${getInspFile.name + v4()}`);
                        await uploadBytes(imageRef, getInspFile)
                        const url = await getDownloadURL(imageRef);
                        inspireFiles.push(url);
                    }


                }
            }

            const genresInterested: string[] = Object.keys(currGenreRoles).filter((role) => currGenreRoles[role] === true);

            const createProjectData = {
                projectName: data['projectName'],
                projectCoordinatorName: data['project_coordinator_name'],
                projectEmail: data['project_coordinator_email'],
                coverPageURL: files[0],
                rolesNeeded: currActiveRoles,
                genresInterested: genresInterested,
                state: data['state'],
                city: data['city'],
                logline: data['logline'],
                projectFiles: storyFiles,
                inspireFiles: inspireFiles,
                createdBy: uid,
                people: [uid]
            };
            const token = await auth.currentUser?.getIdToken();

            await axios.post(process.env.REACT_APP_BACKEND_URL + "create-project", JSON.stringify(createProjectData), {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            },);
            toast({
                title: 'Success',
                description: 'Your project has been created successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
            navigate('/projects');

        } catch (error) {
            toast({
                title: 'Project creation failed',
                description: `Sorry, it seems that the project creation failed for some reason`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        }
        setButtonLoading(false);

    };
    const [currGenreRoles, setCurrentGenres] = useState(initalGenresState);
    
    useEffect(() => {
        dispatch(resetRoles())
    }, []);
    return (
        <Box sx={{ px: ['80px'] }}>
            <Center>
                <Heading sx={{ fontStyle: 'bold' }}>
                    Create Project
                </Heading>
            </Center>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flex sx={{ flexDir: 'column', gap: ['40px'] }}>
                    <BasicInfo coverPageImg={coverPageImg} setCoverPageImg={setCoverPageImg} errors={errors} register={register} getValues={getValues} />
                    <RoleToggle />
                    <Location errors={errors} register={register} />
                    <Logline errors={errors} register={register} />
                    <Genres currGenres={currGenreRoles} setCurrentGenres={setCurrentGenres} />
                    <KnowTheStory errors={errors} register={register} getValues={getValues} />
                    <GetInspired errors={errors} register={register} getValues={getValues} />
                    <Center>
                        <Button isLoading={buttonLoading} sx={{ bg: 'brand2', color: 'white', borderRadius: ['50px'], py: ['20px'], px: ['40px'], my: ['40px'] }} type='submit'>
                            SUBMIT
                        </Button>
                    </Center>
                </Flex>
            </form>
        </Box>
    )
}

export default CreateProject

import React from 'react';
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import Copyright from './components/Copyright/Copyright';
import './App.css';
import './main.scss';
import './globals.css'
import ProfileHomePage from './pages/ProfileHomePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ErrorPage from './components/error-page';
import Home from './pages/Home';
import Admin from './pages/Admin';
import Apply from './pages/Apply';
import ApplyAccept from './pages/ApplyAccept';
import PersonalProfile from './pages/PersonalProfile';
import People from './pages/People';
import CreateProject from './pages/CreateProject';
import Projects from './pages/Projects';
import DetailedProject from './components/Projects/DetailedProject';
import EditProject from './pages/EditProject';
import ProtectedRoute from './utils/ProtectedRoute';
import Community from './pages/Community';
import { Box, Flex } from '@chakra-ui/react';
import useMessagingSystem from './hooks/messages/useMessagingSystem';
import NewMessages from './pages/NewMessages';
import useUserListener from './hooks/useUserListener';
import PostHogPageviewTracker from './PostHogPageviewTracker';
import Unsubscribe from './pages/Unsubscribe';
import CreateChallengeProject from './pages/CreateChallengeProject';
import ChallengeDetails from './pages/community-pages/challenge-details';

export default function App() {
  console.log(process.env.REACT_APP_ENV);
  useUserListener();
  useMessagingSystem();
  return (
    <BrowserRouter>
      <Box className='pages' >
        <PostHogPageviewTracker />
        <Flex sx={{ flexDir: 'column', minH: '100vh', maxW: ['1440px'], mx: 'auto', px: ['12px'], color: 'white' }}>

          <div className='main-content'>
            <Navbar />
            <Routes>
              <Route path={"/Apply"} element={<Apply />} />
              <Route path={"/"} element={<Home />} />
              <Route path={"/admin"} element={<ProtectedRoute><Admin /></ProtectedRoute>} />
              {/* <Route path='user/:id' element={<ProtectedRoute><ProfileHomePage /></ProtectedRoute>} /> */}
              <Route path='user/:id/personal-profile' element={<ProtectedRoute><PersonalProfile /></ProtectedRoute>} />
              <Route path='/error' element={<ErrorPage />} />
              <Route path='/messages' element={<ProtectedRoute><NewMessages /></ProtectedRoute>} />
              <Route path='/account/accepted/:id' element={<ApplyAccept />} />
              <Route path='/people' element={<People />} />
              <Route path='create-project' element={<ProtectedRoute><CreateProject /></ProtectedRoute>} />
              <Route path='create-challenge-project' element={<ProtectedRoute><CreateChallengeProject /></ProtectedRoute>} />
              <Route path= '/community/challenges/:id' element={<ProtectedRoute><ChallengeDetails /></ProtectedRoute>} />
              <Route path='/projects' element={<Projects />} />
              <Route path='/project/:id' element={<ProtectedRoute><DetailedProject /></ProtectedRoute>} />
              <Route path='/project/:id/edit' element={<EditProject />} />
              <Route path='/community' element={<ProtectedRoute><Community /></ProtectedRoute>} />
              <Route path='/unsubscribe' element={<Unsubscribe />} />
            </Routes>
          </div>
          <Footer />
          <Copyright />
        </Flex>
      </Box>
    </BrowserRouter>
  )
}
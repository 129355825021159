import { Button, VStack, Flex, Modal, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure } from '@chakra-ui/react';
import React from 'react'
import { IoClose } from 'react-icons/io5';
import { convertFirebaseURLToFileName } from '../../utils/helpers';
interface EditPortfolioFilesProps { 
    register: any;
    files: File[];
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    currProfile: any;
    deleteFile: (fileURL: string) => void;
    isDeleteing: string;
    setIsDeleting: React.Dispatch<React.SetStateAction<string>>;
    handleRemove: (file: File) => void;
}

const EditPortfolioFiles: React.FC<EditPortfolioFilesProps> = ({register, files, setFiles, currProfile, deleteFile, isDeleteing, setIsDeleting, handleRemove }) => {
    const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
    } = useDisclosure();
    
  return (
      <>
                <Button
                  as={"label"}
                  htmlFor={"files"}
                  variant={"outline"}
                  colorScheme={"teal"}
                  leftIcon={<i className="fas fa-cloud-upload-alt"></i>}
                  w="100%"
                >
                  Upload Files
                </Button>
                <input
                  id={"files"}
                  type={"file"}
                  accept={".pdf, .txt, .doc, .docx"}
                  style={{ display: "none" }}
                  {...register("files")}
                  multiple
                  onChange={(e) => {
                    register("files").onChange(e); // React Hook Form's onChange
                    const newFiles = e.target.files!;
                    setFiles([...newFiles, ...files]);
                  }}
                />
                <VStack>
                  {files?.map((file: File, idx: number) => (
                    <Flex
                      key={idx}
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        minW: ["50%"],
                      }}
                    >
                      {file.name}
                      <IoClose
                        color="#FFAF7A"
                        onClick={() => handleRemove(file)}
                        cursor="pointer"
                      />
                    </Flex>
                  ))}
                </VStack>
                <VStack>
                  {currProfile.files?.map((fileURL: string, idx: number) => {
                    return (
                      <Flex
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          minW: ["50%"],
                        }}
                        key={idx}
                      >
                        {convertFirebaseURLToFileName(fileURL)}
                        <IoClose
                          color="#FFAF7A"
                          onClick={() => {
                            setIsDeleting(fileURL);
                            deleteOnOpen();
                          }}
                          cursor="pointer"
                        />
                        {fileURL === isDeleteing && (
                          <Modal
                            isOpen={deleteIsOpen}
                            onClose={deleteOnClose}
                            isCentered
                          >
                            <ModalContent
                              sx={{ minW: "50vw", fontFamily: "Avenir" }}
                            >
                              <ModalHeader>
                                Are you sure you want to remove the file{" "}
                                {convertFirebaseURLToFileName(fileURL)}?
                              </ModalHeader>
                              <ModalCloseButton />
                              <ModalBody
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: ["20px"],
                                }}
                              >
                                <Button
                                  sx={{ borderRadius: ["50px"] }}
                                  onClick={() => deleteFile(fileURL)}
                                >
                                  Yes
                                </Button>
                                <Button
                                  sx={{ borderRadius: ["50px"] }}
                                  onClick={deleteOnClose}
                                >
                                  No
                                </Button>
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                        )}
                      </Flex>
                    );
                  })}
                </VStack>
      </>
  )
}

export default EditPortfolioFiles
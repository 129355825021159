import { 
    Alert, 
    AlertDescription, 
    AlertIcon, 
    AlertTitle, 
    Box, 
    Center, 
    Flex, 
    Heading, 
    Spinner, 
    Text 
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import ProfileCard from '../NewHomeComponents/ProfileCard';
import { usePeopleFilter } from '../hooks/usePeopleFilter';
import PeopleFilter from '../components/People/PeopleFilter';
import LoadingComponent from '../components/Utils/LoadingComponent';
import ErrorComponent from '../components/Utils/ErrorComponent';

interface ProfileInfo {
    id: string;
    firstName: string;
    lastName: string;
    city: string;
    state: string;
    roles: string[];
    files: string[];
    interests: string[];
    location: string[];
}

const People = () => {
    
    const { profiles, setProfiles, updateFilters, filterResults, profileCardData, isLoading, error } = usePeopleFilter();

    useEffect(() => {
        setProfiles(profileCardData?.data)
    }, [profileCardData, setProfiles]);

    return (
        <Box>
            <Box sx={{mb: ['30px'] }}>
                <Heading sx={{ fontFamily: 'Courier New', textAlign: 'center' }}>People</Heading>
                <Center>
                    <Text>Search our Community of Artists</Text>
                </Center>
            </Box>
            <Box sx={{ mx: ['50px'], fontSize: {base: "sm", md: "md", lg: "lg" }}}>
                <PeopleFilter updateFilters={updateFilters} filterResults={filterResults}/>
                {error ?<ErrorComponent title='Error' message='Something went wrong while getting profiles information.'/>: <></>
                }
                {isLoading ?
                    <Center><LoadingComponent /></Center>
                    :
                    <Flex sx={{ gap: ['60px'], flexWrap: 'wrap', my: ['30px'] }}>
                        {profiles?.map((profileInfo: ProfileInfo, idx: number) => {
                            return (<ProfileCard profileInfo={profileInfo} key={idx} />)
                        })}
                    </Flex>
                }
            </Box>
        </Box>
    )
}

export default People
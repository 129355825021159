import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Box, Button, Text, VStack, Divider, Badge } from '@chakra-ui/react';
import ChallengeTimer from './challengeTimer';
import { useNavigate } from 'react-router-dom';
import { setChallengeProjectsId, setChallenges } from '../../store/slices/community';
import { getCommunityChallenges } from '../../services/community/challenges';

const Challenge: React.FC = () => {
  const challenges = useSelector((state: RootState) => state.community.challenges);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const newChallenges = await getCommunityChallenges();
        dispatch(setChallenges(newChallenges));
      } catch (error) {
        console.error('Error fetching challenges:', error);
      }
    };

    fetchChallenges();
  }, [dispatch]);

  const handleSubmitNewProject = (challengeId: string) => {
    dispatch(setChallengeProjectsId(challengeId));
    navigate('/create-challenge-project');
  };

  const handleSeeAllSubmissions = (id: any) => {
    navigate(`/community/challenges/${id}`);
  };

  const renderChallengeStatus = (challenge: any) => {
    const challengeStatus = challenge.challenge.status;
    if (challengeStatus === 'open') {
      return (
            <ChallengeTimer endDate={challenge.challenge.endAt}/>
      );
    }
    if (challengeStatus === 'closed') {
      return (
        <Badge variant="subtle" colorScheme="red">
        <Text fontSize="xl" fontWeight="bold" textAlign="center">
          Challenge has ended
        </Text>
        </Badge>
      );
    }
    if(challengeStatus === 'reviewing') {
      return (
        <Badge variant="subtle" colorScheme="yellow">
        <Text fontSize="xl" fontWeight="bold" textAlign="center">
            Challenge submissions are under review.
        </Text>
        </Badge>
      );
    }
  };


  return (
    <Box borderWidth={1} borderRadius="lg" p={4}>
      <VStack spacing={8} align="stretch">
        <Text fontSize="3xl" fontWeight="bold" textAlign="center">
          Indie-Challenge
        </Text>
        {challenges && challenges?.length > 0 ? (
          challenges.map((challenge: any, index: number) => (
            <React.Fragment key={challenge.id}>
              <Box>
                <VStack spacing={4} align="stretch">
                  {renderChallengeStatus(challenge)}
                  <Text fontSize="lg" fontWeight="bold" textAlign="center">
                    {challenge.challenge.prompt}
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" textAlign="center">
                    Submit your best short film within the given time limit.
                  </Text>
                  {challenge.challenge.status === 'open' && (
                    <>
                      {challenge.projects?.length === 0 ? (
                        <Button
                          sx={{ color: 'brand3' }}
                          size="lg"
                          onClick={() => handleSubmitNewProject(challenge.id)}
                        >
                          👉 Be the first to submit a project!
                        </Button>
                      ) : (
                        <Button
                          sx={{ color: 'brand3' }}
                          size="lg"
                          onClick={() => handleSubmitNewProject(challenge.id)}
                        >
                          Submit new project
                        </Button>
                      )}
                    </>
                  )}
                  <Button
                    colorScheme="teal"
                    size="lg"
                    onClick={() => handleSeeAllSubmissions(challenge.id)}
                  >
                    View More →
                  </Button>
                </VStack>
              </Box>
              {index !== challenges?.length - 1 && (
                <Divider orientation="horizontal" sx={{ color: 'brand3' }} />
              )}
            </React.Fragment>
          ))
        ) : (
          <Text>No challenges available.</Text>
        )}
      </VStack>
    </Box>
  );
};

export default Challenge;
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store/store';
import { signOut } from 'firebase/auth';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useToast } from "@chakra-ui/react";
import { auth } from "../utils/firebaseConfig";
import { setAuthTokenAction } from "../store/slices/auth";
import { setProjectsAction } from "../store/slices/project";
import { setProfileInfoAction } from "../store/slices/profile";
import { setCurrProfileAction } from "../store/slices/currProfile";
import { getProfileData, getProjectsData } from "../api/getProfile";
import { resetMessagingState } from "../store/slices/messaging";
import { setChallenges, setEvents, setGrants } from "../store/slices/community";
import { getCommunityEvents, getCommunityGrants } from "../services/community/events";
import useUserListener from "./useUserListener";
import useMessagingSystem from "./messages/useMessagingSystem";
import { getCommunityChallenges } from "../services/community/challenges";
export const useNavbar = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<Boolean | null>(null);
    const loggedInProfile: any = useSelector((state: RootState) => state.profile.value);
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { destroyUserListener } = useUserListener();
    const { destroyMessageRoomListeners } = useMessagingSystem();
    const signOutUser = () => {
        try {
            signOut(auth);
            dispatch(setProfileInfoAction({}));
            destroyUserListener();
            destroyMessageRoomListeners();
            dispatch(resetMessagingState());
            navigate('/');
            toast({
                title: 'Signed out successfully',
                description: "We've successfully logged you out",
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        } catch (error) {
            toast({
                title: 'Could not sign you out',
                description: "An error occured logging you out",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        }
    }

    const handleProfileClick = () => {
        const uid = auth.currentUser?.uid;
        dispatch(setCurrProfileAction(loggedInProfile));
        navigate(`user/${uid}/personal-profile`);
    };
    const profileData = useSelector((state: any) => state.profile.value);

    const populateData = async () => {
        const { data: profileData } = await getProfileData();
        const { data: projectsData } = await getProjectsData();
        const uid = auth.currentUser?.uid;
        const token = await auth.currentUser?.getIdToken();
        const events = await getCommunityEvents();
        const grants = await getCommunityGrants();
        const challenges = await getCommunityChallenges();

        const profile = {id: uid, ...profileData};
        dispatch(setProfileInfoAction(profile));
        dispatch(setProjectsAction(projectsData));
        dispatch(setAuthTokenAction(token));
        dispatch(setEvents(events));
        dispatch(setGrants(grants));
        dispatch(setChallenges(challenges));
    }

    return { isAuthenticated, setIsAuthenticated, loggedInProfile, toast, dispatch, navigate, signOutUser, handleProfileClick, profileData, populateData };

}
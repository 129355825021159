import React from 'react'
import styles from './footer.module.css'
import { Box, Center, Flex, HStack, Tag, Text } from '@chakra-ui/react'
import { FaInstagram, FaLinkedin } from 'react-icons/fa6'
import IndieButton from '../atoms/IndieButton'

const footerTitleStyles = {
fontSize: ['xl', 'xl', '2xl'], textDecoration: 'underline', fontWeight: [600]
}

function Footer() {
    const openEmail = () => {
        window.open('mailto:team@indie-stry.com');
    };
    return (
        <Flex sx={{ flexDir: ['column', 'row'], justifyContent: 'space-between', my: ['10px'], gap: ['10px'] }}>
            <Box>
                <Box sx={footerTitleStyles}> Follow Us</Box>
                <Flex sx={{ gap: ['5px', '15px']}}>
                    <FaInstagram />
                    <FaLinkedin />
                </Flex>
            </Box>
            <Box >
                <IndieButton onClick={openEmail} label="Contact Us" />
            </Box>
            <Box >
                <Box sx={footerTitleStyles}> Company</Box>
                {/* <div className={styles.footerTitle}>Company</div> */}
                <Box sx={{ mx: 'auto', textAlign: ['left', 'center'] }}>
                    <Text>Careers
                        {/* <Tag fontWeight={600} sx={{fontSize: ['8px'], px: ['2px'], py:['2px']} }>coming soon</Tag> */}
                    </Text>
                    <Text>Events</Text>
                    <Text>Grants</Text>
                </Box>
            </Box>
            {/* <Box >
                <div className={styles.footerTitle}>Others</div>
                <Box sx={{ mx: 'auto', textAlign: ['left', 'center'] }}>
                    <Text>Events</Text>
                    <Text>Grants</Text>
                </Box>
            </Box> */}
        </Flex>

    )
}

export default Footer
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  Text,
  Badge,
  Box,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, FieldErrors, useForm } from "react-hook-form";
import { iChallenge } from "../../domain/community/challenge";
import "./CustomDatePicker.css";
import useCommunityEditor from "../../hooks/admin/useCommunityEditor";
import LoadingComponent from "../../components/Utils/LoadingComponent";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { auth } from "../../utils/firebaseConfig";
import {
  setChallenges,
  updateChallenge,
  updateChallengeStatus,
} from "../../store/slices/community";
import {
  getChallengeById,
  getCommunityChallenges,
} from "../../services/community/challenges";
import { use } from "chai";

type FormattedDate = {
  dayOfTheWeek: string;
  dateSection: string;
  timeSection: string;
};

const styles = {
  dateContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

interface SubmissionProject {
  projectName: string;
  id: string;
  submissionLink: string;
  // Add other relevant project details here
}

const SubmissionsCard: React.FC<{ submission: SubmissionProject }> = ({
  submission,
}) => {
  const navigate = useNavigate();
  return (
    <Card
      direction={{ base: "column" }}
      overflow="hidden"
      variant="outline"
      backgroundColor={"transparent"}
      textColor={"white"}
      width={{ base: "100%", md: "100%", lg: "100%" }}
      marginBottom={{ base: "10px", md: "10px", lg: "10px" }}
    >
      <CardBody>
        <Stack spacing={4}>
          <Text fontSize="xl" fontWeight="bold">
            {submission.projectName}
          </Text>
          <Text fontSize="md">
            Submission Link:{" "}
            <a
              href={submission.submissionLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {submission.submissionLink}
            </a>
          </Text>
          <Button
            colorScheme="green"
            onClick={() => {
              navigate(`/project/${submission.id}`);
            }}
          >
            View Project
          </Button>
          {/* other button or other actions */}
        </Stack>
      </CardBody>
    </Card>
  );
};

const SubmissionsModal: React.FC<{ challenge: any }> = ({ challenge }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const projects = useSelector((state: RootState) => state.projects.value);

  const submissionProjects: SubmissionProject[] = challenge.submissions.map(
    (submissionLink: string) => {
      const project: any = projects.find(
        (project: any) => project.submissionLink === submissionLink
      );
      return {
        projectName: project?.projectName || "",
        id: project?.id || "",
        submissionLink,
        // Add other relevant project details here
      };
    }
  );

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>
        View Submissions
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Challenge Submissions</ModalHeader>
          <ModalBody>
            <Stack spacing={4}>
              {submissionProjects.map((submission, index) => (
                <SubmissionsCard key={index} submission={submission} />
              ))}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const ChallengeStatusOptions: React.FC<{ id: string }> = ({ id }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const challenges = useSelector(
    (state: RootState) => state.community.challenges
  );
  const currentChallenge = challenges.find((challenge) => challenge.id === id) as any;

  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newStatus = event.target.value as "open" | "reviewing" | "closed";
    try {
      const token = await auth.currentUser?.getIdToken();
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "community/update-challenge-status",
        JSON.stringify({ challengeId: id, status: newStatus }),
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      toast({
        title: "Success",
        description: "Challenge status updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      dispatch(updateChallengeStatus({ id, status: newStatus }));
      const challenge = await getChallengeById(id);
      const updatedChallenge ={
        id: id,
        challenge: challenge
      }
      dispatch(updateChallenge(updatedChallenge));
    } catch (error) {
        console.error("Error updating challenge status:", error);
      toast({
        title: "Error",
        description: "Failed to update challenge status",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <>
      <Select value={currentChallenge.challenge.status} onChange={handleStatusChange}>
        <option value="open">Open</option>
        <option value="reviewing">Reviewing Submissions</option>
        <option value="closed">Closed</option>
      </Select>
    </>
  );
};

const DisplayChallenge: React.FC<{ challenge: any }> = ({ challenge }) => {
    const id = challenge.id;
    challenge = challenge.challenge
    const navigate = useNavigate();
  const formatDate = (dateString: string): FormattedDate => {
    const dateTimeObj = new Date(dateString);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfTheWeek = days[dateTimeObj.getDay()];
    const formattedDate = dateTimeObj.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    const formattedTime = dateTimeObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return {
      dayOfTheWeek,
      dateSection: formattedDate,
      timeSection: formattedTime,
    };
  };

  const startDate = formatDate(challenge.startAt);
  const endDate = formatDate(challenge.endAt);

  return (
    <Card
      direction={{ base: "column" }}
      overflow="hidden"
      variant="outline"
      backgroundColor={"transparent"}
      textColor={"white"}
      width={{ base: "100%", md: "100%", lg: "100%" }}
      marginBottom={{ base: "10px", md: "10px", lg: "10px" }}
    >
      <CardBody>
        <Stack spacing={4}>
          <Text fontSize="2xl" fontWeight="bold">
            {challenge.name}
          </Text>
          <Text fontSize="lg">Prompt: {challenge.prompt}</Text>
          <Box>
            <Text fontSize="md">
              Start Date: {startDate.dayOfTheWeek}, {startDate.dateSection} at{" "}
              {startDate.timeSection}
            </Text>
            <Text fontSize="md">
              End Date: {endDate.dayOfTheWeek}, {endDate.dateSection} at{" "}
              {endDate.timeSection}
            </Text>
          </Box>
          {challenge.description && (
            <Text fontSize="md">Description: {challenge.description}</Text>
          )}
        </Stack>
      </CardBody>
      <CardFooter>
        <Flex justify="space-between" width="100%">
          <Button
            colorScheme="green"
            onClick={() => {
              navigate(`/community/challenges/${id}`);
            }}
          >
            View Details
          </Button>
          <SubmissionsModal challenge={challenge} />
          <ChallengeStatusOptions id={id} />
        </Flex>
      </CardFooter>
    </Card>
  );
};

const ChallengesAdmin: React.FC = () => {
  const { register, handleSubmit, control, reset, formState } =
    useForm<iChallenge>({ mode: "onChange" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createNewChallenge, challengesLoading } = useCommunityEditor();
  const challenges = useSelector((state: RootState) => state.community.challenges);
  const userProfile = useSelector((state: RootState) => state.profile.value);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const newChallenges = await getCommunityChallenges();
        dispatch(setChallenges(newChallenges));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching challenges:", error);
        setLoading(false);
      }
    };

    fetchChallenges();
  }, [dispatch]);

  const onSubmit = (data: iChallenge) => {
    console.log(data);
    const newChallenge: iChallenge = {
      ...data,
      createdAt: new Date().toUTCString(),
      submissions: [],
      projectIds: [],
      status: "open",
    };
    createNewChallenge(newChallenge);
    clearForm();
  };

  const clearForm = () => {
    reset();
  };

  const onError = (errors: FieldErrors<iChallenge>) => {
    console.error("Invalid data:", errors);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Button marginBottom={"15px"} colorScheme="green" onClick={onOpen}>
        Create New Challenge
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Challenge</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Stack spacing={3}>
                <Input
                  variant="flushed"
                  placeholder="Challenge Name*"
                  {...register("name", { required: "name is required" })}
                />
                <Input
                  variant="flushed"
                  placeholder="Prompt"
                  {...register("prompt", { required: "prompt is required" })}
                />
                <Text>Start Date*</Text>
                <Controller
                  control={control}
                  name="startAt"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      wrapperClassName="date-picker"
                      placeholderText="Select event start date and time"
                      selected={field.value ? new Date(field.value) : null}
                      onChange={(date) => field.onChange(date)}
                      showTimeSelect
                    />
                  )}
                />
                <Text>End Date*</Text>

                <Controller
                  control={control}
                  name="endAt"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      wrapperClassName="date-picker"
                      placeholderText="Select event end date and time"
                      selected={field.value ? new Date(field.value) : null}
                      onChange={(date) => field.onChange(date)}
                      showTimeSelect
                    />
                  )}
                />
                <Input
                  variant="flushed"
                  placeholder="Privacy policy"
                  {...register("url")}
                />
                <Input
                  variant="flushed"
                  placeholder="Description"
                  {...register("description")}
                />

                <Button
                  colorScheme="green"
                  type="submit"
                  isDisabled={!formState.isValid}
                >
                  Save Challenge
                </Button>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      {challengesLoading && <LoadingComponent />}
      {challenges.length > 0 && challenges.map((challenge) => (
        <DisplayChallenge key={challenge.id} challenge={challenge} />
      ))}
    </>
  );
};

export default ChallengesAdmin;

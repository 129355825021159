import { Box, Spinner } from '@chakra-ui/react'
import React from 'react'

const LoadingComponent: React.FC = () => {
    return (
      <Box m="5px" mx="auto">
        <Spinner size='lg'></Spinner>
      </Box>
  )
}

export default LoadingComponent
import React from 'react'
import styles from "./profilesection.module.css"
import ProfileCard from '../../NewHomeComponents/ProfileCard';
import { Box } from '@chakra-ui/react';


function ProfileSection() {

    return (
        <Box sx={{}}>
            <Box sx={{ color: '#E7E0DB', fontWeight: ['800'], fontSize: ['25px','30px', '40px', '60px'] }}>Whoever you need, wherever you are</Box>
            <div className={styles.profileCards}>
                {
                    profileArr.map((profile: any, idx: number) => {
                        return (<ProfileCard key={idx} profileInfo={profile} />);
                    })
                }
            </div>
        </Box>
    )
}

export default ProfileSection;

const profileArr = [
    {
        "id": "jGLrTyJNJHBblPbEauus",
        "headShotUrl": "https://firebasestorage.googleapis.com/v0/b/the-indie-stry-production.appspot.com/o/images%2FDSCF5909.jpga01fb9a9-a223-454a-bb55-bda33f165887?alt=media&token=20dd9200-c885-4b31-937a-fcf437bb9fd2",
        "lastName": "Smith",
        "city": "Nashville",
        "selectedInterests": [
            "Comedy",
            "Action",
            "Adventure"
        ],
        "bio": "I'm an aspiring film producer, interested in sci-fi, comedy and aciton films! Currently experimenting with Virtual Productions and AI.",
        "firstName": "Chris",
        "mainRole": "Producer",
        "emailAddress": "csmith4work@gmail.com",
        "files": [],
        "selectedRoles": [
            "DIT, Audio Engineer"
        ],
        "state": "Tennessee",
        "videoLinks": [
            "https://www.youtube.com/watch?v=iNS0ExucUxg",
            "https://www.youtube.com/watch?v=EipYqh1KSYE&t=9s",
            "https://www.youtube.com/watch?v=jQDySEkYl7w"
        ],
        "isAdmin": true,
        "isAccepted": true,
        "isActivated": true
    },
    {
        "id": "ZjwNnI8wWapWA9EKxyI3",
        "headShotUrl": "https://firebasestorage.googleapis.com/v0/b/the-indie-stry-production.appspot.com/o/images%2FScreenshot%202024-01-16%20at%2010.52.39%20AM.png33376ae1-a41a-437a-8133-aa3ca85d2f14?alt=media&token=ad05501f-cf9d-45da-a68b-309ff5bba530",
        "lastName": "Anifantis",
        "city": "Los Angeles",
        "selectedInterests": [
            "Musical",
            "Drama"
        ],
        "bio": "Proactive, enthusiastic, multi hyphenated artist. Interests in music focused projects and coming of age stories. ",
        "isAdmin": false,
        "firstName": "Gabriella",
        "mainRole": "Actor",
        "emailAddress": "gabriellaanifantis@gmail.com",
        "files": [],
        "selectedRoles": [
            "Producer"
        ],
        "state": "California",
        "videoLinks": [],
        "isAccepted": true,
        "isActivated": false
    },
    {
        "id": "JAcUfP1P6gRneOOqoDvA",
        "lastName": "Davis",
        "city": "Los Angeles",
        "selectedInterests": [
            "Drama",
            "Romance",
            "Comedy"
        ],
        "bio": "Independent film director, producer and writer with a focus on diaspora infused interpersonal dramas, romances and comedies. Interested in fostering communities and long-term collaborations.",
        "isAdmin": false,
        "firstName": "Elijah",
        "mainRole": "Director",
        "emailAddress": "Davismovierev@gmail.com",
        "files": [],
        "selectedRoles": [
            "Producer",
            "Writer",
            "Colorist"
        ],
        "state": "California",
        "isAccepted": true,
        "isActivated": true,
        "headShotUrl": "https://firebasestorage.googleapis.com/v0/b/the-indie-stry-production.appspot.com/o/images%2FIMG_3922.jpeg44a20083-2c80-4762-b405-f54008661e70?alt=media&token=86754cae-771b-46db-b315-b8b54ceb9680",
        "reports": [],
        "blockedUsers": [],
        "isBlockedBy": [],
        "videoLinks": [
            "https://vimeo.com/902016016?share=copy",
            "https://vimeo.com/864546564",
            "https://vimeo.com/866669263"
        ]
    },
    {
        "id": "DOqCwL8NG2l420hVpyZk",
        "headShotUrl": "https://firebasestorage.googleapis.com/v0/b/the-indie-stry-production.appspot.com/o/images%2FScreenshot%202024-01-17%20at%201.39.33%E2%80%AFPM.png912f8b97-1032-409d-8a30-d0f9c3dd9dde?alt=media&token=9a96be68-fadd-4e89-ad51-472fc8d48573",
        "lastName": "Doyle",
        "city": "Los Angeles",
        "selectedInterests": [
            "Musical",
            "Comedy",
            "Romance",
            "Horror",
            "Narrative",
            "Drama",
            "Television",
            "New Media",
            "Action",
            "Adventure"
        ],
        "bio": "I am a senior at USC interested in producing. I am passionate about becoming the bridge between the business logistical side of filmmaking and wonderful creative minds to bring projects to fruition. Experience in features/shorts/interactive media. ",
        "isAdmin": false,
        "firstName": "Charlotte",
        "mainRole": "Producer",
        "emailAddress": "ceddoyle@gmail.com",
        "files": [],
        "selectedRoles": [
            "AD"
        ],
        "state": "California",
        "videoLinks": [
            "https://www.youtube.com/watch?v=vP-hS1m9URs",
            "https://www.youtube.com/watch?v=mWE1cpTwhYk",
            "https://www.youtube.com/watch?v=9qVlPSvwiu0",
            "https://www.youtube.com/watch?v=KWZdAeeJ5-U&pp=ygUOb3RoZXJ3b3JsZCBmdHc%3D"
        ],
        "isAccepted": true,
        "isActivated": true,
        "projects": [
            "E1dB5YZWeMWXEP6htuCR",
            "lxV6SxcpFVH63VcyNwSv"
        ]
    }
];

// const profileArr = [
//     {
//         "id": "6fTiDu4gx4csRkeoA8zK",
//         "firstName": "Maximus",
//         "lastName": "Jenkins",
//         "city": "Los Angeles",
//         "state": "California",
//         "roles": [
//             "AD",
//             "Producer",
//             "Editor",
//             "DP",
//             "Gaffer",
//             "Showrunner"
//         ],
//         "files": [],
//         "selectedInterests": [
//             "Adventure",
//             "Non-Fiction",
//             "Action",
//             "Television",
//             "New Media",
//             "Documentary",
//             "Horror",
//             "Comedy",
//             "Narrative"
//         ],
//         "headShotUrl": "https://firebasestorage.googleapis.com/v0/b/indie-stry.appspot.com/o/images%2Ff4fc781f37-headshot.png3a2b380f-db42-4cef-8db3-3e5d3c41c2fd?alt=media&token=c67e1676-c73e-4e36-a739-2239c957f595",
//         "mainRole": "Director"
//     },
//     {
//         "id": "PRNHjwh6ULhgSsCU6wM3",
//         "firstName": "Jake",
//         "lastName": "Burns",
//         "city": "asd",
//         "state": "Connecticut",
//         "roles": [
//             "Actor",
//             "Writer",
//             "Producer",
//             "Production Designer"
//         ],
//         "files": [],
//         "selectedInterests": [
//             "Drama",
//             "Documentary"
//         ],
//         "mainRole": "DP"
//     },
//     {
//         "id": "VLtAIJ3Vbn8zWPMOMHgZ",
//         "firstName": "Chris",
//         "lastName": "Chen",
//         "city": "St. Louis",
//         "state": "Missouri",
//         "roles": [
//             "VFX",
//             "Producer"
//         ],
//         "files": [],
//         "selectedInterests": [
//             "Drama",
//             "Documentary"
//         ],
//         "headShotUrl": "https://firebasestorage.googleapis.com/v0/b/indie-stry.appspot.com/o/images%2FMV5BNjE3NDQyOTYyMV5BMl5BanBnXkFtZTcwODcyODU2Mw%40%40._V1_FMjpg_UX1000_.jpga7a1ba40-65c8-4f26-beb8-3a39a8b526e8?alt=media&token=677ff452-f149-4412-93db-f33e911682ae",
//         "mainRole": "Writer"
//     },
//     {
//         "id": "zOfGs0vPabsx2XeY5iuB",
//         "firstName": "Mushtaq",
//         "lastName": "3",
//         "city": "LA",
//         "state": "California",
//         "roles": [
//             "DP",
//             "Production Designer"
//         ],
//         "files": [],
//         "selectedInterests": [
//             "New Media",
//             "Television"
//         ],
//         "headShotUrl": "",
//         "mainRole": "VFX"
//     }
// ]
import { Box, Flex, FormControl, FormErrorMessage, Input, InputGroup, InputLeftAddon, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import FileUploader from './FileUpload'
import { FieldErrors, FieldValues, UseFormGetValues } from 'react-hook-form';
type BasicInfoProps = {
    errors: FieldErrors<FieldValues>;
    register: any;
    getValues: UseFormGetValues<any>;
    initialValues?: any;
    coverPageImg: any,
    setCoverPageImg: any
};
const BasicInfo: React.FC<BasicInfoProps> = ({ coverPageImg, setCoverPageImg, errors, register, getValues }) => {


    return (<Flex sx={{ flexDir: ['column','column','column','row'], gap: ['10px','10px','10px','50px'], }}>
        <Box>
            <Text>Basic Info</Text>
            <Stack>
                <FormControl isInvalid={Boolean(errors.projectName)} >
                    <InputGroup sx={{flexDir: ['column','column','column','row']}}>
                        <InputLeftAddon sx={{ bgColor: 'transparent', border: '0', w: ['180px'] }} children='Name of Project' />
                        <Input sx={{w: ['100%','100%','100%','250px']}}{...register('projectName', {
                            required: 'Project name is req'
                        })} />
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.projectName && errors.projectName.message?.toString()}
                    </FormErrorMessage>
                </FormControl>

            </Stack>
        </Box>
        <Flex sx={{  justifyContent: 'center', mx: 'auto', w: ['100%'] }}>
            <FileUploader coverPageImg={coverPageImg} setCoverPageImg={setCoverPageImg} errors={errors} register={register} getValues={getValues} />
        </Flex>
    </Flex >);
}

export default BasicInfo;
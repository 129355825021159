import { Flex, FormControl, FormErrorMessage, Heading, Input, Select } from '@chakra-ui/react';
import React from 'react';

const states = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
    'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
    'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
    'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

interface LocationProps {
    register: any;
    errors: any;
    initialState?: string;
    initialCity?: string;

}

/**
 * Location Component
 *
 * The `Location` component provides user interface elements for selecting a state from a dropdown list and entering a city name in an input field. It's designed for 
 * capturing location information.
 *
 * @component
 * @example
 * <Location register={register} errors={errors} />
 *
 * @param {Object} props - Component properties
 * @param {Function} props.register - A function provided by a form library (e.g., React Hook Form) for registering form inputs.
 * @param {Object} props.errors - An object containing error validation.
 */
const Location: React.FC<LocationProps> = ({ register, errors, initialState, initialCity }) => {
    return (
        <div>
            <Heading
                fontSize={{ base: '20px', lg: '25px' }}
                fontWeight={'bold'}
                mb={2}
                color='#E7E0DB'>
                Location
            </Heading>
            <Flex align={'center'}>
                {/* State Dropdown */}
                <FormControl isInvalid={Boolean(errors.state)}>
                    <Select
                        name={'state'}
                        placeholder={'Select a state'}
                        {...register('state', { required: 'Select State' })}
                        defaultValue={initialState}
                    >
                        {states.map((state, index) => (
                            <option key={index} value={state}>
                                {state}
                            </option>
                        ))}
                    </Select>
                    <FormErrorMessage
                        position={'absolute'}
                        top={'100%'}
                        left={0}
                        width={'100%'}>
                        {errors.state && errors.state.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
                {/* City Input */}
                <FormControl isInvalid={Boolean(errors.city)}>
                    <Input
                        placeholder={'Jacksonville'}
                        {...register(`city`, { required: 'Enter City' })}
                        marginLeft={2}
                        transition={'border-color 0.3s ease'}
                        defaultValue={initialCity}
                    />
                    <FormErrorMessage
                        marginLeft={2}
                        position={'absolute'}
                        top={'100%'}
                        left={0}
                        width={'100%'}>
                        {errors.city && errors.city.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
        </div>
    );
};

export default Location;

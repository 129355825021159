import { combineReducers } from "@reduxjs/toolkit";
import { authTokenSlice } from "./slices/auth";
import { currProfileSlice } from "./slices/currProfile";
import { currProjectSlice } from "./slices/currProject";
import { projectSlice } from "./slices/project";
import { profileSlice } from "./slices/profile";
import { messagingSlice } from "./slices/messaging";
import { loadingSlice } from "./slices/loading";
import { communitySlice } from "./slices/community";
import { rolesSlice } from "./slices/roles";

const rootReducer = combineReducers({
	profile: profileSlice.reducer,
    projects: projectSlice.reducer,
    currProject: currProjectSlice.reducer,
    currProfile: currProfileSlice.reducer,
    authToken: authTokenSlice.reducer,
    messaging: messagingSlice.reducer,
    loading: loadingSlice.reducer,
    community: communitySlice.reducer,
    roles: rolesSlice.reducer
})
export default rootReducer;
import React, { useEffect } from 'react';
import { Avatar, Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Menu, MenuButton, MenuItem, MenuList, useDisclosure, Text } from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { COLOR2, COLOR1 } from '../../utils/ColorConstants';
import SignInButton from '../SignIn/SignIn';
import { useNavbar } from '../../hooks/useNavbar';
import { auth } from '../../utils/firebaseConfig';

const NavbarDrawer: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isAuthenticated, setIsAuthenticated, populateData, loggedInProfile, navigate, signOutUser, handleProfileClick, profileData } = useNavbar();
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setIsAuthenticated(true);
                populateData();
                onClose();
            } else {
                setIsAuthenticated(false);
            }
        });
    }, []);
    return (
        <Box>
            <GiHamburgerMenu size={'30'} onClick={onOpen} />

            <Drawer isOpen={isOpen} placement="right" onClose={onClose} >
                <DrawerOverlay>
                    <DrawerContent sx={{ bg: 'black', color: 'white' }}>
                        <DrawerCloseButton />
                        <DrawerHeader sx={{ mx: 'auto' }}>
                            <Menu>
                                {(isAuthenticated && profileData) ?
                                    <Menu>
                                        <MenuButton>
                                            <Flex sx={{ gap: ['20px'], alignItems: 'center' }}>
                                                {profileData.headShotUrl ?
                                                    <Avatar name={profileData.firstName} src={profileData.headShotUrl} />
                                                    :
                                                    <Avatar name={profileData.firstName} bgColor={COLOR2} color={COLOR1} />
                                                }
                                                <Text sx={{ fontWeight: ['600'], cursor: 'pointer' }} >{profileData.firstName}</Text>
                                            </Flex>
                                        </MenuButton>
                                        <MenuList sx={{ bgColor: '#1E2626', }}>
                                            <MenuItem sx={{ cursor: 'pointer', bgColor: '#1E2626' }} onClick={() => { onClose(); handleProfileClick() }}>Profile</MenuItem>
                                            <MenuItem sx={{ cursor: 'pointer', bgColor: '#1E2626' }} onClick={() => { onClose(); signOutUser() }}>Logout</MenuItem>
                                        </MenuList>
                                    </Menu> :
                                    <SignInButton closeDrawer={onClose}></SignInButton>
                                    }
                            </Menu>

                        </DrawerHeader>
                        <DrawerBody>
                            <Flex sx={{ gap: ['30px'], alignItems: 'center', flexDir: 'column' }}>
                                {(isAuthenticated && loggedInProfile?.isAdmin) && <Text sx={{ fontWeight: ['600'], cursor: 'pointer' }} onClick={() => { onClose(); navigate('/admin') }} > Admin </Text>}
                                <Text sx={{ fontWeight: ['600'], cursor: 'pointer' }} onClick={() => { onClose(); navigate('/community') }} > Community </Text>
                                <Text sx={{ fontWeight: ['600'], cursor: 'pointer' }} onClick={() => { onClose(); navigate('/people') }} > People </Text>
                                <Text sx={{ fontWeight: ['600'], cursor: 'pointer' }} onClick={() => { onClose(); navigate('/projects') }} > Projects </Text>
                                <Text sx={{ fontWeight: ['600'], cursor: 'pointer' }} onClick={() => { onClose(); navigate('/messages') }} > Messages </Text>
                            </Flex>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </Box>
    );
};

export default NavbarDrawer;

import { useToast } from '@chakra-ui/react';
import React from 'react'

interface ErrorComponentProps { 
    title: string;
    message: string;
    duration?: number;
}
const ErrorComponent: React.FC<ErrorComponentProps> = ({ title, message, duration }) => {
    const toast = useToast();
  return (
    <>
      {toast({
        title: title,
        description: message,
        status: 'error',
        duration: duration ? duration : 5000,
        isClosable: true,
        position: 'top'
      })}
    </>
  )
}

export default ErrorComponent
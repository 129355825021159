import { Box, Button, Center, Flex, Grid, GridItem, Heading } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ProjectBio from '../DetailedProjects/ProjectBio';
import KnowTheStory from '../DetailedProjects/KnowTheStory';
import GetInspired from '../DetailedProjects/GetInspired';
import ProjectsPeople from '../DetailedProjects/ProjectsPeople';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import DeleteProject from '../DetailedProjects/DeleteProject';
import SubmitProject from './SubmitProject';


const DetailedProject: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const projectId: string | undefined = params.id;
    const currProject = useSelector((state: RootState) => state.currProject.value);
    const user: any = useSelector((state: RootState) => state.profile.value);
    const isProjectCreatedByUser = user?.id === currProject.createdBy;
    const hasDeadlinePassed = new Date(currProject.endAt).getTime() < new Date().getTime();

    return (
        <Box sx={{ mx: ['5px', '10px', '15px', '140px'] }}>
            <Grid
                templateRows='repeat(1, 1fr)'
                templateColumns='repeat(3, 1fr)'
            >
                <GridItem />
                <GridItem><Center><Heading sx={{ fontWeight: ['700'] }}>Projects</Heading></Center></GridItem>
            </Grid>
            <ProjectBio />
            <Flex sx={{ flexDir: 'column', gap: ['30px'] }}>
                <KnowTheStory />
                <ProjectsPeople />
                <GetInspired />
            </Flex>
            {/* {user?.id === currProject.createdBy && <DeleteProject userId={user.id} projectId={currProject.id}/>} */}
            <Center sx={{ marginTop: '40px' }}>
                <SubmitProject isProjectCreatedByUser={isProjectCreatedByUser} hasDeadlinePassed={hasDeadlinePassed} currProject={currProject} />
            </Center>
            {user?.isAdmin && <DeleteProject projectId={currProject.id} />}
        </Box>
    )
}

export default DetailedProject
import {
    Box,
    Button,
    Center,
    CloseButton,
    Image,
    List,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
    useToast
} from '@chakra-ui/react';
import React, { ChangeEvent, useState } from 'react';
import { storage } from '../../utils/firebaseConfig';
import { StorageReference, ref, uploadBytes, getStorage } from 'firebase/storage';

interface FileInputProps {
    /**
     * A callback function to handle file changes. It receives an array of selected files.
     */
    onChange: (files: File[]) => void;
    existingFiles?: string[];
    existingVideos?: string[]
}

/**
 * FileUpload Component
 *
 * The `FileUpload` component allows users to upload files for their application. It supports various file types such as images (JPEG, JPG, PNG), documents (PDF, DOC, DOCX, TXT), and videos. Users can upload multiple files and view previews of images.
 *
 * @component
 * @example
 * <FileUpload onChange={handleFileChange} />
 *
 * @param {Object} props - Component properties
 * @param {Function} props.onChange - A callback function to handle file changes. It receives an array of selected files.
 */
const FileUpload: React.FC<FileInputProps> = ({ onChange, existingFiles, existingVideos }) => {
    const toast = useToast();
    let initialFiles: File[] = [];
    if (existingFiles) {
        // initialFiles = [ref(storage, existingFiles[0])]
    }
    const [selectedFiles, setSelectedFiles] = useState<File[]>(initialFiles);
    const [selectedFileIndex, setSelectedFileIndex] = useState<number | undefined>(undefined);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const newSelectedFiles = Array.from(files);
            setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
            onChange([...selectedFiles, ...newSelectedFiles]);
        }
    };

    const openFilePreview = (index: number) => {
        setSelectedFileIndex(index);
    };

    const closeFilePreview = () => {
        setSelectedFileIndex(undefined);
    };

    const renderFilePreview = (file: File, index: number) => {
        const fileType = file.type.split('/')[0];

        const supportedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const supportedTextTypes = ['text/plain']
        const supportedApplicationTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf']
        if (supportedImageTypes.includes(file.type)) {
            return (
                <Box
                    onClick={() => openFilePreview(index)}
                    cursor={'pointer'}
                    _hover={{ opacity: 0.7 }}
                    borderRadius={'md'}
                    overflow={'hidden'}
                >
                    <Text ml={2} fontWeight={'bold'} fontSize={'sm'}>
                        {file.name}
                    </Text>
                </Box>
            );
        } else if (fileType === 'video') {
            toast({
                title: 'Video Files Not Accepted',
                description: `Sorry we are unable to upload videos.`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        } else if (supportedApplicationTypes.includes(file.type)) {
            return (
                <Box
                    borderRadius={'md'}
                    overflow={'hidden'}
                >
                    <Text ml={2} fontWeight={'bold'} fontSize={'sm'}>
                        {file.name}
                    </Text>
                </Box>
            );
        }
        else if (supportedTextTypes.includes(file.type)) {
            return (
                <Box
                    borderRadius={'md'}
                    overflow={'hidden'}
                >
                    <Text ml={2} fontWeight={'bold'} fontSize={'sm'}>
                        {file.name}
                    </Text>
                </Box>
            );
        }
        else {
            return (
                <Box
                    cursor={'pointer'}
                    borderRadius={'md'}
                    overflow={'hidden'}
                >
                    <Text>Unsupported File</Text>
                </Box>
            );
        }
    };

    const handleRemoveFile = (index: number) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
        onChange(updatedFiles);
    };

    return (
        <Center>
            <Box
                p={4}
                borderRadius={'lg'}
                boxShadow={'md'}
                textAlign={'center'}
                bg={'white'}
                width={'100%'}
                max-width={'800px'} /* Set a maximum width to avoid overly wide content */
                margin={'0 auto'}
                padding={'20px'}
                sx={{ bgColor: 'transparent' }}
            >
                <VStack spacing={4} align={'stretch'}>
                    <Button
                        as={'label'}
                        htmlFor={'file-upload'}
                        variant={'outline'}
                        colorScheme={'teal'}
                        leftIcon={<i className='fas fa-cloud-upload-alt'></i>}
                    >
                        Upload Files
                    </Button>
                    <input
                        id={'file-upload'}
                        type={'file'}
                        accept={'.pdf, .jpg, .jpeg, .png, .doc, .docx, .txt'}
                        multiple
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    {selectedFiles.length > 0 && (
                        <VStack align={'stretch'} spacing={2} >
                            <Text fontWeight={'bold'}>Selected Files:</Text>
                            <List styleType={'none'}>
                                {selectedFiles.map((file, index) => (
                                    <ListItem
                                        key={index}
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        alignItems={'center'}
                                        bg={'#F7F7F7'}
                                        borderRadius={'md'}
                                        border={'1px solid #E0E0E0'}
                                        padding={'4'}
                                        bgColor='transparent'
                                    >
                                        {renderFilePreview(file, index)}
                                        <CloseButton
                                            color={'red'}
                                            onClick={() => handleRemoveFile(index)}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </VStack>
                    )}
                    <Text mt={2}>Accepted file types: PDF, JPG, JPEG, PNG, DOC, DOCX, TXT</Text>
                </VStack>
            </Box>

            {/* Modal for displaying file content */}
            <Modal isOpen={selectedFileIndex !== undefined} onClose={closeFilePreview}>
                <ModalOverlay />
                <ModalContent maxW={'60%'} maxH={'60%'}>
                    <ModalHeader>{selectedFiles[selectedFileIndex!]?.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        maxW="100%"
                        maxH="100%"
                    >
                        {/* Display file content here, e.g., PDF viewer or image */}
                        {['jpg', 'jpeg', 'png'].includes(
                            selectedFiles[selectedFileIndex!]?.name.split('.').pop()?.toLowerCase() || ''
                        ) ? (
                            <div
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100vh',  // Set a maximum height for the image
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Image
                                    src={URL.createObjectURL(selectedFiles[selectedFileIndex!]!)}
                                    alt={`Preview for ${selectedFiles[selectedFileIndex!]?.name}`}
                                    style={{ maxWidth: '80%', height: 'auto' }}
                                />
                            </div>
                        ) : (
                            <Text>Unsupported file type.</Text>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>


        </Center>
    );
};

export default FileUpload;
